import { Backdrop, Card, CardContent, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { api } from "../../apis/config";
import { useGetProductStatus } from "../../hooks/Payment";
import { fPrice } from "../../utils/formatNumber";
import ProductExtend from "./ProductExtend";
import Reviw from "./Reviw";
const useStyles = makeStyles((theme) => ({
  normal: {
    padding: theme.spacing(1, 0),
    borderRadius: "15px",
    cursor: "pointer",
    border: `2px solid ${theme.palette.success.main}`,
    "&:hover": {
      backgroundColor: "rgba(0, 252, 252, 0.1)",
    },
  },
  featured: {
    padding: theme.spacing(1, 0),
    borderRadius: "15px",
    cursor: "pointer",
    border: `2px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      backgroundColor: "rgba(0, 252, 252, 0.1)",
    },
  },
  promoted: {
    padding: theme.spacing(1, 0),
    borderRadius: "15px",
    cursor: "pointer",
    border: `2px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: "rgba(0, 252, 252, 0.1)",
    },
  },
  header: {
    fontWeight: "bold",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  foorer: { textAlign: "center", padding: theme.spacing(0.5, 0) },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "flex-end",
    marginTop: theme.spacing(4),
  },
  babutton: {
    margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
}));
function ProductReview({ product, setReview, setBody, price }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data, isSuccess } = useGetProductStatus(product.id);
  const nfp_price = {
    is_normal: price.normal_price,
    is_featured: price.featured_price,
    is_promoted: price.promoted_price,
  };
  if (isSuccess && data.normal_status !== "active") {
    nfp_price.is_featured += price.normal_price;
    nfp_price.is_promoted += price.normal_price;
  }
  const handleClick = async (name) => {
    let body = {
      product_pk: product.id,
      amount: nfp_price[name],
      [name]: true,
    };
    if (data.normal_status !== "active") body.is_normal = true;
    setBody(body);
    if (body.amount <= 0) {
      await api.post(`api/payment/product-payment/free-post/`, body);
      navigate(`/detail/${product.id}`);
    } else setReview(false);
  };
  const { t } = useTranslation();
  return (
    isSuccess && (
      <Box mx={2.5}>
        <Reviw product={product} />
        <Typography variant="h4" align="center" sx={{ mt: 5, mb: 2 }}>
          {data.normal_status === "active"
            ? t("Boosting details")
            : t("Posting details")}
        </Typography>
        <ProductExtend data={data} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ position: "relative" }}>
              <Backdrop
                sx={{
                  position: "absolute",
                  borderRadius: "15px",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={!data.free_post_allow && price.normal_price <= 0}
              />
              <Card
                className={classes.normal}
                onClick={() => handleClick("is_normal")}
              >
                <Typography className={classes.header} variant={"h5"}>
                  {data.normal_status === "active"
                    ? t("Free post extend")
                    : t("Go to detail page")}
                </Typography>
                <CardContent className={classes.content}>
                  <Typography variant="body2" color="text.secondary">
                    {t("normal_post_msg")}
                  </Typography>
                </CardContent>
                <Typography className={classes.foorer} variant={"h6"}>
                  {nfp_price.is_normal > 0
                    ? fPrice(nfp_price.is_normal)
                    : t(`Free`)}
                </Typography>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ position: "relative" }}>
              <Backdrop
                sx={{
                  position: "absolute",
                  borderRadius: "15px",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                open={true}
              >
                {t("Coming soon")}
              </Backdrop>
              <Card
                className={classes.featured}
                onClick={() => handleClick("is_featured")}
              >
                <Typography className={classes.header} variant={"h5"}>
                  {data.is_featured
                    ? t(`Featured post extend`)
                    : t("Featured post")}
                </Typography>
                <CardContent className={classes.content}>
                  <Typography variant="body2" color="text.secondary">
                    {data.normal_status == "active"
                      ? t("featured_post_msg2")
                      : t("featured_post_msg1")}
                  </Typography>
                </CardContent>
                <Typography className={classes.foorer} variant={"h6"}>
                  {nfp_price.is_featured > 0
                    ? fPrice(nfp_price.is_featured)
                    : t(`Free`)}
                </Typography>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ position: "relative" }}>
              <Backdrop
                sx={{
                  position: "absolute",
                  borderRadius: "15px",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                open={true}
              >
                {t("Coming soon")}
              </Backdrop>
              <Card
                className={classes.promoted}
                onClick={() => handleClick("is_promoted")}
              >
                <Typography className={classes.header} variant={"h5"}>
                  {data.is_promoted
                    ? t(`Promoted post extend`)
                    : t("Promoted post")}
                </Typography>
                <CardContent className={classes.content}>
                  <Typography variant="body2" color="text.secondary">
                    {data.normal_status == "active"
                      ? t("promoted_post_msg2")
                      : t("promoted_post_msg1")}
                  </Typography>
                </CardContent>
                <Typography className={classes.foorer} variant={"h6"}>
                  {nfp_price.is_promoted > 0
                    ? fPrice(nfp_price.is_promoted)
                    : t(`Free`)}
                </Typography>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.button}>
          <Button
            className={classes.babutton}
            onClick={() => navigate(`/update-ad/${product.id}`)}
          >
            {t("Go to Edit Page")}
          </Button>
        </Box>
      </Box>
    )
  );
}
export default ProductReview;

import SearchIcon from "@mui/icons-material/Search";
import { Container, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Catagory } from "../Catagory";
import CustomAutoComplete from "../Inputs/AutoComplete";
import useSearchBar from "./SearchBarHook";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 4px",
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 5px 5px 0 #e4e7ee",
    boxShadow: "rgba(4, 17, 29, 0.25) 0px 0px 2px 0px",
    height: "58px",
  },
  autocomplete: {
    marginLeft: theme.spacing(1),
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  SearchBtn: {
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      marginRight: "0px",
    },
    padding: "8px 35px",
    marginRight: "2px",
    whiteSpace: "nowrap",
    backgroundColor: "#FB8500",
    color: " #fff",
    textTransform: "none",
    borderRadius: "27px",
    "&:hover": {
      backgroundColor: "#FB8500",
      color: " #fff",
    },
  },
}));
export default function SearchBar1(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    adSearchQuery,
    adSearchCategory,
    handleCatagoryChange,
    categoryList,
    categoryIsLoading,
    onTextChange,
    onSearch,
    searchAutoCompleteIsLoading,
    searchAutoCompleteData,
  } = useSearchBar(props);
  return (
    <Container
      component="form"
      className={classes.root}
      style={{ maxWidth: 700 }}
    >
      <Catagory
        category={adSearchCategory}
        categoryList={categoryList}
        loading={categoryIsLoading}
        handleCatagoryChange={handleCatagoryChange}
      />
      <CustomAutoComplete
        text={adSearchQuery}
        onTextChange={onTextChange}
        onSearch={onSearch}
        isLoading={searchAutoCompleteIsLoading}
        options={searchAutoCompleteData}
        className={classes.autocomplete}
        placeholder="Search for anything..."
        inputProps={{ "aria-label": "Search for anything..." }}
      />
      <IconButton
        aria-label="Search"
        sx={{ color: "text.primary" }}
        onClick={onSearch}
      >
        <SearchIcon />
      </IconButton>
    </Container>
  );
}

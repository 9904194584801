import { Icon as Iconify } from "@iconify/react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Avatar, Badge, Box, Divider, Grid, Link, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import salary from "../../config/salary.js";
import { fNumber } from "../../utils/formatNumber";
import { fDate } from "../../utils/formatTime";
import CustomTooltip from "../CustomTooltip.js";
import Label from "../Label.js";
import Favorite from "../products1/Favorite";
export const DescriptionStyle = styled(Box)(({ theme }) => ({
  fontFamily: '"Poppins", sans-serif',
  lineHeight: 1.25556,
  fontSize: "0.8rem",
  color: "#828282",
  marginRight: "20px",
  marginY: "5px",
  textDecoration: "none",
  cursor: "pointer",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
}));
const TitleStyle = styled(Box)(({ theme }) => ({
  fontFamily: '"Poppins", sans-serif',
  fontWeight: 550,
  lineHeight: 1.8,
  fontSize: "1.1rem",
  color: theme.palette.text.primary,
  marginTop: theme.spacing(1),
  textDecoration: "none",
  cursor: "pointer",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
}));
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    borderRadius: 0,
    "&:hover": { boxShadow: "rgb(145 158 171 / 24%) -24px 24px 72px -8px" },
  },
}));
function AdCardForJobs({ data, includeFav }) {
  const Icon_INFO = [
    data.price > 0
      ? { value: `ETB ${fNumber(data.price)}`, icon: salary }
      : null,
    data.experience
      ? { value: `${data.experience} experience`, icon: "eva:bar-chart-2-fill" }
      : null,
    data.employment_type
      ? { value: data.employment_type, icon: "eva:person-outline" }
      : null,
    data.education
      ? { value: data.education, icon: "mdi:school-outline" }
      : null,
  ];
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Card className={classes.root}>
        <Link
          component={RouterLink}
          to={data ? `/detail/${data.id}` : ""}
          sx={{ textDecoration: "none", width: "100%" }}
        >
          <Grid spacing={1}>
            <Grid item>
              <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                <CustomTooltip title={data.owner.name} placement="bottom">
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={
                      data.owner.is_verified && (
                        <VerifiedIcon
                          sx={{
                            color: "blue",
                            backgroundColor: "#fff",
                            fontSize: "14px",
                            boxShadow: "0 0 0 1.5px #fff",
                            borderRadius: "50%",
                            padding: 0,
                          }}
                        />
                      )
                    }
                  >
                    <Box
                      sx={{
                        p: 0.5,
                        border: 1,
                        borderColor: "grey.200",
                        borderRadius: "8px",
                      }}
                    >
                      <Avatar
                        variant="rounded"
                        sx={{ width: 45, height: 45 }}
                        src={data.owner.logo}
                        alt={data.owner.name}
                      />
                    </Box>
                  </Badge>
                </CustomTooltip>
                {data.is_featured ? (
                  <Label variant="filled" color="secondary" sx={{}}>
                    {t("Featured")}
                  </Label>
                ) : (
                  <Label sx={{ bgcolor: "transparent" }} />
                )}
              </Stack>
              {includeFav && (
                <Box
                  sx={{
                    width: 34,
                    height: 34,
                    background: "#f7f7f7",
                    ml: 1,
                    mt: 1,
                    top: (theme) => theme.spacing(1.5),
                    right: (theme) => theme.spacing(1.5),
                    position: "absolute",
                    boxShadow: "rgba(4, 17, 29, 0.25) 0px 0px 2px 0px",
                    borderRadius: "50%",
                  }}
                  aria-label="favorite"
                >
                  <Favorite
                    id={data.id}
                    css={{
                      fontSize: "24px",
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                </Box>
              )}
            </Grid>
            <Grid>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TitleStyle>
                  {data.title
                    .split(">")
                    .map((x) => t(x))
                    .join(", ")}
                </TitleStyle>
                <Grid mb={1} sx={{ "& > :not(style)": { marginY: "2px" } }}>
                  {Icon_INFO.map(
                    (info, index) =>
                      info && (
                        <Grid item key={index}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: (theme) => theme.palette.text.secondary,
                            }}
                          >
                            <Box
                              component={Iconify}
                              icon={info.icon}
                              sx={{
                                width: 16,
                                height: 16,
                                mr: 1,
                                color: "grey.500",
                              }}
                            />
                            <Box
                              sx={{
                                whiteSpace: "nowrap",
                                lineHeight: 1.757,
                                fontSize: "0.875rem",
                                fontFamily: '"Poppins",sans-serif',
                                fontWeight: 400,
                              }}
                            >
                              {t(info.value)}
                            </Box>
                          </Box>
                        </Grid>
                      )
                  )}
                </Grid>
                <DescriptionStyle>{data.description}</DescriptionStyle>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    alignItems: "center",
                    "& > :not(style)": {
                      whiteSpace: "nowrap",
                      margin: "0px",
                      lineHeight: "1.96667",
                      fontSize: "0.85rem",
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 400,
                      color: "rgb(145, 158, 171)",
                    },
                    marginTop: "5px",
                  }}
                >
                  {data.location && (
                    <Box
                      component={Typography}
                      variant="caption"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      <LocationOnIcon fontSize="inherit" sx={{ mr: 0.5 }} />
                      <Box component="span">
                        {data.location
                          .split(", ")
                          .map((x, i) => (i > 0 ? ", " : "") + t(x))}
                      </Box>
                    </Box>
                  )}
                  <Box component="span">
                    <Box component="span" mr={0.2}>
                      {t("date posted")}:
                    </Box>{" "}
                    {fDate(data.release_date)}
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Link>
      </Card>
      <Divider />
    </>
  );
}
export default AdCardForJobs;

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import noresult from "../../config/001-exclamation-mark-min.png";
export default function NoResultFound() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "40vh",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <Box sx={{ width: 60, height: 60 }}>
        <img width="100%" height="100%" src={noresult} />
      </Box>
      <Typography variant="h4" sx={{ mt: 2, color: "primary.main" }}>
        {t("Sorry No Results Found!")}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {t("Try changing your search or filter...")}
      </Typography>
    </Box>
  );
}

import { useQuery } from "react-query";

import {
  get_categories,
  get_Main_Categories,
  get_location_list,
  get_region_list,
  get_config,
  get_image_range,
  get_product_price,
} from "../apis";

export const useGetCatagoryList = () => {
  return useQuery("catagory", () => get_categories());
};
// export const useGetMainCategoryList = (location) => {
//   return useQuery(["Main_Category", location], () =>
//     get_Main_Categories(location)
//   );
// };
export const useGetMainCategoryList = () => {
  return useQuery(["Main_Category"], () => get_Main_Categories());
};

export const useGetLocationList = () => {
  return useQuery("location_list", () => get_location_list());
};
export const useGetRegionsList = () => {
  return useQuery("regions_list", () => get_region_list());
};

export const useCategory = () => {
  return useQuery("category", () => get_categories());
};

export const useConfig = (id) => {
  return useQuery(["config", id], () => get_config(id));
};

export const useImageRange = (id) => {
  return useQuery(["image", id], () => get_image_range(id));
};

export const useProductPrice = (id) => {
  return useQuery(["price", id], () => get_product_price(id));
};

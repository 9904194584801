import settingsAdjust from "@iconify/icons-carbon/settings-adjust";
import sortDescending from "@iconify/icons-carbon/sort-descending";
import { Icon } from "@iconify/react";
import { Box, ButtonBase, Menu, MenuItem, Paper } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SORT_OPTIONS } from "../../config/settings";
function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: 4,
    color: trigger ? "primary" : "secondary",
    position: trigger ? "sticky" : "fixed",
  });
}
const useStyles = makeStyles((theme) => ({
  filterContainer: {
    borderRadius: 0,
    position: "relative",
    boxShadow: "rgba(4, 17, 29, 0.25) 0px 0px 2px 0px",
    borderRadius: "5px",
    margin: 10,
  },
  filterSticky: {
    top: 0,
    position: "fixed",
    zIndex: 100,
    width: "100%",
  },
}));
export default function Filtering({
  anchorEl,
  setAnchorEl,
  handleSortClick,
  setOpen,
}) {
  const classes = useStyles();
  const [scrolled, setScrolled] = React.useState(false);
  const openSort = Boolean(anchorEl);
  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 300 ? true : false); // 750 height of the card
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);
  const handleSortClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  return (
    <Box sx={{ height: "80px", width: "100%", mb: 3 }}>
      <ElevationScroll>
        <Paper
          className={scrolled ? classes.filterSticky : classes.filterContainer}
          elevation={2}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "68px",
          }}
        >
          <Box
            component={ButtonBase}
            sx={{
              fontSize: "18px",
              px: 1,
              py: 1.5,
              width: "50%",
              color: "secondary.main",
            }}
            onClick={() => setOpen(true)}
          >
            <Icon icon={settingsAdjust} sx={{}} />
            {t("Filter")}
          </Box>
          <>
            <Box
              component={ButtonBase}
              sx={{
                fontSize: "18px",
                px: 1,
                py: 1.5,
                width: "50%",
                color: "secondary.main",
              }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <Icon icon={sortDescending} sx={{}} />
              {t("Sort")}
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openSort}
              onClose={handleSortClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {SORT_OPTIONS.map((option) => (
                <MenuItem
                  onClick={() => handleSortClick(option.value)}
                  key={option.value}
                  value={option.value}
                >
                  {t(option.label)}
                </MenuItem>
              ))}
            </Menu>
          </>
        </Paper>
      </ElevationScroll>
    </Box>
  );
}

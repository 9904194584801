import { Backdrop } from "@mui/material";
import React from "react";
import CustomLoading from "./components/Feedback/CustomLoading";

export default function LoadingDots() {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: "background.default",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItmes: "center",
        justifyContent: "center",
      }}
      open={true}
    >
      <CustomLoading />
    </Backdrop>
  );
}

import { api } from "./config";

export const clear_Favorities = async () => {
  const response = await api.get("/api/profile/clear-favourite/");

  return response.data;
};

export const get_Favorities = async (isAuth) => {
  if (!isAuth) return;
  const response = await api.get("/api/main/favourite/list/");
  return response.data;
};

export const set_Favorities = async (data) => {
  const response = await api.post("/api/main/favourite/update/", data);
  return response.data;
};

// product sorting parameters
import { ReactComponent as vfs } from "../config/categories/fmd/vfs.svg";
import { ReactComponent as vfr } from "../config/categories/fmd/vfr.svg";
import { ReactComponent as c } from "../config/categories/fmd/c.svg";
import { ReactComponent as e } from "../config/categories/fmd/e.svg";
import { ReactComponent as f } from "../config/categories/fmd/f.svg";
import { ReactComponent as fa } from "../config/categories/fmd/fa.svg";
import { ReactComponent as hac } from "../config/categories/fmd/hac.svg";
import { ReactComponent as j } from "../config/categories/fmd/j.svg";
import { ReactComponent as m } from "../config/categories/fmd/m.svg";
import { ReactComponent as pfr } from "../config/categories/fmd/pfr.svg";
import { ReactComponent as pfs } from "../config/categories/fmd/pfs.svg";
import { ReactComponent as s } from "../config/categories/fmd/s.svg";

export const SORT_OPTIONS = [
  { value: "recommendation", label: "Recommendations" },
  { value: "featured", label: "Default" },
  { value: "newest", label: "Newest Ads" },
  { value: "oldest", label: "Oldest Ads" },
  { value: "low_to_high", label: "Lowest Price" },
  { value: "high_to_low", label: "Highest Price" },
  { value: "a_to_z", label: "Title Ascending" },
  { value: "z_to_a", label: "Title Descending" },
];
export const AD_SORT_OPTIONS = [
  { value: "featured", label: "Default" },
  { value: "newest", label: "Newest Ads" },
  { value: "oldest", label: "Oldest Ads" },
  { value: "low_to_high", label: "Lowest Price" },
  { value: "high_to_low", label: "Highest Price" },
  { value: "a_to_z", label: "Title Ascending" },
  { value: "z_to_a", label: "Title Descending" },
];

export const CATEGORY_LIST = [
  {
    label: "Vehicle for Sell",
    icon: vfs,
    link: "vehicle-for-sell",
  },
  {
    label: "Vehicle for Rent",
    icon: vfr,
    link: "vehicle-for-rent",
  },
  {
    label: "Property for Sell",
    icon: pfs,
    link: "property-for-sell",
  },
  {
    label: "Property for Rent",
    icon: pfr,
    link: "property-for-rent",
  },
  {
    label: "Classifieds",
    icon: fa,
    link: "classifieds",
  },
  // {
  //   label: "Phone and Tablets",
  //   icon: m,
  //   link: "classifieds/phone",
  // },
  {
    label: "Fashion",
    icon: c,
    link: "fashion",
  },
  {
    label: "Electronics",
    icon: e,
    link: "electronics",
  },
  // {
  //   label: "Furnitures",
  //   icon: f,
  //   link: "classifieds/furniture-home-garden",
  // },
  // {
  //   label: "Health & beauty",
  //   icon: hac,
  //   link: "classifieds/furniture-home-garden",
  // },
  {
    label: "Services",
    icon: s,
    link: "services",
  },
  {
    label: "Job",
    icon: j,
    link: "jobs",
  },
];

export const START_CHAT_SHORTS = [
  {
    label: "Text me",
    value: "Please, text me back with the number",
    phone: true,
  },
  {
    label: "Call me",
    value: "Please, call me back or page me with the number",
    phone: true,
  },
  {
    label: "Is it available",
    value: "Is it still available?",
    phone: false,
  },
  {
    label: "Send more images",
    value: "Can you send me other images?",
    phone: false,
  },
  {
    label: "Last price",
    value: "What is your last price?",
    phone: false,
  },
  {
    label: "Other color",
    value: "Do you have it in other color?",
    phone: false,
  },
];

import { api } from "./config";
import queryString from "query-string";

// api for getting chat list
// export const ProductFilter = async ({ pageParam = 0, queryKey }) => {
//   let page;
//   // check if it is the first time fetching
//   if (pageParam !== 0) {
//     const param = pageParam.split("?")[1];
//     const values = queryString.parse(param);
//     page = values.page;
//   }

//   const params = {
//     page: page,
//   };

export const ProductFilter = async (payload, sort, page) => {
  const response = await api.get(
    `api/main/product/list/?${payload}&order_by=${sort}&page=${page}`
  );

  return response.data;
};

import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as NoFavorite } from "../../config/notFound.svg";
const NoPendingProduct = () => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          mt: 8,
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#fff",
        }}
      >
        <Box
          component={NoFavorite}
          sx={{
            width: "200px",
            fill: (theme) => theme.palette.secondary.main,
          }}
        />
        <Typography variant="h4" paragraph>
          {t("No Pending Product Yet!")}
        </Typography>
      </Box>
    </Container>
  );
};
export default NoPendingProduct;

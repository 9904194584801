import { dimension } from "../../../utils/ImageCompressor";
import { message } from "../../Account/Constants";
import { validTelePhone } from "../../Account/ValidateField";
export function disableDependency(disable, values) {
  const index = Object.keys(disable)[0];
  if (index in values && disable[index].includes(values[index])) return false;
  return true;
}
export function numberFieldValidate(e) {
  let newErrors = {};
  if (e.target.type === "number" && e.target.value < 0) {
    newErrors[e.target.name] = "Negative value not allow";
  }
  return { errors: newErrors };
}
export const dependencyValueCheck = (name, value, values) => {
  let newErrors = { [name]: null };
  let newValues = {};
  if (name === "title" && value.length > 100) {
    newErrors.title = "Ensure this field has no more than 100 characters.";
  } else if (name === "mileage") {
    if (values.condition == "New (0-4000 KM)" && value > 4000)
      newErrors.mileage = "Maximum mileage for new car is 4,000KM";
  } else if (name === "condition") {
    newErrors.licence_plate = null;
    newErrors.steer_position = null;
    newErrors.usage = null;
    newErrors.mileage = null;
    if (value === "Used") {
      newValues.usage = "";
      newValues.licence_plate = "";
      newValues.steer_position = "";
    } else if (value === "New (0-4000 KM)" || value === "New") {
      newValues.licence_plate = "Not Registered";
      newValues.steer_position = "Original left";
      newValues.usage = "Brand New";
      if (values.mileage && values.mileage > 4000) {
        newErrors.mileage = "Maximum mileage for new car is 4,000KM";
      }
    }
  } else if (name === "real_estate_type") {
    newErrors.villa_type = null;
    newErrors.compound_area = null;
    newErrors.floor = null;
    if (value === "Villa") newValues.floor = "";
    else if (value === "Apartment") {
      newValues.villa_type = "";
      newValues.compound_area = "";
    }
  } else if (name === "price") {
    newErrors.price = null;
    newErrors.price_per = null;
    if (!value) newValues.price_per = null;
  }
  return { values: newValues, errors: newErrors };
};
export function fieldValidate(e) {
  let errors = {};
  for (const field of e.target.elements) {
    if (
      ["text", "number"].includes(field.type) &&
      field.value === "" &&
      field.required &&
      !field.disabled
    )
      errors[field.name] = message.VALUE_ERROR;
    else if (field.name === "phone_number") {
      if (!validTelePhone(field.value))
        errors.phone_number = message.PHONE_ERROR;
    }
  }
  return errors;
}
export function validateNumberOfImage(config, photos) {
  let errors = {};
  if (photos.length < config.min_image || photos.length > config.max_image)
    errors.photos = `Advert should contain from ${config.min_image} to ${config.max_image} images for this category.`;
  else errors.photos = null;
  return errors;
}
function GetImagePixel(file) {
  return new Promise(async (resolve, reject) => {
    let img = new Image();
    img.src = URL.createObjectURL(file);
    img.onerror = reject;
    img.onload = () => resolve({ x: img.width, y: img.height });
  });
}
export const ValidateImagePixel = async (
  filesObj,
  images,
  setErrors,
  max_image,
  length
) => {
  let deleteCover = false;
  let photosErr = null;
  for (const file of images) {
    const pixel = await GetImagePixel(file.img);
    const index = filesObj.findIndex((item) => item === file);
    if (pixel.x < dimension.minWidth || pixel.y < dimension.minHeight) {
      if (file.cover) deleteCover = true;
      filesObj = filesObj.filter((item) => item !== file);
      photosErr = `Image ${
        pixel.x < dimension.minWidth
          ? "width must be at least " + dimension.minWidth
          : "height must be at least " + dimension.minHeight
      }px.`;
    } else filesObj[index].loading = false;
  }
  if (filesObj.length > max_image) {
    photosErr = `You can not upload more then ${
      length ? length : max_image
    } images for this category.`;
  }
  setErrors((prev) => ({ ...prev, photos: photosErr }));
  if (deleteCover && filesObj.length > 0) filesObj[0].cover = true;
  return filesObj;
};
export function mutateFieldError(mutateError, values) {
  let errors = {};
  Object.keys(mutateError).forEach((field) => {
    if (Object.keys(values).includes(field)) errors[field] = mutateError[field];
    else {
      if (!errors.detail) errors.detail = `${field}=${mutateError[field]}`;
      else errors.detail = `${field}=${mutateError[field]}, ${errors.detail}`;
    }
  });
  return errors;
}

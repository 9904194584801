import { default as Category } from "@mui/icons-material/Category";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { default as DateRange } from "@mui/icons-material/DateRange";
import { default as LocationOnIcon } from "@mui/icons-material/LocationOn";
import { Button, Paper, Stack, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { StaffContext } from "../../pages/AdActivate";
import { fDate } from "../../utils/formatTime";
import Alert from "../Alert/Alert";
import CustomizedBox from "../Feedback/CustomizedBox";
import Gallery from "../Feedback/ImageGallery";
import withMutate from "../Feedback/withMutate";
import Scrollbar from "../Scrollbar";
import Attribute from "./Attribute";
import ChangeCoverImage from "./ChangeCoverImage";
import Confirm from "./Confirm";
import Description from "./Description";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    border: "1px solid lightgrey",
    borderRadius: "10px",
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  box: {
    mb: 1.5,
    px: 2.2,
    boxShadow: "none",
  },
}));
const SubmitButton = ({ slug, coverId, deletedId, isWatermark }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ status: "pending" });
  const { mutate, id } = useContext(StaffContext);

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      coverId: coverId,
      deletedId: deletedId,
      is_watermark: isWatermark,
    }));
  }, [coverId, deletedId, isWatermark]);

  const handleClick = (value) => {
    setValues((prev) => ({ ...prev, status: value }));
    setOpen(true);
  };
  const handleSubmit = () => {
    mutate.mutate({ id: id, slug: slug, body: values });
  };
  useEffect(() => {
    if (mutate.isSuccess) {
      mutate.reset();
      queryClient.invalidateQueries("list_pending_product");
      navigate(-1);
    }
  }, [mutate.isSuccess]);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        mb: 2,
        mt: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {open && (
        <Confirm
          open={open}
          values={values}
          setClose={() => setOpen(false)}
          handleSubmit={handleSubmit}
          setValues={setValues}
        />
      )}
      <Button
        color="error"
        size="large"
        variant="contained"
        onClick={() => handleClick("blocked")}
      >
        {t("Block")}
      </Button>
      <Button
        color="warning"
        size="large"
        variant="contained"
        onClick={() => handleClick("draft")}
      >
        {t("Draft")}
      </Button>
      <Button
        color="success"
        size="large"
        variant="contained"
        onClick={() => handleClick("active")}
      >
        {t("Activate")}
      </Button>
    </Box>
  );
};
const SubmitButtonWithMutate = withMutate(SubmitButton);
function ProductTable({ slug, data }) {
  const classes = useStyles();
  const { mutate } = useContext(StaffContext);
  const [coverId, setCoverId] = useState(null);
  const [deletedId, setDeletedId] = useState([]);
  const [isWatermark, setIsWatermark] = useState(true);
  const [notify, setNotify] = useState(false);

  const {
    title,
    description,
    photos,
    location,
    release_date,
    category,
    ...tableData
  } = data;
  const { t } = useTranslation();

  useEffect(() => {
    setDeletedId([]);
    setIsWatermark(true);
  }, [data]);

  useEffect(() => {
    if (mutate.isError) setNotify(true);
  }, [mutate.isError]);

  return (
    <Box px={1.5} mt={1.5}>
      <Scrollbar>
        <CustomizedBox className={classes.box}>
          <Box width="100%">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              width="100%"
            >
              <Box width="100%" py={0}>
                <Typography className={classes.title} mb={0} variant="h4">
                  {data.title}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    my: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                      color: "text.secondary",
                      mt: 1,
                    }}
                  >
                    <LocationOnIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
                    <Typography variant="caption">{data.location}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                      color: "text.secondary",
                      mt: 1,
                    }}
                  >
                    <Category sx={{ width: 16, height: 16, mr: 0.5 }} />
                    <Typography variant="caption">
                      {t(data.category.name)}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <DateRange sx={{ width: 16, height: 16, mr: 0.5 }} />
                    <Typography mr={2} variant="caption">
                      {fDate(data.release_date)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </CustomizedBox>
        {data.description && (
          <Paper
            sx={{
              my: 2,
              p: 2.2,
              width: "100%",
              typography: "body1",
              borderRadius: "10px",
            }}
          >
            <Typography vairant="body1" color="textSecondary" fontSize="14px">
              <Box component="span" sx={{ fontSize: "1.2rem" }}>
                {data.description}
              </Box>
            </Typography>
          </Paper>
        )}
        {photos && photos.length > 0 && (
          <>
            <Gallery images={photos} showThumbnails={false} />

            <ChangeCoverImage
              photos={photos}
              coverId={coverId}
              deletedId={deletedId}
              setCoverId={setCoverId}
              setDeletedId={setDeletedId}
            />
          </>
        )}
        <Box
          sx={{
            my: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Switch
            color="secondary"
            checked={isWatermark}
            onChange={() => setIsWatermark(!isWatermark)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography color="primary" mr={2} fontSize={18} fontWeight="bold">
            {t(`Add Watermark`)}
          </Typography>
          <Box
            sx={{
              width: 60,
              height: 40,
              backgroundColor: isWatermark ? "success.dark" : "error.dark",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {isWatermark ? (
              <CheckIcon color="success" fontSize="large" />
            ) : (
              <ClearIcon color="error" fontSize="large" />
            )}
          </Box>
        </Box>
        <Attribute data={tableData} />
        <Description data={data.description} />
        <SubmitButtonWithMutate
          slug={slug}
          coverId={coverId}
          deletedId={deletedId}
          isWatermark={isWatermark}
          posting={mutate.isLoading}
          postIsError={false}
          postIsSuccess={mutate.isSuccess}
          postSuccessMsg="Success!"
          postErrorMsg={"Something is wrong."}
        />
      </Scrollbar>
      {notify && (
        <Alert
          open={notify}
          setOpen={() => setNotify(!notify)}
          message={mutate.error.detail ?? "Something is wrong."}
          severity="error"
        />
      )}
    </Box>
  );
}
export default ProductTable;

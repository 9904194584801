import { Icon } from "@iconify/react";
import CategoryIcon from "@mui/icons-material/Category";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, IconButton, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCircularLoading from "./Feedback/BasicCircularLoading";
import MenuPopover from "./MenuPopover";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "30%",
    textAlign: "center",
  },
  catBtn: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    padding: theme.spacing(0.5),
    cursor: "pointer",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  desk: {
    width: "90%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  list: {
    overflowY: "auto",
    padding: 0,
    listStyle: "none",
    height: "100%",
    fontSize: "10px",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      color: "red",
      backgroundColor: "green",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      backgroundColor: "violet",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));
export function Catagory({
  loading,
  category,
  categoryList,
  handleCatagoryChange,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [categoryName, setCategoryName] = useState("All Categories");
  const [opens, setOpens] = React.useState(
    categoryList ? categoryList.map(() => false) : []
  );
  const handleClick1 = (index) => () => {
    let newopens = opens.map((item, ind) => (index === ind ? item : false));
    if (index !== -1) {
      newopens[index] = !newopens[index];
    } else {
      setAnchorEl(null);
      handleCatagoryChange({
        name: "All categories",
        slug: "all",
        url: "all",
        id: 0,
      });
    }
    setOpens(newopens);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (prop) => {
    setAnchorEl(null);
    handleCatagoryChange(prop);
    setCategoryName(prop.name);
  };
  const { t } = useTranslation();
  return (
    <Box className={classes.root} sx={{}}>
      <Box className={classes.catBtn} sx={{}}>
        <Typography
          onClick={handleClick}
          className={classes.desk}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            height: "100%",
            width: 150,
            fontSize: "12px",
          }}
        >
          <ExpandMore />
        </Typography>
        <IconButton
          onClick={handleClick}
          sx={{
            color: "text.primary",
          }}
          aria-label="Choose-Category"
        >
          <CategoryIcon />
        </IconButton>
      </Box>
      {loading ? (
        <Box sx={{ height: 12 }}>
          <BasicCircularLoading />
        </Box>
      ) : (
        <MenuPopover
          getcontentanchorel={null}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ width: "280px" }}
        >
          <List
            sx={{ p: 2, width: "100%", maxWidth: 280 }}
            className={classes.list}
          >
            <ListItemButton
              sx={{ px: 1.3, py: 0.5 }}
              onClick={handleClick1(-1)}
            >
              <ListItemIcon>
                <Icon icon="carbon:search-locate" width="25px" height="15px" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">All Categories</Typography>
              </ListItemText>
            </ListItemButton>
            {categoryList !== undefined
              ? categoryList.map((item, index) => (
                  <Fragment key={index}>
                    <ListItemButton
                      key={item.slug}
                      disablePadding
                      sx={{ px: 1.3, py: 0.5 }}
                      onClick={handleClick1(index)}
                    >
                      <ListItemIcon>
                        <img
                          alt=""
                          width="25px"
                          height="15px"
                          src={item.icon}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body2">{t(item.name)}</Typography>
                      </ListItemText>
                      {opens[index] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={opens[index]} timeout="auto" unmountOnExit>
                      <List component="div">
                        <ListItemButton
                          sx={{ p: 0.3, pl: 4 }}
                          onClick={() => handleChange(item)}
                        >
                          <ListItemIcon>
                            <img
                              alt=""
                              width="25px"
                              height="15px"
                              src={item.icon}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="body2">
                              {t(`All of ${item.name}`)}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                        {item.children &&
                          item.children.map((subitem, subInd) => (
                            <ListItemButton
                              key={subitem.slug}
                              sx={{ p: 0.3, pl: 4 }}
                              onClick={() => handleChange(subitem)}
                            >
                              <ListItemIcon>
                                <img
                                  alt=""
                                  width="25px"
                                  height="15px"
                                  src={subitem.icon}
                                />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography variant="body2">
                                  {t(subitem.name)}
                                </Typography>
                              </ListItemText>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                  </Fragment>
                ))
              : null}
          </List>
        </MenuPopover>
      )}
    </Box>
  );
}

import { Options } from "./Options";

// Choice field name for vehicle
const seller = [
  {
    key: "seller_type",
    name: "seller_type",
    label: "Seller type",
    options: Options.seller_type,
  },
];
const condition = [
  {
    key: "condition",
    name: "condition",
    label: "Condition",
    options: Options.condition,
  },
];
const vehiclesell = [
  ...seller,
  {
    key: "fuel",
    name: "fuel",
    label: "Fuel type",
    options: Options.fuel,
  },
  {
    key: "transmission",
    name: "transmission",
    label: "Transmission type",
    options: Options.transmission,
  },
  {
    key: "turbo",
    name: "turbo",
    label: "Turbo",
    options: Options.turbo,
  },
];

const carsell = [
  ...vehiclesell,
  {
    key: "engine_cc",
    name: "engine_cc",
    label: "Engine cc",
    options: Options.engine_cc,
  },
  {
    key: "cylinder",
    name: "cylinder",
    label: "Cylinder",
    options: Options.cylinder,
  },
  {
    key: "color",
    name: "color",
    label: "Color type",
    options: Options.color,
  },
  {
    key: "regional_spec",
    name: "regional_spec",
    label: "Regional spec",
    options: Options.regional_spec,
  },
  {
    key: "body_type",
    name: "body_type",
    label: "Body type",
    options: Options.body_type,
  },
  {
    key: "car_condition",
    name: "condition",
    label: "Condition",
    options: Options.car_condition,
  },
  {
    key: "licence_plate",
    name: "licence_plate",
    label: "Licence plate",
    options: Options.licence_plate,
    dis: { condition: ["Used"] },
  },
  {
    key: "steer_position",
    name: "steer_position",
    label: "Steer position",
    options: Options.steer_position,
    dis: { condition: ["Used"] },
  },
];

const heavyvehiclesell = [
  ...vehiclesell,
  {
    key: "heavy_engine_cc",
    name: "engine_cc",
    label: "Engine cc",
    options: Options.heavy_engine_cc,
  },
  {
    key: "heavy_cylinder",
    name: "cylinder",
    label: "Cylinder",
    options: Options.heavy_cylinder,
  },
  ...condition,
  {
    key: "licence_plate",
    name: "licence_plate",
    label: "Licence plate",
    options: Options.licence_plate,
    dis: { condition: ["Used"] },
  },
];

const motorcyclesell = [
  ...seller,
  {
    key: "motor_engine_cc",
    name: "engine_cc",
    label: "Engine cc",
    options: Options.motor_engine_cc,
  },
  ...condition,
];

const threewheelersell = [
  ...seller,
  {
    key: "wheeler_engine_cc",
    name: "engine_cc",
    label: "Engine cc",
    options: Options.wheeler_engine_cc,
  },
  ...condition,
];

const vehiclerent = [
  {
    key: "price_per",
    name: "price_per",
    label: "Price per",
    options: Options.price_per,
  },
  {
    key: "transmission",
    name: "transmission",
    label: "Transmission type",
    options: Options.transmission,
  },
  {
    key: "provide_driver",
    name: "provide_driver",
    label: "Provide Driver",
    options: Options.provide_driver,
  },
  {
    key: "minimum_rent_duration",
    name: "minimum_rent_duration",
    label: "Minimum Rent Duration",
    options: Options.minimum_rent_duration,
  },
  {
    key: "maximum_rent_duration",
    name: "maximum_rent_duration",
    label: "Maximum Rent Duration",
    options: Options.maximum_rent_duration,
  },
];
const carrent = [
  ...vehiclerent,
  {
    key: "engine_cc",
    name: "engine_cc",
    label: "Engine cc",
    options: Options.engine_cc,
  },
  {
    key: "off_road_driving",
    name: "off_road_driving",
    label: "Off Road Driving",
    options: Options.off_road_driving,
  },
];
const heavyvehiclerent = [
  ...vehiclerent,
  {
    key: "heavy_engine_cc",
    name: "engine_cc",
    label: "Engine cc",
    options: Options.heavy_engine_cc,
  },
];

// Choice field name for property
const bedbathroom = [
  {
    key: "bedroom",
    name: "bedroom",
    label: "Number of bedroom",
    options: Options.bedroom,
  },
  {
    key: "bathroom",
    name: "bathroom",
    label: "Number of bathroom",
    options: Options.bathroom,
  },
];
const apartmentsell = [
  ...bedbathroom,
  ...seller,
  {
    key: "floor",
    name: "floor",
    label: "Floor number",
    options: Options.floor,
  },
];
const rent = [
  {
    key: "rent_is_paid",
    name: "rent_is_paid",
    label: "Rent is paid",
    options: Options.rent_is_paid,
  },
  {
    key: "renter_type",
    name: "renter_type",
    label: "Renter type",
    options: Options.seller_type,
  },
];
const apartmentrent = [
  ...bedbathroom,
  ...rent,
  {
    key: "floor",
    name: "floor",
    label: "Floor number",
    options: Options.floor,
  },
];
const villasell = [
  ...bedbathroom,
  ...seller,
  {
    key: "villa_type",
    name: "villa_type",
    label: "Villa type",
    options: Options.villa_type,
  },
];
const villarent = [
  ...bedbathroom,
  ...rent,
  {
    key: "villa_type",
    name: "villa_type",
    label: "Villa type",
    options: Options.villa_type,
  },
];
const realestate = [
  {
    key: "price_per",
    name: "price_per",
    label: "Price per(optinal)",
    options: Options.price_per_for_property,
    required: false,
  },
  ...bedbathroom,
  ...seller,
  {
    key: "real_estate_type",
    name: "real_estate_type",
    label: "Real estate type",
    options: Options.real_estate_type,
  },
  {
    key: "floor",
    name: "floor",
    label: "Floor number",
    options: Options.floor,
    dis: { real_estate_type: ["Apartment"] },
  },
  {
    key: "villa_type",
    name: "villa_type",
    label: "Villa type",
    options: Options.villa_type,
    dis: { real_estate_type: ["Villa"] },
  },
];
const commercial = [
  {
    key: "price_per",
    name: "price_per",
    label: "Price per(optinal)",
    options: Options.price_per_for_property,
    required: false,
  },
  ...seller,
  {
    key: "floor",
    name: "floor",
    label: "Floor number",
    options: Options.floor,
  },
  {
    key: "commercial_type",
    name: "commercial_type",
    label: "Commercial type",
    options: Options.commercial_type,
  },
];
const commercialrent = [
  {
    key: "price_per",
    name: "price_per",
    label: "Price per(optinal)",
    options: Options.price_per_for_property,
    required: false,
  },
  {
    key: "floor",
    name: "floor",
    label: "Floor number",
    options: Options.floor,
  },
  {
    key: "commercial_type",
    name: "commercial_type",
    label: "Commercial type",
    options: Options.commercial_type,
  },
  ...rent,
];
const condominium = [
  ...seller,
  {
    key: "bedroom",
    name: "bedroom",
    label: "Number of bedroom",
    options: Options.condominium_bedroom,
  },
  {
    key: "floor",
    name: "floor",
    label: "Floor number",
    options: Options.floor,
  },
];
const condominiumrent = [
  {
    key: "bedroom",
    name: "bedroom",
    label: "Number of bedroom",
    options: Options.condominium_bedroom,
  },
  {
    key: "floor",
    name: "floor",
    label: "Floor number",
    options: Options.floor,
  },
  ...rent,
];
const building = [
  ...seller,
  {
    key: "number_of_floor",
    name: "number_of_floor",
    label: "Number of floor",
    options: Options.number_of_floor,
  },
];
const buildingrent = [
  {
    key: "number_of_floor",
    name: "number_of_floor",
    label: "Number of floor",
    options: Options.number_of_floor,
  },
  ...rent,
];
const service = [
  {
    key: "room",
    name: "room",
    label: "Number of room",
    options: Options.room,
  },
  {
    key: "tenants_allowed",
    name: "tenants_allowed",
    label: "Tenants allowed",
    options: Options.tenants_allowed,
  },
  ...rent,
];
const flatmates = [
  ...service,
  {
    key: "flatmate_type",
    name: "flatmate_type",
    label: "Flatmate type",
    options: Options.flatmate_type,
  },
  {
    key: "room_type",
    name: "room_type",
    label: "Room type",
    options: Options.room_type,
  },
];

// Choice filed name for Classified
const usage = [
  {
    key: "usage",
    name: "usage",
    label: "Usage",
    options: Options.usage,
    dis: { condition: ["Used"] },
  },
];
const classifieds = [...condition, ...usage];

// Choice filed name for Electronics
const electronics = [...condition, ...usage];
const mobile = [
  {
    key: "memory",
    name: "storage",
    label: "Internal Storage",
    options: Options.memory,
  },
  {
    key: "mobile_ram",
    name: "ram",
    label: "RAM",
    options: Options.mobile_ram,
  },
  {
    key: "color",
    name: "color",
    label: "Color Type",
    options: Options.color,
    required: false,
  },
  ...condition,
  ...usage,
];

const computer = [
  {
    key: "computer_type",
    name: "computer_type",
    label: "Computer Type",
    options: Options.computer_type,
  },
  {
    key: "hard_drive",
    name: "hard_drive",
    label: "Storage",
    options: Options.hard_drive,
  },
  {
    key: "ram",
    name: "ram",
    label: "RAM",
    options: Options.ram,
  },
  {
    key: "processor_type",
    name: "processor_type",
    label: "Processor type",
    options: Options.processor_type,
  },
  {
    key: "display_size",
    name: "display_size",
    label: "Display size",
    options: Options.display_size,
  },
  {
    key: "storage_type",
    name: "storage_type",
    label: "Storage type",
    options: Options.storage_type,
  },
  {
    key: "processor_speed",
    name: "processor_speed",
    label: "Processor Speed",
    options: Options.processor_speed,
  },
  {
    key: "color",
    name: "color",
    label: "Color Type",
    options: Options.color,
    required: false,
  },
  ...condition,
  ...usage,
];
const television = [
  {
    key: "television_brand",
    name: "brand",
    label: "Brand",
    options: Options.television_brand,
  },
  {
    key: "screen_size",
    name: "screen_size",
    label: "Screen size",
    options: Options.screen_size,
  },
  ...condition,
  ...usage,
];
const refrigerator = [
  {
    key: "r_and_w_brand",
    name: "brand",
    label: "Brand",
    options: Options.r_and_w_brand,
  },
  ...condition,
  ...usage,
];
const washing_machine = [
  {
    key: "r_and_w_brand",
    name: "brand",
    label: "Brand",
    options: Options.r_and_w_brand,
  },
  ...condition,
  ...usage,
];

// Choice field name for Commenity Service
const jobemployee = [
  {
    key: "employment_type",
    name: "employment_type",
    label: "Employment Type",
    options: Options.employment_type,
  },
  {
    key: "career_level",
    name: "career_level",
    label: "Career Level",
    options: Options.career_level,
  },
  {
    key: "experience",
    name: "experience",
    label: "Experience",
    options: Options.experience,
  },
  {
    key: "education",
    name: "education",
    label: "Education",
    options: Options.education,
  },
];

// Choice field name for Job and Employee
const employee = [
  {
    key: "gender",
    name: "gender",
    label: "Gender",
    options: Options.gender,
  },
  ...jobemployee,
];

const job = [
  {
    key: "job_gender",
    name: "gender",
    label: "Gender",
    options: Options.job_gender,
  },
  ...jobemployee,
];

// Choice field name for Fashion
const fashion = [
  {
    key: "fashion_condition",
    name: "condition",
    label: "Condition",
    options: Options.fashion_condition,
  },
  {
    key: "fashion_gender",
    name: "gender",
    label: "Gender",
    options: Options.fashion_gender,
  },
];
const bag = [
  ...fashion,
  {
    key: "bag_brand",
    name: "brand",
    label: "Brand",
    options: Options.bag_brand,
  },
];
const clothing = [
  ...fashion,
  {
    key: "clothe_brand",
    name: "brand",
    label: "Brand",
    options: Options.clothe_brand,
  },
  {
    key: "occasion",
    name: "occasion",
    label: "Occasion",
    options: Options.occasion,
  },
];

const jewelry = [
  ...fashion,
  {
    key: "stone",
    name: "stone",
    label: "Stone",
    options: Options.stone,
  },
];
const watches = [
  ...fashion,
  {
    key: "watche_brand",
    name: "brand",
    label: "Brand",
    options: Options.watche_brand,
  },
  {
    key: "dial_display",
    name: "dial_display",
    label: "Dial display",
    options: Options.dial_display,
    required: false,
  },
  {
    key: "movement_type",
    name: "movement_type",
    label: "Movement type",
    options: Options.movement_type,
    required: false,
  },
];
const shoes = [
  ...fashion,
  {
    key: "shoes_brand",
    name: "brand",
    label: "Brand",
    options: Options.shoes_brand,
  },
  {
    key: "occasion",
    name: "occasion",
    label: "Occasion",
    options: Options.occasion,
  },
];

export const ChoiceName = {
  "car-for-sell": carsell,
  "motorcycle-for-sell": motorcyclesell,
  "3-wheeler-motors-for-sell": threewheelersell,
  "heavy-vehicles-for-sell": heavyvehiclesell,
  "car-for-rent": carrent,
  "wedding-car-for-rent": carrent,
  "tourist-car-for-rent": carrent,
  "heavy-vehicles-for-rent": heavyvehiclerent,
  "apartment-for-sell": apartmentsell,
  "apartment-for-rent": apartmentrent,
  "villa-for-sell": villasell,
  "villa-for-rent": villarent,
  "real-estate-for-sell": realestate,
  "commercial-for-sell": commercial,
  "commercial-for-rent": commercialrent,
  "commercial-building-for-sell": building,
  "commercial-building-for-rent": buildingrent,
  "condominium-for-sell": condominium,
  "condominium-for-rent": condominiumrent,
  "service-room-for-rent": service,
  "flatmates-room-for-rent": flatmates,
  classifieds: classifieds,
  electronics: electronics,
  computer: computer,
  "mobile-phone": mobile,
  tablet: mobile,
  television: television,
  refrigerator: refrigerator,
  "washing-machine": washing_machine,
  job: job,
  employee: employee,
  fashion: fashion,
  bags: bag,
  clothing: clothing,
  jewelry: jewelry,
  watches: watches,
  shoes: shoes,
};

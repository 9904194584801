import { Backdrop, Box, Button, ButtonBase, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import withLoading from "../../components/Feedback/withLoading";
import withMutate from "../../components/Feedback/withMutate";
import AmolePay from "../../components/Payment/AmolePay";
import ConfirmPayment from "../../components/Payment/ConfirmPayment";
import amolePNG from "../../config/images/amole.png";
import point from "../../config/images/point.png";
import telebirr from "../../config/images/telebirr.svg";
import {
  useFSPointPaymentWithTelebirr,
  useGetMyFSPoint,
  useGetSubscriptionAmount,
  useSubscriptionPaymentWithFamilysooqpoint,
  useSubscriptionPaymentWithTelebirr,
} from "../../hooks/Payment";
import { fPrice } from "../../utils/formatNumber";
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    minHeight: "calc(100vh - 160px)",
    padding: theme.spacing(2),
    background: "#fafafa",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
  payment: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  button: {
    borderRadius: "5px",
    height: 80,
    width: "100%",
    margin: theme.spacing(1.5, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "187px",
    height: "80px",
    borderRadius: "5px",
  },
}));
export function PurchaseMethod({ fspoint, type, value, setLoading }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notify, setNotify] = useState(false);
  const [amole, setAmole] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState();
  const [body, setBody] = useState({});
  const pointMutate = useFSPointPaymentWithTelebirr();
  const STMutate = useSubscriptionPaymentWithTelebirr();
  const SBMutate = useSubscriptionPaymentWithFamilysooqpoint();
  const { data: price } = useGetSubscriptionAmount(value, type);
  const handleFamilysooqpoint = () => {
    setLoading(true);
    SBMutate.mutate({ subscription_type: value });
  };
  const handleTelebirr = () => {
    setLoading(true);
    if (type === "point") pointMutate.mutate({ amount: value });
    if (type === "subscription") STMutate.mutate({ subscription_type: value });
  };
  const setErr = (err) => {
    setErrors(err.detail);
    setNotify(true);
  };
  useEffect(() => {
    setLoading(pointMutate.isLoading);
    if (pointMutate.isError) setErr(pointMutate.error);
    if (pointMutate.isSuccess) window.location.assign(pointMutate.data);
  }, [pointMutate.isSuccess, pointMutate.isError]);
  useEffect(() => {
    setLoading(STMutate.isLoading);
    if (STMutate.isError) setErr(STMutate.error);
    if (STMutate.isSuccess) window.location.assign(STMutate.data);
  }, [STMutate.isSuccess, STMutate.isError]);
  useEffect(() => {
    setLoading(SBMutate.isLoading);
    if (SBMutate.isError) setErr(SBMutate.error);
    if (SBMutate.isSuccess) navigate("/profile/subscriptions");
  }, [SBMutate.isSuccess, SBMutate.isError]);
  useEffect(() => {
    if (type === "point") setBody({ amount: value });
    else setBody({ subscription_type: value });
  }, []);
  if (amole) return <AmolePay body={body} setAmole={setAmole} type={type} />;
  else
    return (
      <Box className={classes.payment}>
        <Typography variant="body1" mb={3} color="text.secondary">
          {t("Payment options")}
        </Typography>
        <Typography variant="h3" mb={2}>
          {t("Total Amount")}{" "}
          <b>
            {fPrice(type === "subscription" && price ? price.amount : value)}
          </b>
        </Typography>
        {type === "subscription" && (
          <Box sx={{ position: "relative", width: "50%" }}>
            {open && (
              <ConfirmPayment
                handlePay={handleFamilysooqpoint}
                open={open}
                setClose={() => setOpen(false)}
              />
            )}
            <Backdrop
              sx={{
                position: "absolute",
                borderRadius: "5px",
                marginY: 1.5,
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={price && fspoint.balance < price.amount}
            />
            <ButtonBase
              className={classes.button}
              sx={{ border: "1px solid", borderColor: "primary.main" }}
              onClick={() => setOpen(true)}
            >
              <Box className={classes.logo} sx={{ border: "1px solid #fff" }}>
                <img alt="" src={point} width="100%" height="100%" />
              </Box>
              <Box width="100%">
                <Typography variant="h4" color="primary">
                  {t("FS point")}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {t("Your current balance is")} <b>{fspoint.balance}</b>
                </Typography>
              </Box>
            </ButtonBase>
          </Box>
        )}
        <Box sx={{ width: "90%" }}>
          <ButtonBase
            className={classes.button}
            sx={{ border: "1px solid #0072BC" }}
            onClick={handleTelebirr}
          >
            <Box className={classes.logo} sx={{ bgcolor: "#0072BC11" }}>
              <img alt="" src={telebirr} width="100%" height="100%" />
            </Box>
            <Box width="100%">
              <Typography variant="h4" color="#0072BC">
                {t("telebirr")}
              </Typography>
            </Box>
          </ButtonBase>
        </Box>
        <Box sx={{ width: "90%" }}>
          <ButtonBase
            className={classes.button}
            sx={{ border: "1px solid orange" }}
            onClick={() => setAmole(true)}
          >
            <Box className={classes.logo} sx={{ bgcolor: "#fed00111" }}>
              <img alt="" src={amolePNG} width="100%" height="100%" />
            </Box>
            <Box width="100%">
              <Typography variant="h4" color="orange">
                {t("Amole")}
              </Typography>
            </Box>
          </ButtonBase>
        </Box>
        <Button onClick={() => navigate(-1)}>Back</Button>
        <Alert
          open={notify}
          setOpen={() => setNotify(!notify)}
          message={errors}
          severity="error"
        />
      </Box>
    );
}
const CustomPurchaseMethod = withMutate(withLoading(PurchaseMethod));
export default function Purchase() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const { type } = useParams();
  const { data, isLoading } = useGetMyFSPoint();
  const { state } = useLocation();
  useEffect(() => {
    if (state === null) navigate(-1);
  }, [state]);

  return (
    <CustomPurchaseMethod
      fspoint={data}
      type={type}
      value={state}
      setLoading={setLoading}
      isLoading={isLoading}
      posting={Loading}
      postIsError={false}
      postIsSuccess={false}
      postSuccessMsg="Success!"
      postErrorMsg={"Please correct the errors below."}
    />
  );
}

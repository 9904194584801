import { useQuery, useInfiniteQuery } from "react-query";
import {
  ProductFeaturd,
  ProductHomeFeatured,
  getFeaturedProductInfinite,
} from "../apis";

export const useProductFeatured = (id, page = 1) => {
  return useQuery(["ProductFeatured", id], () => ProductFeaturd(id, page), {
    keepPreviousData: true,
  });
};

export const useProductHomeFeatured = (slug, location) => {
  return useQuery(
    ["ProductHomeFeatured", slug, location],
    () => ProductHomeFeatured(slug, location),
    {
      keepPreviousData: true,
    }
  );
};

export const useFeaturedProductInfinite = (slug) => {
  return useInfiniteQuery(
    ["getFeaturedProductInfinte", slug],
    getFeaturedProductInfinite,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next === null ? undefined : lastPage.next;
      },
    }
  );
};

import React, { Suspense, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import "./App.css";
import LoginDialog from "./components/Account/LoginDialog";
import Layout from "./layout/Layout";
import store from "./redux/store";
import Router from "./routes";
import ThemeConfig from "./theme";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const routePath = useLocation();
  const [stat, setStat] = useState(false);
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeConfig>
          <LoginDialog message="Login is Required" />
          <Layout>
            <Suspense fallback={<div>Loading...</div>}>
              <Router />
            </Suspense>
          </Layout>
        </ThemeConfig>
      </Provider>
    </QueryClientProvider>
  );
}

import { Box } from "@mui/system";
import { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import ActivateNav from "../../components/AdActivate/ActivateNav";
import NoPendingProduct from "../../components/AdActivate/NoPendingProduct";
import ProductTable from "../../components/AdActivate/ProductTable";
import { AppBarWithBack } from "../../components/Appbar/AppBarWithBack";
import withLoading from "../../components/Feedback/withLoading";
import {
  useActivatePendingProduct,
  useListPendingProduct,
  useRetrievePendingProduct,
} from "../../hooks";
const ProductTableWithLoading = withLoading(ProductTable);
const FetchPendingProduct = ({ data }) => {
  const { ad_id } = useParams();
  const slug = data.find((item) => item.id === ad_id).category.slug;
  const { data: product, isLoading } = useRetrievePendingProduct(ad_id, slug);
  return (
    <ProductTableWithLoading
      slug={slug}
      data={product}
      isLoading={isLoading}
      isError={false}
    />
  );
};
const NavWithLoading = withLoading(ActivateNav);
const ProductWithLoading = withLoading(FetchPendingProduct);
export const StaffContext = createContext();
function AdActivate({ user }) {
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const { data, isLoading } = useListPendingProduct();
  const mutate = useActivatePendingProduct();
  useEffect(() => {
    if (user && !user.is_staff) navigate(-1);
  }, [user]);
  return user && user.is_staff ? (
    <StaffContext.Provider value={{ mutate, id: active }}>
      {data && data.length > 0 ? (
        <Routes>
          <Route
            path=""
            element={
              <div>
                <AppBarWithBack title="Pending Products" goHome />
                <Box mt={1} px={1.5}>
                  <NavWithLoading
                    data={data}
                    setActive={setActive}
                    isLoading={isLoading}
                    isError={false}
                  />
                </Box>
              </div>
            }
          />
          <Route
            path={`:ad_id`}
            element={
              <>
                <AppBarWithBack title="Pending Products" />
                <ProductWithLoading
                  data={data}
                  isLoading={isLoading}
                  isError={false}
                />
              </>
            }
          />
        </Routes>
      ) : (
        <>
          <AppBarWithBack title="Pending Products" goHome />
          <NoPendingProduct />
        </>
      )}
    </StaffContext.Provider>
  ) : (
    <></>
  );
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps)(AdActivate);

import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { memo, useContext } from "react";
import { disableDependency } from "..";
import { RootContext } from "../../../../pages/ProductAd/AdForm";
import { fNumber } from "../../../../utils/formatNumber";
import { InputName } from "../../../../utils/Post";
import { message } from "../../../Account/Constants";
import CustomizedTextField from "../../../TextField/TextField";
import { dependencyValueCheck, numberFieldValidate } from "../FieldValidate";
function InputField({ table }) {
  const { values, errors, setErrors, setValues } = useContext(RootContext);
  const handleBlur = (e) => {
    if (e.target.required && !e.target.value) {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: message.VALUE_ERROR,
      }));
    } else if (e.target.name === "year") {
      const yyyy = new Date().getFullYear();
      if (e.target.value < 1950 || e.target.value > yyyy)
        setErrors((prev) => ({
          ...prev,
          [e.target.name]: `Valid year choice between 1950 and ${yyyy}`,
        }));
    }
  };
  const handleChange = (e) => {
    let numberError = numberFieldValidate(e);
    let error = dependencyValueCheck(e.target.name, e.target.value, values);
    if (e.target.name === "price") {
      let value = e.target.value.replaceAll(",", "");
      if (!isNaN(value))
        setValues((prev) => ({
          ...prev,
          ...error.values,
          [e.target.name]: value,
        }));
    } else
      setValues((prev) => ({
        ...prev,
        ...error.values,
        [e.target.name]: e.target.value,
      }));
    setErrors((prev) => ({ ...prev, ...error.errors, ...numberError.errors }));
  };
  const { t } = useTranslation();
  return (
    table in InputName &&
    InputName[table].map(
      (field) =>
        !(field.dis && disableDependency(field.dis, values)) && (
          <Grid item xs={12} sm={6} md={6} key={field.name}>
            <CustomizedTextField
              fullWidth
              key={field.name}
              type={field.type}
              name={field.name}
              label={t(field.label)}
              required={!("required" in field)}
              placeholder={t(field.placeholder)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values[field.name]
                  ? field.name === "price"
                    ? fNumber(values.price)
                    : values[field.name]
                  : ""
              }
              error={Boolean(errors[field.name])}
              helperText={
                errors[field.name] ? t(errors[field.name]) : t(field.helperText)
              }
              disabled={field.dis && disableDependency(field.dis, values)}
            />
          </Grid>
        )
    )
  );
}
export default memo(InputField);

import { Box } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import Appbar2 from "../components/Appbar/Appbar2";
import MobileAppBar from "../components/Appbar/MobileAppBar";
import ErrorFallbackPage from "../pages/ErrorFallbackPage";
export default function NavbarLayout() {
  return (
    <Box sx={{ height: "100vh" }}>
      <Appbar2 />
      <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
        <Outlet />
      </ErrorBoundary>
      <MobileAppBar />
    </Box>
  );
}

import queryString from "query-string";
import { api } from "./config";

export const getLatestProduct = async (payload) => {
  const response = await api.get(
    `/api/main/product/recent_ad_list/?${payload}`
  );
  return response.data;
};

export const getLatestProductInfinite = async ({ queryKey, pageParam }) => {
  let page = 1;

  if (pageParam !== undefined) {
    // const val = queryString.parse(pageParam);
    const param = pageParam.split("?")[1];
    const val = queryString.parse(param);

    page = val["page"];
    // const values = pageParam.indexOf("page=");
    // page = pageParam.charAt(parseInt(values) + 5);
  }

  const response = await api.get(
    `/api/main/product/recent_ad_list/?page=${page}`
  );
  return response.data;
};

const apartment = [
  "A/c & Heating",
  "Balcony",
  "Built-in Closet",
  "Built-in Kitchen",
  "Maid Room",
  "Parking Space",
  "Private Garden",
  "Private Jacuzzi",
  "Security",
  "Shared Gym",
  "Shared Pool",
  "Shared Spa",
  "Study Room",
  "View of Landmark",
  "Water Tank",
];

const villa = [
  "A/c & Heating",
  "Balcony",
  "Built-in Closet",
  "Built-in Kitchen",
  "Maid Room",
  "Parking Space",
  "Private Garden",
  "Private Gym",
  "Private Jacuzzi",
  "Private Pool",
  "Security",
  "Shared Spa",
  "Study Room",
  "View of Landmark",
  "Water Tank",
];

const realestate = [
  "A/c & Heating",
  "Balcony",
  "Built-in Closet",
  "Built-in Kitchen",
  "Maid Room",
  "Parking Space",
  "Private Garden",
  "Private Gym",
  "Private Jacuzzi",
  "Private Pool",
  "Security",
  "Shared Gym",
  "Shared Pool",
  "Shared Spa",
  "Study Room",
  "View of Landmark",
  "Water Tank",
];

const condominium = [
  "Balcony",
  "Built-in Closet ",
  "Built-in Kitchen",
  "Parking Space",
  "Security",
  "View of Landmark",
];

const commercial = [
  "Conference Room",
  "Generator",
  "Lift",
  "Parking Space",
  "Restaurant in Building",
  "View of Landmark",
];

const serviceroom = [
  "Attached Bathroom",
  "Balcony",
  "Free Parking",
  "Near to Main Road",
  "Pets Allowed",
  "Private Garden",
  "Security",
  "Shared Bathroom",
  "Shared Electric",
  "Shared Kitchen",
  "Shared Water",
  "Shared Wifi",
];
const flatmates = [
  "Attached Bathroom",
  "Balcony",
  "Cleaning Included",
  "Free Parking",
  "Gym",
  "Near to Main Road",
  "Shared Bathroom",
  "Shared Electric",
  "Shared Water",
  "Shared Wifi",
  "Washer and Dryer",
];

const building = [
  "A/c & Heating",
  "Garden Area",
  "Parking Space",
  "Swimming Pool",
  "View of Landmark",
];
const buildingrent = [
  "A/c & Heating",
  "Conference Room",
  "Garden Area",
  "Gym",
  "Parking Space",
  "Security",
  "Swimming Pool",
  "View of Landmark",
];
const color = [
  "Blue",
  "Black",
  "Brown",
  "Gray",
  "Purple",
  "Red",
  "White",
  "Pink",
  "Orange",
  "Rose",
  "Purple",
  "Blueblack",
  "Silver",
  "Yellow",
  "Violet",
  "Ash",
  "Azure",
  "Baby Blue",
  "Beige",
  "Bone",
  "Bronze",
  "Burgundy",
  "Carrot",
  "Cherry",
  "Chestnut",
  "Cloud",
  "Coffee",
  "Copper",
  "Coral",
  "Crimson",
  "Dove",
  "Ecru",
  "Emerald",
  "Fog",
  "Fuchsia",
  "Gold",
  "Graphite",
  "Green",
  "Honey",
  "Hot Pink",
  "Iron",
  "Ivory",
  "Khaki",
  "Lavender",
  "Lemon",
  "Light Blue",
  "Light Coral",
  "Light Green",
  "Lilac",
  "Lime",
  "Magenta",
  "Mauve",
  "Merigold",
  "Mint",
  "Multicolor",
  "Mustard",
  "Navy Blue",
  "Ochre",
  "Orange-Red",
  "Orchid",
  "Pearl",
  "Pistachio",
  "Plum",
  "Porcelain",
  "Rose Gold",
  "Ruby",
  "Sage",
  "Salmon",
  "Seafoam",
  "Tan",
  "Taupe",
  "Turquoise",
  "Wine",
  "Other",
];

const clothe_size = [
  "0-3M",
  "3-6M",
  "6-9M",
  "9-12M",
  "1-2Y",
  "2-4Y",
  "4-6Y",
  "6-8Y",
  "8-10Y",
  "10-15Y",
  "3XS",
  "XXS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "3XL",
  "4XL",
  "5XL",
  "6XL",
  "7XL",
  "Free Size",
];

const shoes_size = [
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "Free Size",
];

const materials = [
  "Cobalt Chrome",
  "Gold",
  "Platinum",
  "Stainless Steel",
  "Sterling Silver",
  "Titanium",
];

const bags = [{ name: "color", label: "Color type", options: color }];
const clothing = [
  { name: "size", label: "Size", options: clothe_size },
  ...bags,
];
const shoes = [{ name: "size", label: "Size", options: shoes_size }, ...bags];

const jewelry = [{ name: "materials", label: "Materials", options: materials }];

export const MultiSelect = {
  "apartment-for-sell": [
    { name: "amenities", label: "Amenities", options: apartment },
  ],
  "apartment-for-rent": [
    { name: "amenities", label: "Amenities", options: apartment },
  ],
  "villa-for-sell": [{ name: "amenities", label: "Amenities", options: villa }],
  "villa-for-rent": [{ name: "amenities", label: "Amenities", options: villa }],
  "real-estate-for-sell": [
    { name: "amenities", label: "Amenities", options: realestate },
  ],
  "commercial-for-sell": [
    { name: "amenities", label: "Amenities", options: commercial },
  ],
  "commercial-for-rent": [
    { name: "amenities", label: "Amenities", options: commercial },
  ],
  "commercial-building-for-sell": [
    { name: "amenities", label: "Amenities", options: building },
  ],
  "commercial-building-for-rent": [
    { name: "amenities", label: "Amenities", options: buildingrent },
  ],
  "condominium-for-sell": [
    { name: "amenities", label: "Amenities", options: condominium },
  ],
  "condominium-for-rent": [
    { name: "amenities", label: "Amenities", options: condominium },
  ],
  "service-room-for-rent": [
    { name: "amenities", label: "Amenities", options: serviceroom },
  ],
  "flatmates-room-for-rent": [
    { name: "amenities", label: "Amenities", options: flatmates },
  ],
  bags: bags,
  clothing: clothing,
  shoes: shoes,
  jewelry: jewelry,
};

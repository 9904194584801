import { message } from "./Constants";
export function validEmail(values) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values);
}
export function validPhone(values) {
  return /^((\+251)|(251)|(00251)|0|)[7-9][0-9]{8}$/i.test(values);
}
export function validTelePhone(values) {
  return /^((\+251)|(251)|(00251)|0|)[0-9]{9}$/i.test(values);
}
export function checkEmpty(e) {
  let error = {};
  for (const field of e.target.elements) {
    if (["text", "password"].includes(field.type)) {
      if (field.value === "") {
        error[field.name] = message.VALUE_ERROR;
      } else if (field.name === "email") {
        if (!(validEmail(field.value) || validPhone(field.value))) {
          if (isNaN(field.value)) error.email = message.EMAIL_ERROR;
          else error.email = message.PHONE_ERROR;
        }
      } else if (field.name === "phone_number") {
        if (!validPhone(field.value)) error.phone_number = message.PHONE_ERROR;
      }
    }
  }
  return error;
}
export function checkError(ERROR, errors, values) {
  if (typeof ERROR !== "string") {
    let err = "";
    Object.keys(ERROR).forEach((field) => {
      let errMsg = Array.isArray(ERROR[field])
        ? ERROR[field].join("\n")
        : ERROR[field];
      if (!Object.keys(values).includes(field)) err += `${field}=${errMsg} `;
      else errors[field] = errMsg;
    });
    if (err != "") errors.error = err;
  } else errors.error = ERROR;
}

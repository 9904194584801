import { Box } from "@mui/system";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetNotificationList,
  useGetUnReadNotificationCount,
  useMarkNotificationAsRead,
  useRemoveNotification,
} from "../../hooks";
import { set_chats, set_notifications } from "../../redux/actions/auth";
import WebSocketInstance from "../../services/notificationWebSocket";
import CustomNotificationsList from "./NotificationsList";
function Index({
  notifications,
  chat_notifications,
  set_notifications,
  set_chats,
  user,
  token,
}) {
  const { mutate } = useMarkNotificationAsRead();
  const { mutate: removeNotificationMutate } = useRemoveNotification();
  const [open, setOpen] = useState(false);
  const { data: unread_noti } = useGetUnReadNotificationCount();
  const { mutate: markAsRead } = useMarkNotificationAsRead();
  const navigate = useNavigate();
  useEffect(() => {
    if (!Boolean(unread_noti)) return;
    set_notifications({ ...notifications, count: unread_noti.ns_count });
    set_chats({
      ...chat_notifications,
      count: unread_noti.chat_count,
    });
  }, [unread_noti]);
  const handleChatClick = () => {
    markAsRead("msg");
    navigate("/chat");
  };
  const wfcd = useMemo(
    () => debounce(() => WebSocketInstance.connect(token), 500),
    [token]
  );
  wfcd();
  WebSocketInstance.addCallbacks(
    (data) => () => console.info("getting new messages"),
    (data) => {
      if (data && data.target && data.target.target_type === "Message") {
        set_chats({
          ...chat_notifications,
          count: chat_notifications.count + 1,
        });
      } else {
        set_notifications({
          count: notifications.count + 1,
          nf_list: [data, ...notifications.nf_list],
        });
      }
    }
  );
  const handleMarkAllAsRead = () => {
    mutate(-1);
    const new_nf_list = notifications.nf_list.map((notification) => ({
      ...notification,
      unread: false,
    }));
    set_notifications({ count: 0, nf_list: new_nf_list });
  };
  const handleRemoveNotification = (id) => {
    removeNotificationMutate(id);
    if (id === "-1") {
      set_notifications({ ...notifications, nf_list: [] });
    } else {
      const new_nf_list = notifications.nf_list.filter(
        (notification) => notification.notification_id !== id
      );
      set_notifications({ ...notifications, nf_list: new_nf_list });
    }
  };
  const handleMarkAsRead = (id, close = false) => {
    mutate(id);
    const new_nf_list = notifications.nf_list.map((notification) => ({
      ...notification,
      unread: notification.notification_id === id ? false : notification.unread,
    }));
    set_notifications({
      count: notifications.count === 0 ? 0 : notifications.count - 1,
      nf_list: new_nf_list,
    });
  };
  return (
    <>
      <Box>
        <CustomNotificationsList
          notifications={notifications}
          set_notifications={set_notifications}
          getList={useGetNotificationList}
          onMarkAsRead={handleMarkAsRead}
          onMarkAllAsRead={handleMarkAllAsRead}
          onRemoveNotification={handleRemoveNotification}
        />
      </Box>
    </>
  );
}
const mapStateToProps = (state) => ({
  token: state.auth.token,
  notifications: state.auth.notifications,
  chat_notifications: state.auth.chat_notifications,
});
export default connect(mapStateToProps, {
  set_notifications,
  set_chats,
})(Index);

import { useQuery, useInfiniteQuery } from "react-query";
import { getSimilarProductInfinite, RelatedProduct } from "../apis";

export const useRelatedProduct = (productId) => {
  return useQuery(
    ["ProductFeatured", productId],
    () => RelatedProduct(productId),
    {
      keepPreviousData: true,
    }
  );
};

export const useSimilarProductInfinite = (id) => {
  return useInfiniteQuery(
    ["getSimilarProductInfinte", id],
    getSimilarProductInfinite,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next === null ? undefined : lastPage.next;
      },
    }
  );
};

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import MoreHorizoIcon from "@mui/icons-material/MoreHoriz";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
const ITEM_HEIGHT = 48;
export function MoreButton({ onMarkAsRead, onRemoveNotification }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  return (
    <div>
      <IconButton
        edge="end"
        aria-label="more"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="notification-item"
        color="primary"
        sx={{
          margin: 0,
          padding: 0,
        }}
      >
        <MoreHorizoIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            onMarkAsRead();
          }}
        >
          <ListItemIcon>
            <CheckIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" fontSize="small">
            {t("Mark all as read")}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onRemoveNotification("-1");
          }}
        >
          <ListItemIcon>
            <ClearIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" fontSize="small">
            {t("Remove all notification")}
          </Typography>
        </MenuItem>
        <MenuItem component={RouterLink} to="/profile/setting">
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" fontSize="small">
            {t("Go to notification settings")}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

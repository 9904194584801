import chevronRight from "@iconify/icons-carbon/chevron-right";
import { Icon } from "@iconify/react";
import { Box, ListItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useThirdLevelCatagories } from "../../hooks/catagory";
import { AppBarWithBack } from "../Appbar/AppBarWithBack";
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  item: {
    display: "flex",
    alignItems: "center",
    flexDirection: "raw",
    fontWeight: 200,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textDecoration: "none",
    color: "inherit",
  },
  title: {
    fontWeight: 400,
  },
  link: {
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: theme.spacing(2),
  },
}));
export function CategorySelector({}) {
  const classes = useStyles();
  const { slug } = useParams();
  const [subCategory, setSubCategory] = useState([]);
  const { isSuccess, data: CategoryLists } = useThirdLevelCatagories();
  useEffect(() => {
    if (slug && isSuccess) {
      setSubCategory(CategoryLists.find((item) => item.slug === slug).children);
    } else if (isSuccess) {
      setSubCategory(CategoryLists);
    }
  }, [slug, isSuccess]);
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <AppBarWithBack title="Choose sub category" />
      {subCategory.map((item, index) => (
        <ListItem
          key={item.name + index}
          component={RouterLink}
          to={item.slug}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            textDecoration: "none",
            color: "inherit",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Box className={classes.item}>
            <img
              style={{ objectFit: "contain" }}
              alt=""
              width="60px"
              height="60px"
              src={item.icon}
            />
            <Box>
              <Typography className={classes.link} variant="h3">
                {t(item.name)}{" "}
              </Typography>
            </Box>
          </Box>
          <Icon style={{ marginRight: "15px" }} icon={chevronRight} />
        </ListItem>
      ))}
    </Box>
  );
}

import { Box, styled } from "@mui/material";
const CustomizedBox = styled((props) => <Box {...props} />)((props) => ({
  position: "relative",
  display: "block",
  background: "background.paper",
  borderRadius: "10px",
  marginBottom: "30px",
  padding: "14px 10px",
  boxShadow:
    "rgba(23, 43, 77, 0.2) 0px 1px 1px, rgba(23, 43, 77, 0.2) 0px 0px 1px",
}));
export default CustomizedBox;

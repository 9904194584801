import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { load_user } from "../redux/actions/auth";
import { get_location } from "../redux/actions/location";
const Layout = ({ load_user, get_location, isAuthenticated, children }) => {
  useEffect(async () => {
    if (localStorage.getItem("token")) await load_user();
  }, [load_user, children]);
  useEffect(() => {
    get_location();
  }, [isAuthenticated]);
  return <Fragment>{children}</Fragment>;
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { load_user, get_location })(Layout);

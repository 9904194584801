import { useTranslation } from "react-i18next";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function CustomFullDialog(props) {
  const { open, handleClose, dialogTitle, keepMounted, children } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted={keepMounted}
    >
      <AppBar sx={{ position: "relative", bgcolor: "background.paper" }}>
        <Toolbar>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            color="primary"
            variant="h4"
            textAlign="center"
          >
            {t(dialogTitle)}
          </Typography>
          <IconButton
            edge="start"
            color="primary"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}

import { useQuery, useInfiniteQuery } from "react-query";
import { ProductFilter } from "../apis";

export const useProductFilter = (payload, sort, page) => {
  return useInfiniteQuery(
    ["ProductFilter", payload, sort, page],
    () => ProductFilter(payload, sort, page),
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next === null ? undefined : lastPage.next;
      },
      // enabled: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const dimension = {
  width: 800,
  height: 600,
  coverWidth: 400,
  coverHeight: 300,
  minWidth: 600,
  minHeight: 450,
};

export function Compressor(file, waterMark) {
  return new Promise(async (resolve, reject) => {
    let img = new Image();
    img.src = URL.createObjectURL(file);
    img.onerror = reject;
    img.onload = async (e) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (waterMark) {
        canvas.height = dimension.height;
        if (e.target.width >= e.target.height) {
          canvas.width = dimension.width;
        } else {
          canvas.width = e.target.width * (dimension.height / e.target.height);
        }

        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "rgba(255, 255, 255, 0.50)";
        ctx.font = "25px Russo One";
        ctx.fillText("FAMILYSOOQ", canvas.width / 2 - 75, canvas.height / 2);
      } else {
        canvas.height = dimension.coverHeight;
        if (e.target.width >= e.target.height) {
          canvas.width = dimension.coverWidth;
        } else {
          canvas.width =
            e.target.width * (dimension.coverHeight / e.target.height);
        }

        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
      }

      let quality = 1;
      const compressedImageBlob = await new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            }
          },
          "image/jpeg",
          quality
        );
      });

      let fileName = file.name.slice(0, file.name.lastIndexOf(".")) + ".jpeg";
      if (fileName.length > 70) fileName = fileName.slice(40);
      const compressedImage = new File([compressedImageBlob], fileName, {
        type: "image/jpeg",
      });
      resolve(compressedImage);
    };
  });
}

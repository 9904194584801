import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import NavButton from "../Button/NavButton";
function PostAdButton({ isAuthenticated, navigate }) {
  const { t } = useTranslation();
  return (
    <NavButton
      sx={{ bgcolor: "secondary.light", ml: 1.5 }}
      size="small"
      onClick={() => {
        if (!isAuthenticated) {
          navigate("/signin?next=/post-ad/");
        } else navigate("/post-ad");
      }}
    >
      {t("Post Your Ad")}
    </NavButton>
  );
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps)(PostAdButton);

import { Grid, TextField } from "@mui/material";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { RootContext } from "../../../../pages/ProductAd/AdForm";
import Editor from "../../../Editor/Editor";
import CustomizedTextField from "../../../TextField/TextField";
function TextAreaField({ table }) {
  const { values, setValues } = useContext(RootContext);
  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onResponsiblityChange = (value) => {
    setValues((prev) => ({ ...prev, responsibilities: value }));
  };
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        <TextField
          rows={5}
          fullWidth
          multiline={true}
          name="description"
          variant="outlined"
          label={t("Description")}
          onChange={handleChange}
          value={"description" in values ? values["description"] : ""}
          sx={{
            "& .MuiOutlinedInput-root": {
              background: "background.paper",
              fontSize: "14px",
              "&:hover fieldset": {
                borderColor: (theme) => theme.palette.secondary.main,
              },
            },
          }}
        />
      </Grid>
      {table === "job" && (
        <>
          <Grid item xs={12}>
            <Editor
              simple
              id="editor-id"
              value={values.responsibilities ? values.responsibilities : ""}
              onChange={(value) => onResponsiblityChange(value)}
              placeholder={t("Type a responsibilities")}
              sx={{ borderColor: "transparent" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomizedTextField
              fullWidth
              type="date"
              name="deadline"
              label={t("Deadline")}
              variant="outlined"
              onChange={handleChange}
              value={
                "deadline" in values
                  ? values["deadline"]
                  : new Date().toISOString().slice(0, 10)
              }
            />
          </Grid>
        </>
      )}
    </>
  );
}
export default memo(TextAreaField);

import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import Facebook from "./components/Account/Facebook";
import Google from "./components/Account/Google";
import VerifyCode from "./components/Account/VerifyCode";
import Loading from "./components/Feedback/PageLoading";
import { CategorySelector } from "./components/Navigation/CatagorySelector";
import NavbarLayout from "./layout/NavabarWithFooterLayout";
import NavbarLayoutWithOutAppBar from "./layout/navabarWithOutAppBar";
import SearchLayout from "./layout/searchLayout";
import TopLogoOnly from "./layout/TopLogoOnly";
import AdActivate from "./pages/AdActivate";
import Notifications from "./pages/Notifications";
import NotFound from "./pages/Page404";
import Payment from "./pages/Payment";
import Purchase from "./pages/Payment/Purchase";
import Search from "./pages/Searching";
//
const DownloadApp = lazy(() => import("./pages/DownloadApp"));

const UserProductList = lazy(() => import("./pages/Product/UserProducts"));
const Signup = lazy(() => import("./pages/Account/Auth/Signup"));
const Signin = lazy(() => import("./pages/Account/Auth/Signin"));
const Activate = lazy(() => import("./pages/Account/Auth/Activate"));
const MarketingInfo = lazy(() => import("./components/Account/MarketingInfo"));
const PhoneConfirm = lazy(() => import("./pages/Account/Auth/PhoneConfirm"));
const ResetPassword = lazy(() => import("./pages/Account/Auth/ResetPassword"));
const ResetPasswordConfirm = lazy(() =>
  import("./pages/Account/Auth/ResetPasswordConfirm")
);
const TwoStepVerification = lazy(() =>
  import("./pages/Account/Auth/TwoStepVerification")
);
const PrivacyPolicy = lazy(() =>
  import("./pages/TermsCondition/PrivacyPolicy")
);
const Terms = lazy(() => import("./pages/TermsCondition/Terms.js"));
const SafteyTips = lazy(() => import("./pages/SafteyTips"));
const Faqs = lazy(() => import("./pages/Faqs"));
const Home = lazy(() => import("./pages/Home"));
const Main = lazy(() => import("./pages/MainCatagory/MainCatagory"));
const Filter = lazy(() => import("./pages/MainCatagory/Filtering"));
const Profile = lazy(() => import("./pages/Account"));
const Chat = lazy(() => import("./pages/Chat"));
const AdDetail = lazy(() => import("./pages/AdDetail"));
const AllFeatured = lazy(() => import("./pages/AllProduct/AllFeatured"));
const AllLatestProduct = lazy(() => import("./pages/Product/RecentProduct"));
const RecommendProductPage = lazy(() =>
  import("./pages/Product/RecommendedProductPage")
);
const Post = lazy(() => import("./pages/ProductAd/Post"));
const Update = lazy(() => import("./pages/ProductAd/Update"));
const UpdateImage = lazy(() =>
  import("./components/ProductAd/Update/UpdateImage")
);
const Pricing = lazy(() => import("./components/Pricing/subscription"));

function Router({ isAuthenticated }) {
  return useRoutes([
    {
      path: "/",
      element: <NavbarLayout />,
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          ),
        },

        {
          path: "featured/:slug",
          element: (
            <Suspense fallback={<Loading />}>
              <AllFeatured />
            </Suspense>
          ),
        },
        {
          path: "recent-products",
          element: (
            <Suspense fallback={<Loading />}>
              <AllLatestProduct />
            </Suspense>
          ),
        },
        {
          path: "recommended-products",
          element: (
            <Suspense fallback={<Loading />}>
              <RecommendProductPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/",
      element: <NavbarLayoutWithOutAppBar />,
      children: [
        {
          path: "category/",
          element: (
            <Suspense fallback={<Loading />}>
              <CategorySelector />
            </Suspense>
          ),
        },
        {
          path: "category/:slug",
          element: (
            <Suspense fallback={<Loading />}>
              <CategorySelector />
            </Suspense>
          ),
        },
        {
          path: "detail/:ad_id",
          element: (
            <Suspense fallback={<Loading />}>
              <AdDetail />
            </Suspense>
          ),
        },
        {
          path: "product-list/:type/:user_id",
          element: (
            <Suspense fallback={<Loading />}>
              <UserProductList />
            </Suspense>
          ),
        },
        {
          path: "chat/*",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Chat />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/chat" />
          ),
        },
        {
          path: "product/activate/*",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <AdActivate />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/product/activate/" />
          ),
        },
        {
          path: "notifications",
          element: (
            <Suspense fallback={<Loading />}>
              <Notifications />
            </Suspense>
          ),
        },
        {
          path: "profile/*",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Profile />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/profile" />
          ),
        },
      ],
    },

    {
      path: "/",
      element: <SearchLayout />,
      children: [
        { path: "search", element: <Search /> },

        {
          path: "category/:name/*",
          element: (
            <Suspense fallback={<Loading />}>
              {" "}
              <Filter />
            </Suspense>
          ),
        },
      ],
    },

    {
      path: "/",
      element: <TopLogoOnly />,
      children: [
        {
          path: "post-review/:id",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Payment />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/post-review/:id" />
          ),
        },
        {
          path: "/purchase/:type/",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Purchase />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/purchase/type/" />
          ),
        },

        {
          path: "/packages/list",
          element: (
            <Suspense fallback={<Loading />}>
              <Pricing />
            </Suspense>
          ),
        },
        {
          path: "post-ad",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Post />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/post-ad" />
          ),
        },
        {
          path: "update-ad/:id",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Update />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/update-ad/:id" />
          ),
        },
        {
          path: "update-image/:id",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <UpdateImage />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/update-image/:id" />
          ),
        },

        {
          path: "signin",
          element: (
            <Suspense fallback={<Loading />}>
              <Signin />
            </Suspense>
          ),
        },
        {
          path: "signup",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <Signup />
            </Suspense>
          ),
        },
        {
          path: "activate/:id",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <Activate />
            </Suspense>
          ),
        },
        {
          path: "two-step-verification/",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <TwoStepVerification />)
          
            </Suspense>
          ),
        },
        {
          path: "app",
          element: (
            <Suspense fallback={<Loading />}>
              <DownloadApp />
            </Suspense>
          ),
        },
        {
          path: "marketing-info/:id",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <MarketingInfo />
            </Suspense>
          ),
        },
        {
          path: "phone/change/confirm/:id",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <PhoneConfirm />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/phone/change/confirm/:id" />
          ),
        },
        {
          path: "password/reset",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <ResetPassword />
            </Suspense>
          ),
        },
        {
          path: "password/reset/confirm/:id",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <ResetPasswordConfirm />
            </Suspense>
          ),
        },

        { path: "google", element: <Google /> },
        { path: "facebook", element: <Facebook /> },
        // { path: "activate", element: <Activate /> },

        // { path: "password/reset", element: <ResetPassword /> },
        // { path: "password/reset/confirm", element: <ResetPasswordConfirm /> },
        // { path: "phone/change/confirm", element: <AddPhoneConfirm /> },
        { path: "account/verify-code", element: <VerifyCode /> },

        {
          path: "/packages/list",
          element: (
            <Suspense fallback={<Loading />}>
              <Pricing />
            </Suspense>
          ),
        },
        {
          path: "privacy_policy",
          element: (
            <Suspense fallback={<Loading />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "terms",
          element: (
            <Suspense fallback={<Loading />}>
              <Terms />
            </Suspense>
          ),
        },
        {
          path: "saftey-tips",
          element: (
            <Suspense fallback={<Loading />}>
              <SafteyTips />
            </Suspense>
          ),
        },
        {
          path: "support/faqs",
          element: (
            <Suspense fallback={<Loading />}>
              <Faqs />
            </Suspense>
          ),
        },
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(Router);

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "10px",
  padding: "8px 10px",
  fontSize: theme.spacing(1.5),
  fontWeight: "normal",
}));
export default function NavButton({ children, ...otherProps }) {
  return <StyledButton {...otherProps}>{children}</StyledButton>;
}

import { Box, Chip, Skeleton, Stack, Typography } from "@mui/material";
import queryString from "query-string";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Scrollbar from "../../components/Scrollbar";
export default function ResultOverview({ data, isLoading }) {
  const { search } = useLocation();
  const [active, setActive] = useState(
    data ? data.found_result.map((item, ind) => (ind === 0 ? true : false)) : []
  );
  const navigate = useNavigate();
  const values = queryString.parse(search);
  const handleClick = (slug, ind) => {
    let temp = data.found_result.map((item, i) => (i === ind ? true : false));
    setActive(temp);
    navigate(`/search?query=${values.query}&category=${slug}`);
  };
  const { t } = useTranslation();
  return (
    <Box sx={{ mt: 3, mb: 2, width: "100%" }}>
      {data === null ? (
        <>
          <Typography variant="h4">
            <Skeleton width={200} />
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Skeleton sx={{ borderRadius: 20, mr: 1 }} width={90} height={50} />
            <Skeleton sx={{ borderRadius: 20, mr: 1 }} width={90} height={50} />
            <Skeleton sx={{ borderRadius: 20, mr: 1 }} width={90} height={50} />
            <Skeleton sx={{ borderRadius: 20, mr: 1 }} width={90} height={50} />
          </Box>
        </>
      ) : (
        <>
          <Typography
            variant="subtitle1"
            sx={{
              width: "100%",
              whiteSpace: "wrap",
              mb: 1,
            }}
          >
            {t("Found")}{" "}
            <Typography variant="h5" component="span" color="secondary">
              {" "}
              {data && "total_result" in data && data.total_result}{" "}
            </Typography>
            {t("search results for")}
            <Typography variant="subtitle1" component="span" color="secondary">
              {" "}
              "{data && "query" in data && data.query}"
            </Typography>
          </Typography>
          <Scrollbar
            sx={{
              height: "100%",
              "& .simplebar-content": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <Stack direction="row" spacing={1}>
              {data &&
                "found_result" in data &&
                data.found_result.map((item, index) => (
                  <Chip
                    size="small"
                    key={index}
                    label={t(item.category + " " + item.count)}
                    onClick={() => handleClick(item.category_slug, index)}
                    sx={{
                      bgcolor: `${
                        active[index] ? "secondary.lighter" : "default"
                      }`,
                    }}
                  />
                ))}
            </Stack>
          </Scrollbar>
        </>
      )}
    </Box>
  );
}

import {
  CheckCircleOutline as CheckCircleIcon,
  CloseOutlined as CloseCircleIcon,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedBox from "../Feedback/CustomizedBox";
function Attribute({ data }) {
  const { t } = useTranslation();
  const getTitleLabel = (label) => {
    let atrs = label
      .split("_")
      .map((x) => x)
      .join(" ");
    if (atrs.slice(0, 2) === "is") atrs = atrs.slice(3);
    return atrs.charAt(0).toUpperCase() + atrs.slice(1);
  };

  return (
    <CustomizedBox
      sx={{
        my: 1.5,
        pb: 0,
        border: "1px solid",
        borderColor: "divider",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Box width="100%" mb={1}>
        <Typography variant="h6">{t("Detail Info")}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {Object.keys(data).map((item, index) => (
          <>
            {data[item] ? (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  minWidth: 200,
                  width: "99%",
                  padding: "10px 15px",
                  borderRadius: "10px",
                  bgcolor: "rev_grey.300",
                  textTransform: "capitalize",
                  alignItems: "center",
                }}
              >
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    textTransform: "capitalize",
                    fontSize: "14px",
                  }}
                >
                  {t(getTitleLabel(item))}
                </Typography>
                <Typography fontSize="14px" variant="subtitle2" noWrap>
                  {typeof data[item] === "boolean" ? (
                    data[item] ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CloseCircleIcon color="error" />
                    )
                  ) : (
                    data[item]
                  )}
                </Typography>
              </Box>
            ) : null}
          </>
        ))}
      </Box>
    </CustomizedBox>
  );
}
export default Attribute;

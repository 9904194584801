import { useQuery } from "react-query";
import {
  get_feedback,
  get_review,
  get_feedbacks_non_authenticated,
} from "../apis";
export const useGetFeedback = (page, review) => {
  return useQuery(["feedback", page, review], () => get_feedback(page, review));
};
export const useGetReviewNonAuthenticated = (page, id) => {
  return useQuery(["get_feedbacks_non_authenticated", page, id], () =>
    get_feedbacks_non_authenticated(page, id)
  );
};
export const useGetReview = (page, id) => {
  return useQuery(["get_feedbacks", page, id], () => get_review(page, id));
};

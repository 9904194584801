import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useShowPhoneNumber } from "../../hooks/Product";
import withLoginRequired from "./withLoginRequired";
const WithAuthFavIcon = withLoginRequired(IconButton);
const WithAuthFavBtn = withLoginRequired(Button);
export default function ShowPhoneNumber({ phoneNumber, id, button }) {
  const [open, setOpen] = useState(false);
  const { mutate } = useShowPhoneNumber();
  const handleClick = (e) => {
    setOpen(true);
    if (!open) mutate(id);
  };
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      {!button ? (
        <>
          <WithAuthFavIcon onClick={(e) => handleClick(e)} variant="contained">
            <Box
              sx={{
                display: "flex",
                alingItems: "center",
                color: "primary.main",
              }}
            >
              <LocalPhoneIcon fontSize="medium" />
              {!open && (
                <Typography fontSize="13px">
                  {t("Show Phone Number")}
                </Typography>
              )}
            </Box>
          </WithAuthFavIcon>
          {open && <Typography fontSize="12px">{phoneNumber}</Typography>}
        </>
      ) : (
        <WithAuthFavBtn
          sx={{
            width: "100%",
          }}
          color="secondary"
          variant="contained"
          startIcon={<LocalPhoneIcon />}
          onClick={(e) => handleClick(e)}
        >
          {open ? phoneNumber : t("Show Phone Number")}
        </WithAuthFavBtn>
      )}
    </Box>
  );
}

import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import amolePNG from "../../config/images/amole.png";
import point from "../../config/images/point.png";
import telebirr from "../../config/images/telebirr.svg";
import {
  useGetMyFSPointAndSubscription,
  useProductPaymentWithFamilysooqpoint,
  useProductPaymentWithSubscription,
  useProductPaymentWithTelebirr,
} from "../../hooks/Payment";
import { fPrice } from "../../utils/formatNumber";
import { fDateMonth } from "../../utils/formatTime";
import Alert from "../Alert/Alert";
import AmolePay from "./AmolePay";
import ConfirmPayment from "./ConfirmPayment";
const useStyles = makeStyles((theme) => ({
  payment: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: theme.spacing(0, 2.5),
  },
  button: {
    borderRadius: "5px",
    height: 80,
    width: "100%",
    margin: theme.spacing(1.5, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "187px",
    height: "80px",
    borderRadius: "5px",
  },
}));
export default function PaymentMethod({ body, product, ...props }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [balance, setBalance] = useState(false);
  const [notify, setNotify] = useState(false);
  const [amole, setAmole] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const { setReview, setLoading } = props;
  const BMutate = useProductPaymentWithFamilysooqpoint();
  const TMutate = useProductPaymentWithTelebirr();
  const SMutate = useProductPaymentWithSubscription();
  const { data, isSuccess, isLoading } = useGetMyFSPointAndSubscription(body);
  useEffect(() => {
    if (isSuccess)
      setBalance(data.find((item) => item.type === "familysooqpoint"));
  }, [data, isSuccess]);
  const handleFamilysooqpoint = () => {
    setLoading(true);
    BMutate.mutate(body);
  };
  const handleTelebirr = () => {
    setLoading(true);
    TMutate.mutate(body);
  };
  const handleSubscription = (id) => {
    body.subscription_pk = id;
    setLoading(true);
    SMutate.mutate(body);
  };
  const setErr = (err) => {
    setErrors(err);
    if (err.detail) setNotify(true);
  };
  useEffect(() => {
    setLoading(BMutate.isLoading);
    if (BMutate.isError) setErr(BMutate.error);
    if (BMutate.isSuccess) navigate(`/detail/${product.id}`);
  }, [BMutate.isError, BMutate.isSuccess]);
  useEffect(() => {
    setLoading(TMutate.isLoading || TMutate.isSuccess);
    if (TMutate.isError) setErr(TMutate.error);
    if (TMutate.isSuccess) window.location.assign(TMutate.data);
  }, [TMutate.isError, TMutate.isSuccess]);
  useEffect(() => {
    setLoading(SMutate.isLoading);
    if (SMutate.isError) setErr(SMutate.error);
    if (SMutate.isSuccess) navigate(`/detail/${product.id}`);
  }, [SMutate.isError, SMutate.isSuccess]);
  if (amole)
    return <AmolePay body={body} setAmole={setAmole} type="product_post" />;
  else
    return (
      <Box className={classes.payment}>
        {open && (
          <ConfirmPayment
            handlePay={handleFamilysooqpoint}
            open={open}
            setClose={() => setOpen(false)}
          />
        )}
        <Backdrop
          sx={{
            position: "absolute",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {!isLoading && (
          <>
            {" "}
            <Typography variant="h4" mb={3}>
              {t("Payment options")}
            </Typography>
            <Typography mb={3} variant="h3" color="text.secondary">
              {t("Total Amount to be paid")}{" "}
              <Typography variant="h3" color="text.primary" component="span">
                <b>{fPrice(body.amount)}</b>{" "}
              </Typography>
            </Typography>
            {balance && (
              <Box sx={{ position: "relative", width: "100%" }}>
                <Backdrop
                  sx={{
                    position: "absolute",
                    borderRadius: "5px",
                    marginY: 1.5,
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={balance.balance < body.amount}
                />
                <ButtonBase
                  className={classes.button}
                  sx={{
                    border: "1px solid",
                    borderColor: "primary.main",
                    width: "100%",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <Box
                    className={classes.logo}
                    sx={{ border: "1px solid #fff" }}
                  >
                    <img alt="" src={point} width="100%" height="100%" />
                  </Box>
                  <Box width="100%">
                    <Typography variant="h4" color="primary">
                      {t("FS point")}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {t("Your current balance is")} <b>{balance.balance}</b>
                    </Typography>
                  </Box>
                </ButtonBase>
              </Box>
            )}
            <Box sx={{ width: "100%" }}>
              <ButtonBase
                className={classes.button}
                sx={{ border: "1px solid #0072BC" }}
                onClick={handleTelebirr}
              >
                <Box className={classes.logo} sx={{ bgcolor: "#0072BC11" }}>
                  <img alt="" src={telebirr} width="100%" height="100%" />
                </Box>
                <Box width="100%">
                  <Typography variant="h4" color="#0072BC">
                    {t("telebirr")}
                  </Typography>
                </Box>
              </ButtonBase>
            </Box>
            <Box sx={{ width: "100%" }}>
              <ButtonBase
                className={classes.button}
                sx={{ border: "1px solid orange" }}
                onClick={() => {
                  setErrors({});
                  setAmole(true);
                }}
              >
                <Box className={classes.logo} sx={{ bgcolor: "#fed00111" }}>
                  <img alt="" src={amolePNG} width="100%" height="100%" />
                </Box>
                <Box width="100%">
                  <Typography variant="h4" color="orange">
                    {t("Amole")}
                  </Typography>
                </Box>
              </ButtonBase>
            </Box>
            {data &&
              data.map(
                (subscribe) =>
                  subscribe.type === "subscription" && (
                    <Box sx={{ width: "50%" }}>
                      <ButtonBase
                        className={classes.button}
                        sx={{
                          border: "1px solid",
                          borderColor: "primary.main",
                        }}
                        onClick={() => handleSubscription(subscribe.id)}
                      >
                        <Box
                          className={classes.logo}
                          sx={{ border: "1px solid #fff" }}
                        >
                          <img
                            alt=""
                            src={subscribe.subscription_type.icon}
                            width="100%"
                            height="100%"
                          />
                        </Box>
                        <Box width="100%">
                          <Typography variant="h4" color="primary">
                            {t("Subscription")}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            <b>{t(subscribe.subscription_type.title)}</b>
                          </Typography>
                          <br />
                          <Typography variant="caption" color="text.secondary">
                            {t("Expired on")}{" "}
                            <b>{fDateMonth(subscribe.expired_date)}</b>
                          </Typography>
                        </Box>
                      </ButtonBase>
                    </Box>
                  )
              )}
            <Button onClick={() => setReview(true)}>Back</Button>
            <Alert
              open={notify}
              setOpen={() => setNotify(!notify)}
              message={errors.detail}
              severity="error"
            />
          </>
        )}
      </Box>
    );
}

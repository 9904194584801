import { Container, Grid } from "@mui/material";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductList from "../../components/Feedback/ProductList";
import NavSection from "../../components/NavSection";
import Page from "../../components/Page";
import { useSearchAd } from "../../hooks";
import Filtering from "./Filtering";
import ResultOverview from "./ResultOverview";
export default function Index() {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [fil, setFil] = useState({});
  const [filterUrl, setFilterUrl] = useState("");
  const [query, setQuery] = useState("");
  const [cleanValuesFil, setCleanValuesFil] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [overViewData, setOverViewData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const { isLoading, fetchNextPage, hasNextPage, data } = useSearchAd({
    values: values,
    fil: cleanValuesFil,
  });
  useEffect(() => {
    let parame = {};
    let item;
    for (item in fil) {
      if (fil[item] !== "All" && fil[item] !== "all" && fil[item]) {
        parame[item] = fil[item];
      }
    }
    setCleanValuesFil(parame);
  }, [fil]);
  useEffect(() => {
    if (data) {
      const temp = [];
      data.pages.forEach((element) => {
        element.results.forEach((item) => {
          temp.push(item);
        });
      });
    }
  }, [data]);
  useEffect(() => {
    if (data && data.pages) {
      if (data.pages[0].filter_category !== filterUrl) {
        setFilterUrl(data.pages[0].filter_category);
      }
    }
  }, [data]);
  useEffect(() => {
    if (data && data.pages[0].query !== query) {
      setOverViewData(data.pages[0]);
      setQuery(data.pages[0].query);
    }
  }, [data]);
  const handleSortClick = (value) => {
    setFil({
      ...cleanValuesFil,
      order_by: value,
    });
    setAnchorEl(null);
  };
  return (
    <Page title="searching">
      <Container sx={{ my: 2 }}>
        <ResultOverview isLoading={data === null} data={overViewData} />
      </Container>
      <Grid container>
        <Grid item md={1}></Grid>
        <Grid item xs={12} md={12}>
          <Filtering
            handleSortClick={handleSortClick}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            setOpen={setOpen}
          />
          <Container>
            <NavSection
              url={filterUrl}
              setValues={setFil}
              open={open}
              setOpen={setOpen}
            />
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <ProductList
            data={data}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
    </Page>
  );
}

import { Box, CircularProgress } from "@mui/material";
export default function PageLoading({ ...props }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        height: "100%",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "background.paper",
        }}
      >
        <CircularProgress size="25px" {...props} />
      </Box>
    </Box>
  );
}

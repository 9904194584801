import arrowLeft from "@iconify/icons-carbon/arrow-left";
import { Icon } from "@iconify/react";
import { Box, Divider, IconButton, List, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import NotificationItem from "../../components/Feedback/Notification/NotificationItem";
import Scrollbar from "../../components/Scrollbar";
import { MoreButton } from "./MoreButton";
export default function CustomNotificationsList({
  notifications,
  set_notifications,
  getList,
  onMarkAsRead,
  onMarkAllAsRead,
  onRemoveNotification,
}) {
  const { isLoading, hasNextPage, fetchNextPage, data } = getList();
  const navigate = useNavigate();
  const handleBack = () => {
    if (true) navigate("/");
    else navigate(-1);
  };
  useEffect(() => {
    if (data) {
      let nf = [];
      let allnf = [];
      data.pages.forEach((element) => {
        element.results.forEach((item) => {
          if (item && item.target && item.target.target_type === "Message")
            allnf.push(item);
          else {
            nf.push(item);
            allnf.push(item);
          }
        });
      });
      set_notifications({ ...notifications, nf_list: allnf });
    }
  }, [data]);
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 1.5 }}>
        <Box
          component={IconButton}
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "25px",
            color: "primary.main",
            color: "text.primary",
            mr: 4,
          }}
          size="small"
          onClick={handleBack}
        >
          <Icon icon={arrowLeft} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">{t("Notifications")}</Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {t("unreadMessage", { count: notifications.count })}
          </Typography>
        </Box>
        <MoreButton
          onMarkAsRead={onMarkAllAsRead}
          onRemoveNotification={onRemoveNotification}
        />
      </Box>
      <Scrollbar sx={{ height: { sm: "auto" } }}>
        <div
          id="notifiScrollableDiv"
          style={{
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={notifications.nf_list.length} //This is important field to render the next data
            next={() => fetchNextPage()}
            hasMore={hasNextPage}
            loader={
              <div className="loader">
                {[1, 2, 3].map(() => (
                  <NotificationItem isLoading={true} />
                ))}
              </div>
            }
            scrollableTarget="notifiScrollableDiv"
          >
            <Divider />
            <List disablePadding>
              {isLoading
                ? [1, 2, 3, 4].map(() => <NotificationItem isLoading={true} />)
                : notifications.nf_list.map((item) => (
                    <NotificationItem
                      onMarkAsRead={onMarkAsRead}
                      onRemoveNotification={onRemoveNotification}
                      isLoading={isLoading}
                      notification={item}
                      showDesc
                    />
                  ))}
            </List>
          </InfiniteScroll>
        </div>
      </Scrollbar>
    </>
  );
}

import { Icon } from "@iconify/react";
import { Badge, IconButton } from "@mui/material";
import { memo, useEffect } from "react";
import { useQueryClient } from "react-query";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCountPendingProduct } from "../../hooks";
import withLoginRequired from "../Feedback/withLoginRequired";
const StaffWithLogin = withLoginRequired(IconButton);
function StaffNotify({ user }) {
  const { data } = useCountPendingProduct();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries("list_pending_product");
  }, [data]);
  return user && user.is_staff && data ? (
    <StaffWithLogin
      ref={null}
      sx={{ bgcolor: "inherit", color: "text.primary", ml: 1.5 }}
      onClick={() => navigate("/product/activate")}
    >
      <Badge badgeContent={data.count} color="error">
        <Icon icon="material-symbols:pending-outline" width="25" height="25" />
      </Badge>
    </StaffWithLogin>
  ) : (
    <></>
  );
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps)(memo(StaffNotify));

import { connect } from "react-redux";
import UserMenus from "./UserMenus";
function Authentication({ user, token }) {
  return <UserMenus user={user} token={token} />;
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
});
export default connect(mapStateToProps)(Authentication);

import ArrowRight from "@iconify/icons-eva/arrow-ios-forward-outline";
import { Icon } from "@iconify/react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { memo } from "react";
import { useTranslation } from "react-i18next";
function Breadcrumb(props) {
  const { setCompanies, path, goBack } = props;
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Breadcrumbs
        separator={
          <Box
            component={Icon}
            icon={ArrowRight}
            sx={{ width: 16, height: 16 }}
          />
        }
        sx={{ display: "flex", justifyContent: "center" }}
        aria-label="breadcrumb"
      >
        {path.map(
          (item, index) =>
            index < path.length - 1 && (
              <Link
                key={item}
                underline="hover"
                color="secondary"
                sx={{ cursor: "pointer", fontSize: "14px" }}
                onClick={() => goBack(item)}
              >
                {t(item)}
              </Link>
            )
        )}
      </Breadcrumbs>
    </Box>
  );
}
export default memo(Breadcrumb);

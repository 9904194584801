import { Box, Button, Chip, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedTextField from "../TextField/TextField";
function CustomRadioInput({
  choice,
  handleChange,
  filterOption,
  selected,
  onSelectedValue,
}) {
  const handleClick = (e) => {
    onSelectedValue({
      ...selected,
      [choice.name]: e,
    });
    handleChange({
      target: {
        value: e,
        name: choice.name,
      },
    });
  };
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: "1px solid lightgrey",
        p: 1.9,
      }}
    >
      <Chip
        component={Button}
        label={t("All")}
        value="All"
        color={
          "All" === selected[choice.name] || selected[choice.name] === undefined
            ? "secondary"
            : "default"
        }
        onClick={() => handleClick("All")}
        sx={{
          m: 0.5,
          "&:hover": {
            bgcolor: "secondary.main",
          },
        }}
        disableRipple
      />
      {filterOption[choice.key].children.map((item, index) => (
        <Chip
          component={Button}
          disableRipple
          onChange={(e) => console.info()}
          label={t(item.title)}
          value={item.value}
          key={item.title}
          color={item.title === selected[choice.name] ? "secondary" : "default"}
          onClick={() => handleClick(item.title)}
          sx={{
            m: 0.5,
            "&:hover": {
              bgcolor: "secondary.main",
            },
          }}
        />
      ))}
    </Box>
  );
}
function SelectInput({ choice, handleChange, filterOption, selected }) {
  const { t } = useTranslation();
  return (
    <Box
      px={1.2}
      sx={{
        borderBottom: "1px dashed lightgrey",
      }}
    >
      <CustomizedTextField
        key={choice.key}
        required
        select
        name={choice.name}
        onChange={handleChange}
        size="small"
        value={selected[choice.name] ? selected[choice.name] : "All"}
        sx={{ mb: 1.7 }}
      >
        <MenuItem value="All"> {t("All")} </MenuItem>
        {filterOption[choice.key].children.map((item, ind) => (
          <MenuItem key={"sel-" + item.title + "-" + ind} value={item.value}>
            {t(item.title)}
          </MenuItem>
        ))}
      </CustomizedTextField>
    </Box>
  );
}
export default function FilterInput({
  choice,
  handleChange,
  filterOption,
  selected,
  onSelectedValue,
}) {
  const { t } = useTranslation();
  const label = filterOption ? filterOption[choice.key].label : null;
  return (
    <>
      <Box
        sx={{
          display: "block",
          fontSize: "14px",
          mb: 0.1,
          mx: 1.2,
          mt: 1.2,
          color: "primary.light",
        }}
      >
        {t(label)}
      </Box>
      {filterOption[choice.key].children.length < 4 ? (
        <CustomRadioInput
          choice={choice}
          handleChange={handleChange}
          filterOption={filterOption}
          selected={selected}
          onSelectedValue={onSelectedValue}
        />
      ) : (
        <SelectInput
          choice={choice}
          handleChange={handleChange}
          filterOption={filterOption}
          selected={selected}
        />
      )}
    </>
  );
}

import ArrowDropDownIcon from "@mui/icons-material//ArrowDropDown";
import { Box, ButtonBase, MenuItem } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ColorModeContext } from "../../theme";
import MenuPopover from "../MenuPopover";
const lang_options = [
  { label: "Light Mode", value: "light" },
  { label: "Dark Mode", value: "dark" },
  { label: "Use system", value: "sys" },
];
export function ThemeMode({ setOpen }) {
  const { mode, setMode } = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  useEffect(() => {
    lang_options.map((item, ind) => {
      if (item.value === mode) setSelectedIndex(ind);
    });
  }, [mode]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (_event, index) => {
    setSelectedIndex(index);
    setMode.toggleColorMode(lang_options[index].value);
    setAnchorEl(null);
    setOpen(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  return (
    <Box>
      <ButtonBase
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{}}
      >
        {lang_options[selectedIndex].label}
        <ArrowDropDownIcon fontSize="14px" />
      </ButtonBase>
      <MenuPopover
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {lang_options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {t(option.label)}
          </MenuItem>
        ))}
      </MenuPopover>
    </Box>
  );
}

import { styled } from "@mui/material/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
const CoverImgStyle = styled(LazyLoadImage)({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});
export default function ImagePlaceHolder({ src, ...props }) {
  return (
    <CoverImgStyle
      src={src}
      effect="blur"
      width="100%"
      height="100%"
      {...props}
    />
  );
}

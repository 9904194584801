import axios from "axios";
import { api } from "../../apis/config";
import { BASE_URL } from "../../config";
import WebSocketInstance from "../../services/notificationWebSocket";
import {
  CLOSE_LOGIN_DIALOG,
  FACEBOOK_AUTH_FAIL,
  FACEBOOK_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  GOOGLE_AUTH_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  OPEN_LOGIN_DIALOG,
  SET_CHAT_NOTIFICATIONS,
  SET_DIALOG_CLOSING_ACTION,
  SET_NOTIFICATIONS,
  USER_LOADED_FAIL,
  USER_LOADED_SUCCESS,
} from "./types";

export const set_notifications = (payload) => {
  return { type: SET_NOTIFICATIONS, payload: payload };
};

export const set_chats = (payload) => {
  return { type: SET_CHAT_NOTIFICATIONS, payload: payload };
};

export const set_dialog_closing_action = (fun) => {
  return { type: SET_DIALOG_CLOSING_ACTION, payload: fun };
};
export const open_login_dialog = (fun) => {
  return { type: OPEN_LOGIN_DIALOG, payload: fun };
};

export const close_login_dialog = () => {
  return { type: CLOSE_LOGIN_DIALOG };
};
export const load_user = () => async (dispatch) => {
  await api
    .get(`/api/auth/user/me/`)
    .then((res) => {
      dispatch({ type: USER_LOADED_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      if (err.response && err.response.statusText === "Unauthorized") {
        dispatch({ type: USER_LOADED_FAIL, payload: err.response });
      }
    });
};

export const login = (values) => async (dispatch) => {
  let response = {};
  await api
    .post(`/api/auth/login/`, values)
    .then((res) => {
      if (res.data.two_factor_auth) response = res.data;
      else {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        dispatch({ type: CLOSE_LOGIN_DIALOG });
      }
    })
    .catch((err) => {
      if (err.response) response = err.response.data;
      else response.error = ["Error: Network Error or server down"];
      dispatch({ type: LOGIN_FAIL, payload: err.response });
    });

  return response;
};

export const LOGIN = (payload) => async (dispatch) => {
  dispatch({ type: LOGIN_SUCCESS, payload: payload });
  dispatch({ type: CLOSE_LOGIN_DIALOG });
  return;
};

export const googleAuthenticate = (state, code) => async (dispatch) => {
  let ERROR = {};
  const config = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  const details = { state: state, code: code };
  const formBody = Object.keys(details)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
    )
    .join("&");
  await axios
    .post(`${BASE_URL}/api/auth/o/google-oauth2/?${formBody}`, config)
    .then((res) => {
      dispatch({ type: GOOGLE_AUTH_SUCCESS, payload: res.data });
      if (res.data.created) ERROR.created = res.data.created;
      dispatch(load_user());
    })
    .catch((err) => {
      if (err.response) ERROR = err.response.data;
      else ERROR.error = ["Error: Network Error or server down"];
      dispatch({ type: GOOGLE_AUTH_FAIL, payload: err.response });
    });

  return ERROR;
};

export const facebookAuthenticate = (state, code) => async (dispatch) => {
  let ERROR = {};
  const config = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  const details = { state: state, code: code };
  const formBody = Object.keys(details)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
    )
    .join("&");
  await axios
    .post(`${BASE_URL}/api/auth/o/facebook/?${formBody}`, config)
    .then((res) => {
      dispatch({ type: FACEBOOK_AUTH_SUCCESS, payload: res.data });
      if (res.data.created) ERROR.created = res.data.created;
      dispatch(load_user());
    })
    .catch((err) => {
      if (err.response) ERROR = err.response.data;
      else ERROR.error = ["Error: Network Error or server down"];
      dispatch({ type: FACEBOOK_AUTH_FAIL, payload: err.response });
    });

  return ERROR;
};

export const logout = () => (dispatch) => {
  api.post(`/api/auth/logout/`);
  const socketRef = WebSocketInstance.socketRef;
  if (socketRef != null || socketRef != undefined) {
    socketRef.close();
  }
  dispatch({ type: LOGOUT });
};

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  allowImageNull,
  RootContext,
} from "../../../../pages/ProductAd/AdForm";
import { fileSize } from "../../../../utils/fileSize";
import getFileType from "../../../../utils/fileTypeChecker";
import { CheckboxName } from "../../../../utils/Post";
const useStyles = makeStyles(() => ({
  inputFile: {
    backgroundColor: "#fff",
    padding: "11px 14px",
    borderRadius: "6px",
    border: "1px inset rgb(145 158 171 / 20%)",
    "&:hover": { borderColor: "rgb(118, 118, 118)" },
  },
  error: {
    color: "#FF4842",
    lineHeight: "15px",
    fontSize: "0.75rem",
    fontFamily: "Public Sans,sans-serif",
    fontWeight: "400",
    margin: "2px 14px",
  },
}));
function CheckBoxField({ table }) {
  const classes = useStyles();
  const { values, errors, setErrors, setValues } = useContext(RootContext);
  const handleChange = (e) => {
    if (e.target.name === "certificate" || e.target.name === "cv") {
      let msg = null;
      if (getFileType(e.target.files[0]) === "pdf") {
        if (e.target.files[0].size > fileSize.pdf) {
          msg = `File size exceeds the maximum limit`;
        } else
          setValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.files[0],
          }));
      } else {
        msg = "Upload valid pdf file please.";
      }
      setErrors((prev) => ({ ...prev, [e.target.name]: msg }));
    } else
      setValues((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const radioHandleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  useEffect(() => {
    table in CheckboxName &&
      CheckboxName[table].map(
        (checkbox) => (values[checkbox.name] = values[checkbox.name] || false)
      );
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <Grid container>
        {table in CheckboxName &&
          CheckboxName[table].map((checkbox) => (
            <Grid item xs={12} sm={6} key={checkbox.name}>
              <FormControlLabel
                key={checkbox.name}
                control={
                  <Checkbox
                    color="secondary"
                    key={checkbox.name}
                    name={checkbox.name}
                    checked={
                      values[checkbox.name] ? values[checkbox.name] : false
                    }
                    onChange={handleChange}
                  />
                }
                label={t(checkbox.label)}
              />
            </Grid>
          ))}
        {!allowImageNull.includes(table) && (
          <Grid item xs={12} sm={6} mt={3}>
            <FormControl>
              <FormLabel>{t("Price is")} :</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="is_negotiable"
                value={values.is_negotiable ? values.is_negotiable : false}
                onChange={radioHandleChange}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t("Fixed")}
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={t("Negotiable")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        {table === "employee" && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography>{t("CV")} </Typography>
              <Box className={classes.inputFile} sx={{ mr: "7px" }}>
                <input
                  name="cv"
                  type="file"
                  accept="application/pdf"
                  onChange={handleChange}
                />
              </Box>
              <Typography variant="body2" color="text.secondary">
                {t("Maximum allowed size is _KB", {
                  size: fileSize.pdf / 1000,
                })}
              </Typography>
              <Box className={classes.error}>{t(errors.cv)}</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>{t("Certificate")}</Typography>
              <Box className={classes.inputFile} sx={{ ml: "7px" }}>
                <input
                  type="file"
                  accept="application/pdf"
                  name="certificate"
                  onChange={handleChange}
                />
              </Box>
              <Typography variant="body2" color="text.secondary">
                {t("Maximum allowed size is _KB", {
                  size: fileSize.pdf / 1000,
                })}
              </Typography>
              <Box className={classes.error}>{t(errors.certificate)}</Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
export default memo(CheckBoxField);

const options = {
  seller_type: {
    label: "Seller type",
    children: [
      {
        title: "Owner",
        value: "Owner",
      },
      {
        title: "Broker",
        value: "Broker",
      },
    ],
  },
  fuel: {
    label: "Fuel type",
    children: [
      {
        title: "Benzene",
        value: "Benzene",
      },
      {
        title: "Diesel",
        value: "Diesel",
      },
      {
        title: "Hybrid",
        value: "Hybrid",
      },
      {
        title: "Electric",
        value: "Electric",
      },
    ],
  },
  transmission: {
    label: "Transmission",
    children: [
      {
        title: "Manual",
        value: "Manual",
      },
      {
        title: "Automatic",
        value: "Automatic",
      },
      {
        title: "Semi Auto",
        value: "Semi Auto",
      },
    ],
  },
  turbo: {
    label: "Turbo",
    children: [
      {
        title: "Available",
        value: "Available",
      },
      {
        title: "Not Available",
        value: "Not Available",
      },
    ],
  },
  engine_cc: {
    label: "Engine cc",
    children: [
      {
        title: "0.6L",
        value: "0.6L",
      },
      {
        title: "0.8L",
        value: "0.8L",
      },
      {
        title: "1.0L",
        value: "1.0L",
      },
      {
        title: "1.1L",
        value: "1.1L",
      },
      {
        title: "1.2L",
        value: "1.2L",
      },
      {
        title: "1.3L",
        value: "1.3L",
      },
      {
        title: "1.4L",
        value: "1.4L",
      },
      {
        title: "1.5L",
        value: "1.5L",
      },
      {
        title: "1.6L",
        value: "1.6L",
      },
      {
        title: "1.7L",
        value: "1.7L",
      },
      {
        title: "1.8L",
        value: "1.8L",
      },
      {
        title: "2.0L",
        value: "2.0L",
      },
      {
        title: "2.2L",
        value: "2.2L",
      },
      {
        title: "2.3L",
        value: "2.3L",
      },
      {
        title: "2.5L",
        value: "2.5L",
      },
      {
        title: "2.6L",
        value: "2.6L",
      },
      {
        title: "2.8L",
        value: "2.8L",
      },
      {
        title: "3.0L",
        value: "3.0L",
      },
      {
        title: "3.2L",
        value: "3.2L",
      },
      {
        title: "3.3L",
        value: "3.3L",
      },
      {
        title: "3.5L",
        value: "3.5L",
      },
      {
        title: "3.7L",
        value: "3.7L",
      },
      {
        title: "3.8L",
        value: "3.8L",
      },
      {
        title: "3.9L",
        value: "3.9L",
      },
      {
        title: "4.1L",
        value: "4.1L",
      },
      {
        title: "4.2L",
        value: "4.2L",
      },
      {
        title: "4.3L",
        value: "4.3L",
      },
      {
        title: "4.4L",
        value: "4.4L",
      },
      {
        title: "4.8L",
        value: "4.8L",
      },
      {
        title: "4.9L",
        value: "4.9L",
      },
      {
        title: "5.0L",
        value: "5.0L",
      },
      {
        title: "5.2L",
        value: "5.2L",
      },
      {
        title: "5.7L",
        value: "5.7L",
      },
      {
        title: "5.8L",
        value: "5.8L",
      },
      {
        title: "5.9L",
        value: "5.9L",
      },
      {
        title: "6.0L",
        value: "6.0L",
      },
      {
        title: "6.2L",
        value: "6.2L",
      },
      {
        title: "6.6L",
        value: "6.6L",
      },
      {
        title: "6.9L",
        value: "6.9L",
      },
      {
        title: "7.0L",
        value: "7.0L",
      },
      {
        title: "7.4L",
        value: "7.4L",
      },
      {
        title: "7.5L",
        value: "7.5L",
      },
      {
        title: "7.6L",
        value: "7.6L",
      },
      {
        title: "7.8L",
        value: "7.8L",
      },
      {
        title: "8.1L",
        value: "8.1L",
      },
      {
        title: "8.2L",
        value: "8.2L",
      },
      {
        title: "8.3L",
        value: "8.3L",
      },
      {
        title: "8.6L",
        value: "8.6L",
      },
      {
        title: "9.0L",
        value: "9.0L",
      },
      {
        title: "9.1L",
        value: "9.1L",
      },
      {
        title: "9.3L",
        value: "9.3L",
      },
      {
        title: "10.0L",
        value: "10.0L",
      },
      {
        title: "10.4L",
        value: "10.4L",
      },
      {
        title: "10.5L",
        value: "10.5L",
      },
      {
        title: "11.0L",
        value: "11.0L",
      },
      {
        title: "11.6L",
        value: "11.6L",
      },
      {
        title: "12.0L",
        value: "12.0L",
      },
      {
        title: "12.2L",
        value: "12.2L",
      },
      {
        title: "13.9L",
        value: "13.9L",
      },
      {
        title: "14.0L",
        value: "14.0L",
      },
      {
        title: "14.2L",
        value: "14.2L",
      },
      {
        title: "14.6L",
        value: "14.6L",
      },
      {
        title: "14.8L",
        value: "14.8L",
      },
      {
        title: "15.2L",
        value: "15.2L",
      },
      {
        title: "18.0L",
        value: "18.0L",
      },
      {
        title: "18.8L",
        value: "18.8L",
      },
      {
        title: "19.0L+",
        value: "19.0L+",
      },
    ],
  },
  cylinder: {
    label: "Cylinder",
    children: [
      {
        title: "3",
        value: "3",
      },
      {
        title: "4",
        value: "4",
      },
      {
        title: "5",
        value: "5",
      },
      {
        title: "6",
        value: "6",
      },
      {
        title: "8",
        value: "8",
      },
      {
        title: "10",
        value: "10",
      },
      {
        title: "12",
        value: "12",
      },
    ],
  },
  color: {
    label: "Color Type",
    children: [
      {
        title: "White",
        value: "White",
      },
      {
        title: "Black",
        value: "Black",
      },
      {
        title: "Silver",
        value: "Silver",
      },
      {
        title: "Blue",
        value: "Blue",
      },
      {
        title: "Red",
        value: "Red",
      },
      {
        title: "Natural",
        value: "Natural",
      },
      {
        title: "Green",
        value: "Green",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  regional_spec: {
    label: "Regional spec",
    children: [
      {
        title: "European Specs",
        value: "European Specs",
      },
      {
        title: "GCC Specs",
        value: "GCC Specs",
      },
      {
        title: "Japanese Specs",
        value: "Japanese Specs",
      },
      {
        title: "North American Specs",
        value: "North American Specs",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  body_type: {
    label: "Body type",
    children: [
      {
        title: "Coupe",
        value: "Coupe",
      },
      {
        title: "Crossover",
        value: "Crossover",
      },
      {
        title: "Hard Top Convertaible",
        value: "Hard Top Convertaible",
      },
      {
        title: "Hatchback",
        value: "Hatchback",
      },
      {
        title: "Pick Up Truck",
        value: "Pick Up Truck",
      },
      {
        title: "Sedan",
        value: "Sedan",
      },
      {
        title: "Soft Top Convirtible",
        value: "Soft Top Convirtible",
      },
      {
        title: "Sports Car",
        value: "Sports Car",
      },
      {
        title: "Utility Truck",
        value: "Utility Truck",
      },
      {
        title: "Suv",
        value: "Suv",
      },
      {
        title: "Van",
        value: "Van",
      },
      {
        title: "Wagon",
        value: "Wagon",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  car_condition: {
    label: "Condition",
    children: [
      {
        title: "New (0-4000 KM)",
        value: "New (0-4000 KM)",
      },
      {
        title: "Used",
        value: "Used",
      },
    ],
  },
  licence_plate: {
    label: "Licence plate",
    children: [
      {
        title: "Registered",
        value: "Registered",
      },
      {
        title: "Not Registered",
        value: "Not Registered",
      },
    ],
  },
  steer_position: {
    label: "Steer position",
    children: [
      {
        title: "Original left",
        value: "Original left",
      },
      {
        title: "Original right",
        value: "Original right",
      },
    ],
  },
  motor_engine_cc: {
    label: "Engine cc",
    children: [
      {
        title: "0.6L",
        value: "0.6L",
      },
      {
        title: "0.8L",
        value: "0.8L",
      },
      {
        title: "1.0L",
        value: "1.0L",
      },
      {
        title: "1.1L",
        value: "1.1L",
      },
      {
        title: "1.2L",
        value: "1.2L",
      },
      {
        title: "1.3L",
        value: "1.3L",
      },
      {
        title: "1.4L",
        value: "1.4L",
      },
      {
        title: "1.5L",
        value: "1.5L",
      },
      {
        title: "1.6L",
        value: "1.6L",
      },
      {
        title: "1.7L",
        value: "1.7L",
      },
      {
        title: "1.8L",
        value: "1.8L",
      },
      {
        title: "2.0L",
        value: "2.0L",
      },
      {
        title: "2.2L",
        value: "2.2L",
      },
      {
        title: "2.3L",
        value: "2.3L",
      },
      {
        title: "2.5L",
        value: "2.5L",
      },
      {
        title: "2.6L",
        value: "2.6L",
      },
      {
        title: "2.8L",
        value: "2.8L",
      },
      {
        title: "3.0L",
        value: "3.0L",
      },
      {
        title: "3.2L",
        value: "3.2L",
      },
      {
        title: "3.3L",
        value: "3.3L",
      },
      {
        title: "3.5L",
        value: "3.5L",
      },
      {
        title: "3.7L",
        value: "3.7L",
      },
      {
        title: "3.8L",
        value: "3.8L",
      },
      {
        title: "3.9L",
        value: "3.9L",
      },
      {
        title: "4.1L",
        value: "4.1L",
      },
      {
        title: "4.2L",
        value: "4.2L",
      },
      {
        title: "4.3L",
        value: "4.3L",
      },
      {
        title: "4.4L",
        value: "4.4L",
      },
      {
        title: "4.8L",
        value: "4.8L",
      },
      {
        title: "4.9L",
        value: "4.9L",
      },
      {
        title: "5.0L",
        value: "5.0L",
      },
      {
        title: "5.2L",
        value: "5.2L",
      },
      {
        title: "5.7L",
        value: "5.7L",
      },
      {
        title: "5.8L",
        value: "5.8L",
      },
      {
        title: "5.9L",
        value: "5.9L",
      },
      {
        title: "6.0L",
        value: "6.0L",
      },
      {
        title: "6.2L",
        value: "6.2L",
      },
      {
        title: "6.6L",
        value: "6.6L",
      },
      {
        title: "6.9L",
        value: "6.9L",
      },
      {
        title: "7.0L",
        value: "7.0L",
      },
      {
        title: "7.4L",
        value: "7.4L",
      },
      {
        title: "7.5L",
        value: "7.5L",
      },
      {
        title: "7.6L",
        value: "7.6L",
      },
      {
        title: "7.8L",
        value: "7.8L",
      },
      {
        title: "8.1L",
        value: "8.1L",
      },
      {
        title: "8.2L",
        value: "8.2L",
      },
      {
        title: "8.3L",
        value: "8.3L",
      },
      {
        title: "8.6L",
        value: "8.6L",
      },
    ],
  },
  condition: {
    label: "Condition",
    children: [
      {
        title: "New",
        value: "New",
      },
      {
        title: "Used",
        value: "Used",
      },
    ],
  },
  wheeler_engine_cc: {
    label: "Engine cc",
    children: [
      {
        title: "0.6L",
        value: "0.6L",
      },
      {
        title: "0.8L",
        value: "0.8L",
      },
      {
        title: "1.0L",
        value: "1.0L",
      },
      {
        title: "1.1L",
        value: "1.1L",
      },
      {
        title: "1.2L",
        value: "1.2L",
      },
      {
        title: "1.3L",
        value: "1.3L",
      },
      {
        title: "1.4L",
        value: "1.4L",
      },
      {
        title: "1.5L",
        value: "1.5L",
      },
      {
        title: "1.6L",
        value: "1.6L",
      },
      {
        title: "1.7L",
        value: "1.7L",
      },
      {
        title: "1.8L",
        value: "1.8L",
      },
      {
        title: "2.0L",
        value: "2.0L",
      },
      {
        title: "2.2L",
        value: "2.2L",
      },
      {
        title: "2.3L",
        value: "2.3L",
      },
      {
        title: "2.5L",
        value: "2.5L",
      },
      {
        title: "2.6L",
        value: "2.6L",
      },
      {
        title: "2.8L",
        value: "2.8L",
      },
      {
        title: "3.0L",
        value: "3.0L",
      },
      {
        title: "3.2L",
        value: "3.2L",
      },
      {
        title: "3.3L",
        value: "3.3L",
      },
      {
        title: "3.5L",
        value: "3.5L",
      },
      {
        title: "3.7L",
        value: "3.7L",
      },
      {
        title: "3.8L",
        value: "3.8L",
      },
      {
        title: "3.9L",
        value: "3.9L",
      },
      {
        title: "4.1L",
        value: "4.1L",
      },
      {
        title: "4.2L",
        value: "4.2L",
      },
      {
        title: "4.3L",
        value: "4.3L",
      },
      {
        title: "4.4L",
        value: "4.4L",
      },
      {
        title: "4.8L",
        value: "4.8L",
      },
      {
        title: "4.9L",
        value: "4.9L",
      },
      {
        title: "5.0L",
        value: "5.0L",
      },
      {
        title: "5.2L",
        value: "5.2L",
      },
      {
        title: "5.7L",
        value: "5.7L",
      },
      {
        title: "5.8L",
        value: "5.8L",
      },
      {
        title: "5.9L",
        value: "5.9L",
      },
      {
        title: "6.0L",
        value: "6.0L",
      },
      {
        title: "6.2L",
        value: "6.2L",
      },
      {
        title: "6.6L",
        value: "6.6L",
      },
      {
        title: "6.9L",
        value: "6.9L",
      },
      {
        title: "7.0L",
        value: "7.0L",
      },
      {
        title: "7.4L",
        value: "7.4L",
      },
      {
        title: "7.5L",
        value: "7.5L",
      },
      {
        title: "7.6L",
        value: "7.6L",
      },
      {
        title: "7.8L",
        value: "7.8L",
      },
      {
        title: "8.1L",
        value: "8.1L",
      },
      {
        title: "8.2L",
        value: "8.2L",
      },
      {
        title: "8.3L",
        value: "8.3L",
      },
      {
        title: "8.6L",
        value: "8.6L",
      },
    ],
  },
  heavy_engine_cc: {
    label: "Engine cc",
    children: [
      {
        title: "4.1L",
        value: "4.1L",
      },
      {
        title: "4.2L",
        value: "4.2L",
      },
      {
        title: "4.3L",
        value: "4.3L",
      },
      {
        title: "4.4L",
        value: "4.4L",
      },
      {
        title: "4.8L",
        value: "4.8L",
      },
      {
        title: "4.9L",
        value: "4.9L",
      },
      {
        title: "5.0L",
        value: "5.0L",
      },
      {
        title: "5.2L",
        value: "5.2L",
      },
      {
        title: "5.7L",
        value: "5.7L",
      },
      {
        title: "5.8L",
        value: "5.8L",
      },
      {
        title: "5.9L",
        value: "5.9L",
      },
      {
        title: "6.0L",
        value: "6.0L",
      },
      {
        title: "6.2L",
        value: "6.2L",
      },
      {
        title: "6.6L",
        value: "6.6L",
      },
      {
        title: "6.9L",
        value: "6.9L",
      },
      {
        title: "7.0L",
        value: "7.0L",
      },
      {
        title: "7.4L",
        value: "7.4L",
      },
      {
        title: "7.5L",
        value: "7.5L",
      },
      {
        title: "7.6L",
        value: "7.6L",
      },
      {
        title: "7.8L",
        value: "7.8L",
      },
      {
        title: "8.1L",
        value: "8.1L",
      },
      {
        title: "8.2L",
        value: "8.2L",
      },
      {
        title: "8.3L",
        value: "8.3L",
      },
      {
        title: "8.6L",
        value: "8.6L",
      },
      {
        title: "9.0L",
        value: "9.0L",
      },
      {
        title: "9.1L",
        value: "9.1L",
      },
      {
        title: "9.3L",
        value: "9.3L",
      },
      {
        title: "10.0L",
        value: "10.0L",
      },
      {
        title: "10.4L",
        value: "10.4L",
      },
      {
        title: "10.5L",
        value: "10.5L",
      },
      {
        title: "11.0L",
        value: "11.0L",
      },
      {
        title: "11.6L",
        value: "11.6L",
      },
      {
        title: "12.0L",
        value: "12.0L",
      },
      {
        title: "12.2L",
        value: "12.2L",
      },
      {
        title: "13.9L",
        value: "13.9L",
      },
      {
        title: "14.0L",
        value: "14.0L",
      },
      {
        title: "14.2L",
        value: "14.2L",
      },
      {
        title: "14.6L",
        value: "14.6L",
      },
      {
        title: "14.8L",
        value: "14.8L",
      },
      {
        title: "15.2L",
        value: "15.2L",
      },
      {
        title: "18.0L",
        value: "18.0L",
      },
      {
        title: "18.8L",
        value: "18.8L",
      },
      {
        title: "19.0L+",
        value: "19.0L+",
      },
    ],
  },
  heavy_cylinder: {
    label: "Cylinder",
    children: [
      {
        title: "3",
        value: "3",
      },
      {
        title: "4",
        value: "4",
      },
      {
        title: "5",
        value: "5",
      },
      {
        title: "6",
        value: "6",
      },
      {
        title: "8",
        value: "8",
      },
      {
        title: "10",
        value: "10",
      },
      {
        title: "12",
        value: "12",
      },
    ],
  },
  price_per: {
    label: "Price per",
    children: [
      {
        title: "Square meter",
        value: "Square meter",
      },
    ],
  },
  provide_driver: {
    label: "Prover Driver",
    children: [
      {
        title: "Yes",
        value: "Yes",
      },
      {
        title: "No",
        value: "No",
      },
      {
        title: "Optional",
        value: "Optional",
      },
    ],
  },
  minimum_rent_duration: {
    label: "Minimum Rent Duration",
    children: [
      {
        title: "1 day",
        value: "1 day",
      },
      {
        title: "2 days",
        value: "2 days",
      },
      {
        title: "3 days",
        value: "3 days",
      },
      {
        title: "4 days",
        value: "4 days",
      },
      {
        title: "5 days",
        value: "5 days",
      },
      {
        title: "6 days",
        value: "6 days",
      },
      {
        title: "1 week",
        value: "1 week",
      },
      {
        title: "2 weeks",
        value: "2 weeks",
      },
      {
        title: "3 weeks",
        value: "3 weeks",
      },
      {
        title: "1 month",
        value: "1 month",
      },
      {
        title: "3 months",
        value: "3 months",
      },
      {
        title: "6 months",
        value: "6 months",
      },
      {
        title: "1 year",
        value: "1 year",
      },
    ],
  },
  maximum_rent_duration: {
    label: "Maximum Rent Duration",
    children: [
      {
        title: "1 week",
        value: "1 week",
      },
      {
        title: "2 weeks",
        value: "2 weeks",
      },
      {
        title: "3 weeks",
        value: "3 weeks",
      },
      {
        title: "1 month",
        value: "1 month",
      },
      {
        title: "3 months",
        value: "3 months",
      },
      {
        title: "6 months",
        value: "6 months",
      },
      {
        title: "9 months",
        value: "9 months",
      },
      {
        title: "1 year",
        value: "1 year",
      },
      {
        title: "2 years",
        value: "2 years",
      },
      {
        title: "5 years",
        value: "5 years",
      },
    ],
  },
  off_road_driving: {
    label: "Off Road Driving",
    children: [
      {
        title: "Allowed",
        value: "Allowed",
      },
      {
        title: "Not Allowed",
        value: "Not Allowed",
      },
    ],
  },
  condominium_bedroom: {
    label: "Bedroom",
    children: [
      {
        title: "studio",
        value: "studio",
      },
      {
        title: "1 bedroom",
        value: "1 bedroom",
      },
      {
        title: "2 bedrooms",
        value: "2 bedrooms",
      },
      {
        title: "3 bedrooms",
        value: "3 bedrooms",
      },
    ],
  },
  bedroom: {
    label: "Number of bedroom",
    children: [
      {
        title: "0",
        value: "0",
      },
      {
        title: "1",
        value: "1",
      },
      {
        title: "2",
        value: "2",
      },
      {
        title: "3",
        value: "3",
      },
      {
        title: "4",
        value: "4",
      },
      {
        title: "5",
        value: "5",
      },
      {
        title: "6",
        value: "6",
      },
      {
        title: "7",
        value: "7",
      },
      {
        title: "8",
        value: "8",
      },
      {
        title: "9",
        value: "9",
      },
      {
        title: "10+",
        value: "10+",
      },
    ],
  },
  bathroom: {
    label: "Number of bathroom",
    children: [
      {
        title: "0",
        value: "0",
      },
      {
        title: "1",
        value: "1",
      },
      {
        title: "2",
        value: "2",
      },
      {
        title: "3",
        value: "3",
      },
      {
        title: "4",
        value: "4",
      },
      {
        title: "5",
        value: "5",
      },
      {
        title: "6",
        value: "6",
      },
      {
        title: "7",
        value: "7",
      },
      {
        title: "8",
        value: "8",
      },
      {
        title: "9",
        value: "9",
      },
      {
        title: "10+",
        value: "10+",
      },
    ],
  },
  floor: {
    label: "Floor number",
    children: [
      {
        title: "Ground",
        value: "Ground",
      },
      {
        title: "1st",
        value: "1st",
      },
      {
        title: "2nd",
        value: "2nd",
      },
      {
        title: "3rd",
        value: "3rd",
      },
      {
        title: "4th",
        value: "4th",
      },
      {
        title: "5th",
        value: "5th",
      },
      {
        title: "6th",
        value: "6th",
      },
      {
        title: "7th",
        value: "7th",
      },
      {
        title: "8th",
        value: "8th",
      },
      {
        title: "9th",
        value: "9th",
      },
      {
        title: "10th",
        value: "10th",
      },
      {
        title: "11th",
        value: "11th",
      },
      {
        title: "12th",
        value: "12th",
      },
      {
        title: "13th",
        value: "13th",
      },
      {
        title: "14th",
        value: "14th",
      },
      {
        title: "15th",
        value: "15th",
      },
      {
        title: "16th",
        value: "16th",
      },
      {
        title: "17th",
        value: "17th",
      },
      {
        title: "18th",
        value: "18th",
      },
      {
        title: "19th",
        value: "19th",
      },
      {
        title: "20th",
        value: "20th",
      },
      {
        title: "20+",
        value: "20+",
      },
    ],
  },
  rent_is_paid: {
    label: "Rent is paid",
    children: [
      {
        title: "Monthly",
        value: "Monthly",
      },
      {
        title: "Quarterly",
        value: "Quarterly",
      },
      {
        title: "Half Yearly",
        value: "Half Yearly",
      },
      {
        title: "Annually",
        value: "Annually",
      },
    ],
  },
  renter_type: {
    label: "Renter type",
    children: [
      {
        title: "Owner",
        value: "Owner",
      },
      {
        title: "Broker",
        value: "Broker",
      },
    ],
  },
  villa_type: {
    label: "Villa type",
    children: [
      {
        title: "Ground",
        value: "Ground",
      },
      {
        title: "G+1",
        value: "G+1",
      },
      {
        title: "G+2",
        value: "G+2",
      },
      {
        title: "G+3",
        value: "G+3",
      },
      {
        title: "G+4",
        value: "G+4",
      },
      {
        title: "G+5",
        value: "G+5",
      },
    ],
  },
  real_estate_type: {
    label: "Real estate type",
    children: [
      {
        title: "Apartment",
        value: "Apartment",
      },
      {
        title: "Villa",
        value: "Villa",
      },
    ],
  },
  commercial_type: {
    label: "Commercial type",
    children: [
      {
        title: "Office",
        value: "Office",
      },
      {
        title: "Shop",
        value: "Shop",
      },
      {
        title: "Warehouse",
        value: "Warehouse",
      },
      {
        title: "Factory",
        value: "Factory",
      },
      {
        title: "Commercial Villa",
        value: "Commercial Villa",
      },
    ],
  },
  number_of_floor: {
    label: "Number of floor",
    children: [
      {
        title: "G+1",
        value: "G+1",
      },
      {
        title: "G+2",
        value: "G+2",
      },
      {
        title: "G+3",
        value: "G+3",
      },
      {
        title: "G+4",
        value: "G+4",
      },
      {
        title: "G+5",
        value: "G+5",
      },
      {
        title: "G+6",
        value: "G+6",
      },
      {
        title: "G+7",
        value: "G+7",
      },
      {
        title: "G+8",
        value: "G+8",
      },
      {
        title: "G+9",
        value: "G+9",
      },
      {
        title: "G+10",
        value: "G+10",
      },
      {
        title: "G+11",
        value: "G+11",
      },
      {
        title: "G+12",
        value: "G+12",
      },
      {
        title: "G+13",
        value: "G+13",
      },
      {
        title: "G+14",
        value: "G+14",
      },
      {
        title: "G+15",
        value: "G+15",
      },
      {
        title: "G+16",
        value: "G+16",
      },
      {
        title: "G+17",
        value: "G+17",
      },
      {
        title: "G+18",
        value: "G+18",
      },
      {
        title: "G+19",
        value: "G+19",
      },
      {
        title: "G+20",
        value: "G+20",
      },
      {
        title: "G+20+",
        value: "G+20+",
      },
    ],
  },
  room: {
    label: "Number of room",
    children: [
      {
        title: "1",
        value: "1",
      },
      {
        title: "2",
        value: "2",
      },
      {
        title: "3",
        value: "3",
      },
      {
        title: "4",
        value: "4",
      },
      {
        title: "5",
        value: "5",
      },
      {
        title: "6",
        value: "6",
      },
      {
        title: "7",
        value: "7",
      },
      {
        title: "8",
        value: "8",
      },
      {
        title: "9",
        value: "9",
      },
      {
        title: "10+",
        value: "10+",
      },
    ],
  },
  tenants_allowed: {
    label: "Tenants allowed",
    children: [
      {
        title: "Males",
        value: "Males",
      },
      {
        title: "Females",
        value: "Females",
      },
      {
        title: "Couples",
        value: "Couples",
      },
      {
        title: "Families",
        value: "Families",
      },
      {
        title: "Don't mind",
        value: "Don't mind",
      },
    ],
  },
  flatmate_type: {
    label: "Flatmate type",
    children: [
      {
        title: "Apartment",
        value: "Apartment",
      },
      {
        title: "Condominium",
        value: "Condominium",
      },
      {
        title: "Villa",
        value: "Villa",
      },
    ],
  },
  room_type: {
    label: "Room type",
    children: [
      {
        title: "Private room",
        value: "Private room",
      },
      {
        title: "Room with partition",
        value: "Room with partition",
      },
      {
        title: "Bed space",
        value: "Bed space",
      },
    ],
  },
  usage: {
    label: "Usage",
    children: [
      {
        title: "Brand New",
        value: "Brand New",
      },
      {
        title: "0-1 month",
        value: "0-1 month",
      },
      {
        title: "1-6 months",
        value: "1-6 months",
      },
      {
        title: "6-12 months",
        value: "6-12 months",
      },
      {
        title: "1-2 years",
        value: "1-2 years",
      },
      {
        title: "2-5 years",
        value: "2-5 years",
      },
      {
        title: "5-10 years",
        value: "5-10 years",
      },
      {
        title: "10+ years",
        value: "10+ years",
      },
    ],
  },
  computer_type: {
    label: "Computer Type",
    children: [
      {
        title: "Laptop Computer",
        value: "Laptop Computer",
      },
      {
        title: "Desktop Computer",
        value: "Desktop Computer",
      },
      {
        title: "Server",
        value: "Server",
      },
      {
        title: "Notebook",
        value: "Notebook",
      },
      {
        title: "Tablet",
        value: "Tablet",
      },
    ],
  },
  hard_drive: {
    label: "Storage",
    children: [
      {
        title: "16 GB",
        value: "16 GB",
      },
      {
        title: "32 GB",
        value: "32 GB",
      },
      {
        title: "64 GB",
        value: "64 GB",
      },
      {
        title: "128 GB",
        value: "128 GB",
      },
      {
        title: "256 GB",
        value: "256 GB",
      },
      {
        title: "380 GB",
        value: "380 GB",
      },
      {
        title: "500 GB",
        value: "500 GB",
      },
      {
        title: "512 GB",
        value: "512 GB",
      },
      {
        title: "750 GB",
        value: "750 GB",
      },
      {
        title: "1TB",
        value: "1TB",
      },
      {
        title: "2TB",
        value: "2TB",
      },
      {
        title: "3TB",
        value: "3TB",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  ram: {
    label: "RAM",
    children: [
      {
        title: "512 MB",
        value: "512 MB",
      },
      {
        title: "1 GB",
        value: "1 GB",
      },
      {
        title: "2 GB",
        value: "2 GB",
      },
      {
        title: "4 GB",
        value: "4 GB",
      },
      {
        title: "6 GB",
        value: "6 GB",
      },
      {
        title: "8 GB",
        value: "8 GB",
      },
      {
        title: "12 GB",
        value: "12 GB",
      },
      {
        title: "16 GB",
        value: "16 GB",
      },
      {
        title: "24 GB",
        value: "24 GB",
      },
      {
        title: "32 GB",
        value: "32 GB",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  processor_type: {
    label: "Processor type",
    children: [
      {
        title: "Intel Pentium",
        value: "Intel Pentium",
      },
      {
        title: "Intel Core i7",
        value: "Intel Core i7",
      },
      {
        title: "Intel Core i5",
        value: "Intel Core i5",
      },
      {
        title: "Intel Core i3",
        value: "Intel Core i3",
      },
      {
        title: "Intel",
        value: "Intel",
      },
      {
        title: "Nvidia",
        value: "Nvidia",
      },
      {
        title: "Intel Xeon",
        value: "Intel Xeon",
      },
      {
        title: "Intel Core i9",
        value: "Intel Core i9",
      },
      {
        title: "Intel Core M",
        value: "Intel Core M",
      },
      {
        title: "Intel Core 2 Quad",
        value: "Intel Core 2 Quad",
      },
      {
        title: "Intel Core 2 Duo",
        value: "Intel Core 2 Duo",
      },
      {
        title: "Intel Celeron",
        value: "Intel Celeron",
      },
      {
        title: "Intel Atom",
        value: "Intel Atom",
      },
      {
        title: "Apple M1",
        value: "Apple M1",
      },
      {
        title: "AMD Ryzen",
        value: "AMD Ryzen",
      },
      {
        title: "AMD A10",
        value: "AMD A10",
      },
      {
        title: "AMD A8",
        value: "AMD A8",
      },
      {
        title: "AMD A6",
        value: "AMD A6",
      },
      {
        title: "AMD A4",
        value: "AMD A4",
      },
      {
        title: "AMD",
        value: "AMD",
      },
    ],
  },
  display_size: {
    label: "Display size",
    children: [
      {
        title: '10.1"',
        value: '10.1"',
      },
      {
        title: '11"',
        value: '11"',
      },
      {
        title: '11.6"',
        value: '11.6"',
      },
      {
        title: '12"',
        value: '12"',
      },
      {
        title: '12.3"',
        value: '12.3"',
      },
      {
        title: '13"',
        value: '13"',
      },
      {
        title: '13.3"',
        value: '13.3"',
      },
      {
        title: '14"',
        value: '14"',
      },
      {
        title: '15"',
        value: '15"',
      },
      {
        title: '15.6"',
        value: '15.6"',
      },
      {
        title: '16"',
        value: '16"',
      },
      {
        title: '17"',
        value: '17"',
      },
      {
        title: '17.3"',
        value: '17.3"',
      },
      {
        title: '19"',
        value: '19"',
      },
      {
        title: '20.1"',
        value: '20.1"',
      },
      {
        title: '21"',
        value: '21"',
      },
      {
        title: 'More than 21"',
        value: 'More than 21"',
      },
    ],
  },
  storage_type: {
    label: "Storage type",
    children: [
      {
        title: "HDD",
        value: "HDD",
      },
      {
        title: "SSD",
        value: "SSD",
      },
      {
        title: "SSHD (Hybrid)",
        value: "SSHD (Hybrid)",
      },
    ],
  },
  processor_speed: {
    label: "Processor Speed",
    children: [
      {
        title: "Less than 500 MHz",
        value: "Less than 500 MHz",
      },
      {
        title: "500 MHz - 999 MHz",
        value: "500 MHz - 999 MHz",
      },
      {
        title: "1 GHz - 1.49 GHz",
        value: "1 GHz - 1.49 GHz",
      },
      {
        title: "1.5 GHz - 1.99 GHz",
        value: "1.5 GHz - 1.99 GHz",
      },
      {
        title: "2 GHz - 2.49 GHz",
        value: "2 GHz - 2.49 GHz",
      },
      {
        title: "2.5 GHz - 2.99 GHz",
        value: "2.5 GHz - 2.99 GHz",
      },
      {
        title: "3 GHz - 4 GHz",
        value: "3 GHz - 4 GHz",
      },
      {
        title: "4+ GHz",
        value: "4+ GHz",
      },
    ],
  },
  memory: {
    label: "Internal Storage",
    children: [
      {
        title: "2 GB",
        value: "2 GB",
      },
      {
        title: "4 GB",
        value: "4 GB",
      },
      {
        title: "6 GB",
        value: "6 GB",
      },
      {
        title: "8 GB",
        value: "8 GB",
      },
      {
        title: "16 GB",
        value: "16 GB",
      },
      {
        title: "32 GB",
        value: "32 GB",
      },
      {
        title: "64 GB",
        value: "64 GB",
      },
      {
        title: "128 GB",
        value: "128 GB",
      },
      {
        title: "256 GB",
        value: "256 GB",
      },
      {
        title: "512 GB",
        value: "512 GB",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  mobile_ram: {
    label: "RAM",
    children: [
      {
        title: "256 MB",
        value: "256 MB",
      },
      {
        title: "500 MB",
        value: "500 MB",
      },
      {
        title: "1 GB",
        value: "1 GB",
      },
      {
        title: "1.5 GB",
        value: "1.5 GB",
      },
      {
        title: "2 GB",
        value: "2 GB",
      },
      {
        title: "3 GB",
        value: "3 GB",
      },
      {
        title: "4 GB",
        value: "4 GB",
      },
      {
        title: "6 GB",
        value: "6 GB",
      },
      {
        title: "8 GB",
        value: "8 GB",
      },
      {
        title: "12 GB",
        value: "12 GB",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  television_brand: {
    label: "Brand",
    children: [
      {
        title: "BENQ",
        value: "BENQ",
      },
      {
        title: "Braun",
        value: "Braun",
      },
      {
        title: "Casio",
        value: "Casio",
      },
      {
        title: "Daewoo",
        value: "Daewoo",
      },
      {
        title: "Dell",
        value: "Dell",
      },
      {
        title: "Elekta",
        value: "Elekta",
      },
      {
        title: "Haier",
        value: "Haier",
      },
      {
        title: "Hitachi",
        value: "Hitachi",
      },
      {
        title: "JVC",
        value: "JVC",
      },
      {
        title: "LG",
        value: "LG",
      },
      {
        title: "Magnavox",
        value: "Magnavox",
      },
      {
        title: "Memorex",
        value: "Memorex",
      },
      {
        title: "Mitsubishi",
        value: "Mitsubishi",
      },
      {
        title: "Napro",
        value: "Napro",
      },
      {
        title: "NEC",
        value: "NEC",
      },
      {
        title: "Nikai",
        value: "Nikai",
      },
      {
        title: "Panasonic",
        value: "Panasonic",
      },
      {
        title: "Phillips",
        value: "Phillips",
      },
      {
        title: "Pioneer",
        value: "Pioneer",
      },
      {
        title: "Polaroid",
        value: "Polaroid",
      },
      {
        title: "RCA",
        value: "RCA",
      },
      {
        title: "Samsung",
        value: "Samsung",
      },
      {
        title: "Sanyo",
        value: "Sanyo",
      },
      {
        title: "Sharp",
        value: "Sharp",
      },
      {
        title: "Sony",
        value: "Sony",
      },
      {
        title: "Toshiba",
        value: "Toshiba",
      },
      {
        title: "ViewSonic",
        value: "ViewSonic",
      },
      {
        title: "Vizio",
        value: "Vizio",
      },
      {
        title: "Other Brand",
        value: "Other Brand",
      },
    ],
  },
  screen_size: {
    label: "Screen size",
    children: [
      {
        title: '14"',
        value: '14"',
      },
      {
        title: '21"',
        value: '21"',
      },
      {
        title: '24"',
        value: '24"',
      },
      {
        title: '28"',
        value: '28"',
      },
      {
        title: '29"',
        value: '29"',
      },
      {
        title: '32"',
        value: '32"',
      },
      {
        title: '40"',
        value: '40"',
      },
      {
        title: '42"',
        value: '42"',
      },
      {
        title: '43"',
        value: '43"',
      },
      {
        title: '48"',
        value: '48"',
      },
      {
        title: '50"',
        value: '50"',
      },
      {
        title: '55"',
        value: '55"',
      },
      {
        title: '65"',
        value: '65"',
      },
      {
        title: '75"',
        value: '75"',
      },
      {
        title: '80"',
        value: '80"',
      },
      {
        title: '85"',
        value: '85"',
      },
      {
        title: '90"+',
        value: '90"+',
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  refrigerator_and_washing_machine_brand: {
    label: "Brand",
    children: [
      {
        title: "Admiral",
        value: "Admiral",
      },
      {
        title: "Aftron",
        value: "Aftron",
      },
      {
        title: "Bompani",
        value: "Bompani",
      },
      {
        title: "Bosch",
        value: "Bosch",
      },
      {
        title: "Braun",
        value: "Braun",
      },
      {
        title: "Char-Broil",
        value: "Char-Broil",
      },
      {
        title: "Coleman",
        value: "Coleman",
      },
      {
        title: "Daewoo",
        value: "Daewoo",
      },
      {
        title: "Dyson",
        value: "Dyson",
      },
      {
        title: "Electrolux",
        value: "Electrolux",
      },
      {
        title: "Elekta",
        value: "Elekta",
      },
      {
        title: "Fisher & Paykel",
        value: "Fisher %26 Paykel",
      },
      {
        title: "Frigidaire",
        value: "Frigidaire",
      },
      {
        title: "GE",
        value: "GE",
      },
      {
        title: "Haier",
        value: "Haier",
      },
      {
        title: "Hitachi",
        value: "Hitachi",
      },
      {
        title: "Hoover",
        value: "Hoover",
      },
      {
        title: "Hotpoint-Ariston",
        value: "Hotpoint-Ariston",
      },
      {
        title: "Indesit",
        value: "Indesit",
      },
      {
        title: "Kenmore",
        value: "Kenmore",
      },
      {
        title: "Kenwood",
        value: "Kenwood",
      },
      {
        title: "Kitchen-Aid",
        value: "Kitchen-Aid",
      },
      {
        title: "La Germania",
        value: "La Germania",
      },
      {
        title: "Lavazza",
        value: "Lavazza",
      },
      {
        title: "LG",
        value: "LG",
      },
      {
        title: "Maytag",
        value: "Maytag",
      },
      {
        title: "Miele",
        value: "Miele",
      },
      {
        title: "Moulinex",
        value: "Moulinex",
      },
      {
        title: "Nespresso",
        value: "Nespresso",
      },
      {
        title: "Nikai",
        value: "Nikai",
      },
      {
        title: "Optima",
        value: "Optima",
      },
      {
        title: "Panasonic",
        value: "Panasonic",
      },
      {
        title: "Philips",
        value: "Philips",
      },
      {
        title: "Samsung",
        value: "Samsung",
      },
      {
        title: "Sanyo",
        value: "Sanyo",
      },
      {
        title: "Siemens",
        value: "Siemens",
      },
      {
        title: "Toshiba",
        value: "Toshiba",
      },
      {
        title: "Weber",
        value: "Weber",
      },
      {
        title: "Westpoint",
        value: "Westpoint",
      },
      {
        title: "Whirlpool",
        value: "Whirlpool",
      },
      {
        title: "Zanussi",
        value: "Zanussi",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  job_gender: {
    label: "Gender",
    children: [
      {
        title: "Male",
        value: "Male",
      },
      {
        title: "Female",
        value: "Female",
      },
    ],
  },
  employment_type: {
    label: "Employment Type",
    children: [
      {
        title: "Full-time",
        value: "Full-time",
      },
      {
        title: "Part-time",
        value: "Part-time",
      },
      {
        title: "Freelance",
        value: "Freelance",
      },
      {
        title: "Contract",
        value: "Contract",
      },
      {
        title: "Internship",
        value: "Internship",
      },
      {
        title: "Temporary",
        value: "Temporary",
      },
    ],
  },
  career_level: {
    label: "Career Level",
    children: [
      {
        title: "Junior",
        value: "Junior",
      },
      {
        title: "Intermediate",
        value: "Intermediate",
      },
      {
        title: "Senior",
        value: "Senior",
      },
      {
        title: "Expert",
        value: "Expert",
      },
    ],
  },
  experience: {
    label: "Experience",
    children: [
      {
        title: "0 Year",
        value: "0 Year",
      },
      {
        title: "1 Year",
        value: "1 Year",
      },
      {
        title: "2 Years",
        value: "2 Years",
      },
      {
        title: "3 Years",
        value: "3 Years",
      },
      {
        title: "4 Years",
        value: "4 Years",
      },
      {
        title: "5 Years",
        value: "5 Years",
      },
      {
        title: "5+ Years",
        value: "5+ Years",
      },
    ],
  },
  education: {
    label: "Education",
    children: [
      {
        title: "N/A",
        value: "N/A",
      },
      {
        title: "High-School/Secondary",
        value: "High-School/Secondary",
      },
      {
        title: "Bachelors Degree",
        value: "Bachelors Degree",
      },
      {
        title: "Masters Degree",
        value: "Masters Degree",
      },
      {
        title: "PhD",
        value: "PhD",
      },
    ],
  },
  gender: {
    label: "Gender",
    children: [
      {
        title: "Male",
        value: "Male",
      },
      {
        title: "Female",
        value: "Female",
      },
    ],
  },
  fashion_condition: {
    label: "Condition",
    children: [
      {
        title: "New with tag/box",
        value: "New with tag/box",
      },
      {
        title: "New without tag/box",
        value: "New without tag/box",
      },
      {
        title: "New With defects",
        value: "New With defects",
      },
      {
        title: "Used",
        value: "Used",
      },
    ],
  },
  fashion_gender: {
    label: "Gender",
    children: [
      {
        title: "Baby Boy",
        value: "Baby Boy",
      },
      {
        title: "Baby Girl",
        value: "Baby Girl",
      },
      {
        title: "Boy’s",
        value: "Boy’s",
      },
      {
        title: "Girl’s",
        value: "Girl’s",
      },
      {
        title: "Men’s",
        value: "Men’s",
      },
      {
        title: "Women’s",
        value: "Women’s",
      },
      {
        title: "Unisex",
        value: "Unisex",
      },
    ],
  },
  bag_brand: {
    label: "Brand",
    children: [
      {
        title: "Prada",
        value: "Prada",
      },
      {
        title: "Chanel",
        value: "Chanel",
      },
      {
        title: "Louis Vuitton",
        value: "Louis Vuitton",
      },
      {
        title: "Michael Kors",
        value: "Michael Kors",
      },
      {
        title: "Adidas",
        value: "Adidas",
      },
      {
        title: "Aldo",
        value: "Aldo",
      },
      {
        title: "Anne Klein",
        value: "Anne Klein",
      },
      {
        title: "Aptonia",
        value: "Aptonia",
      },
      {
        title: "Bella",
        value: "Bella",
      },
      {
        title: "Burberry",
        value: "Burberry",
      },
      {
        title: "Calvin Klein",
        value: "Calvin Klein",
      },
      {
        title: "Charles & Keith",
        value: "Charles %26 Keith",
      },
      {
        title: "Christian Dior",
        value: "Christian Dior",
      },
      {
        title: "Dakine",
        value: "Dakine",
      },
      {
        title: "Dolce & Gabbana",
        value: "Dolce %26 Gabbana",
      },
      {
        title: "Elham",
        value: "Elham",
      },
      {
        title: "Fendi",
        value: "Fendi",
      },
      {
        title: "Gucci",
        value: "Gucci",
      },
      {
        title: "Guess",
        value: "Guess",
      },
      {
        title: "Hobo",
        value: "Hobo",
      },
      {
        title: "Levis",
        value: "Levis",
      },
      {
        title: "Mango",
        value: "Mango",
      },
      {
        title: "New Look",
        value: "New Look",
      },
      {
        title: "Nike",
        value: "Nike",
      },
      {
        title: "River Island",
        value: "River Island",
      },
      {
        title: "Samsonite",
        value: "Samsonite",
      },
      {
        title: "Susen",
        value: "Susen",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  clothe_brand: {
    label: "Brand",
    children: [
      {
        title: "Adidas",
        value: "Adidas",
      },
      {
        title: "Nike",
        value: "Nike",
      },
      {
        title: "H & M",
        value: "H %26 M",
      },
      {
        title: "Zara",
        value: "Zara",
      },
      {
        title: "4F",
        value: "4F",
      },
      {
        title: "Alexander McQueen",
        value: "Alexander McQueen",
      },
      {
        title: "Armani",
        value: "Armani",
      },
      {
        title: "Burberry",
        value: "Burberry",
      },
      {
        title: "Canali",
        value: "Canali",
      },
      {
        title: "CAT",
        value: "CAT",
      },
      {
        title: "Chanel",
        value: "Chanel",
      },
      {
        title: "Clarks",
        value: "Clarks",
      },
      {
        title: "Converse",
        value: "Converse",
      },
      {
        title: "Diadora",
        value: "Diadora",
      },
      {
        title: "Dior",
        value: "Dior",
      },
      {
        title: "Gucci",
        value: "Gucci",
      },
      {
        title: "Hugo Boss",
        value: "Hugo Boss",
      },
      {
        title: "J.Crew",
        value: "J.Crew",
      },
      {
        title: "Jack & Jones",
        value: "Jack %26 Jones",
      },
      {
        title: "Kanna",
        value: "Kanna",
      },
      {
        title: "Kenneth Cole",
        value: "Kenneth Cole",
      },
      {
        title: "Kent & Curwen",
        value: "Kent %26 Curwen",
      },
      {
        title: "Lacoste",
        value: "Lacoste",
      },
      {
        title: "Levi’s",
        value: "Levi’s",
      },
      {
        title: "Majestic",
        value: "Majestic",
      },
      {
        title: "Marc O’Polo",
        value: "Marc O’Polo",
      },
      {
        title: "Pandora",
        value: "Pandora",
      },
      {
        title: "Peacocks",
        value: "Peacocks",
      },
      {
        title: "Peanuts",
        value: "Peanuts",
      },
      {
        title: "Peavey",
        value: "Peavey",
      },
      {
        title: "Prada",
        value: "Prada",
      },
      {
        title: "Puma",
        value: "Puma",
      },
      {
        title: "Queen",
        value: "Queen",
      },
      {
        title: "Reebok",
        value: "Reebok",
      },
      {
        title: "Under Armour",
        value: "Under Armour",
      },
      {
        title: "Vans",
        value: "Vans",
      },
      {
        title: "Venti",
        value: "Venti",
      },
      {
        title: "Victoria",
        value: "Victoria",
      },
      {
        title: "Wally Walker",
        value: "Wally Walker",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  occasion: {
    label: "Occasion",
    children: [
      {
        title: "Traditional wear",
        value: "Traditional wear",
      },
      {
        title: "Business wear",
        value: "Business wear",
      },
      {
        title: "Casual wear",
        value: "Casual wear",
      },
      {
        title: "Formal wear",
        value: "Formal wear",
      },
      {
        title: "Sports",
        value: "Sports",
      },
    ],
  },
  stone: {
    label: "Stone",
    children: [
      {
        title: "Diamond",
        value: "Diamond",
      },
      {
        title: "Emerald",
        value: "Emerald",
      },
      {
        title: "Sapphire",
        value: "Sapphire",
      },
      {
        title: "Ruby",
        value: "Ruby",
      },
      {
        title: "Aquamarine",
        value: "Aquamarine",
      },
      {
        title: "Topaz",
        value: "Topaz",
      },
      {
        title: "Pearl",
        value: "Pearl",
      },
      {
        title: "Opal",
        value: "Opal",
      },
      {
        title: "Moissanite",
        value: "Moissanite",
      },
      {
        title: "Tourmaline",
        value: "Tourmaline",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  watche_brand: {
    label: "Brand",
    children: [
      {
        title: "Polo",
        value: "Polo",
      },
      {
        title: "Rolex",
        value: "Rolex",
      },
      {
        title: "Patek Philippe",
        value: "Patek Philippe",
      },
      {
        title: "Audemars Piguet",
        value: "Audemars Piguet",
      },
      {
        title: "A.Lange & Söhne",
        value: "A.Lange %26 Söhne",
      },
      {
        title: "Omega",
        value: "Omega",
      },
      {
        title: "Blancpain",
        value: "Blancpain",
      },
      {
        title: "Iwc Schaffhausen",
        value: "Iwc Schaffhausen",
      },
      {
        title: "Jaeger-Lecoultre",
        value: "Jaeger-Lecoultre",
      },
      {
        title: "Chopard",
        value: "Chopard",
      },
      {
        title: "Piaget",
        value: "Piaget",
      },
      {
        title: "Cartier",
        value: "Cartier",
      },
      {
        title: "Vacheron Constantin",
        value: "Vacheron Constantin",
      },
      {
        title: "Montblanc",
        value: "Montblanc",
      },
      {
        title: "Ulysse Nardin",
        value: "Ulysse Nardin",
      },
      {
        title: "Panerai",
        value: "Panerai",
      },
      {
        title: "Girard-Perregaux",
        value: "Girard-Perregaux",
      },
      {
        title: "Hublot",
        value: "Hublot",
      },
      {
        title: "Bulgari",
        value: "Bulgari",
      },
      {
        title: "Nordgreen",
        value: "Nordgreen",
      },
      {
        title: "Nomos Glashütte",
        value: "Nomos Glashütte",
      },
      {
        title: "Vincero",
        value: "Vincero",
      },
      {
        title: "Liv Watches",
        value: "Liv Watches",
      },
      {
        title: "Breitling",
        value: "Breitling",
      },
      {
        title: "Bremont",
        value: "Bremont",
      },
      {
        title: "Tag Heuer",
        value: "Tag Heuer",
      },
      {
        title: "Baume & Mercier",
        value: "Baume %26 Mercier",
      },
      {
        title: "Rado",
        value: "Rado",
      },
      {
        title: "Maurice Lacroix",
        value: "Maurice Lacroix",
      },
      {
        title: "Roger Dubuis",
        value: "Roger Dubuis",
      },
      {
        title: "F.P.Journe",
        value: "F.P.Journe",
      },
      {
        title: "Tiffany & Co.",
        value: "Tiffany %26 Co.",
      },
      {
        title: "Louis Vuitton",
        value: "Louis Vuitton",
      },
      {
        title: "Bamford",
        value: "Bamford",
      },
      {
        title: "Zenith",
        value: "Zenith",
      },
      {
        title: "Bell & Ross",
        value: "Bell %26 Ross",
      },
      {
        title: "Arnold & Son",
        value: "Arnold %26 Son",
      },
      {
        title: "Tudor",
        value: "Tudor",
      },
      {
        title: "Alpina",
        value: "Alpina",
      },
      {
        title: "Seiko",
        value: "Seiko",
      },
      {
        title: "Jaquet Droz",
        value: "Jaquet Droz",
      },
      {
        title: "Laurent Ferrier",
        value: "Laurent Ferrier",
      },
      {
        title: "Hamilton",
        value: "Hamilton",
      },
      {
        title: "Longines",
        value: "Longines",
      },
      {
        title: "Maurice De Mauriac",
        value: "Maurice De Mauriac",
      },
      {
        title: "Parmigiani Fleurier",
        value: "Parmigiani Fleurier",
      },
      {
        title: "Gucci",
        value: "Gucci",
      },
      {
        title: "Weiss",
        value: "Weiss",
      },
      {
        title: "Armani",
        value: "Armani",
      },
      {
        title: "Tissot",
        value: "Tissot",
      },
      {
        title: "Van Cleef & Arpels",
        value: "Van Cleef %26 Arpels",
      },
      {
        title: "Junghans",
        value: "Junghans",
      },
      {
        title: "Bulova",
        value: "Bulova",
      },
      {
        title: "Bovet Fleurier",
        value: "Bovet Fleurier",
      },
      {
        title: "Oris",
        value: "Oris",
      },
      {
        title: "Ressence",
        value: "Ressence",
      },
      {
        title: "Fossil",
        value: "Fossil",
      },
      {
        title: "Casio",
        value: "Casio",
      },
      {
        title: "Frederique Constant",
        value: "Frederique Constant",
      },
      {
        title: "Meistersinger",
        value: "Meistersinger",
      },
      {
        title: "Breguet",
        value: "Breguet",
      },
      {
        title: "Verdure",
        value: "Verdure",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  dial_display: {
    label: "Dial display",
    children: [
      {
        title: "Analog",
        value: "Analog",
      },
      {
        title: "Digital",
        value: "Digital",
      },
    ],
  },
  movement_type: {
    label: "Movement type",
    children: [
      {
        title: "Mechanical",
        value: "Mechanical",
      },
      {
        title: "Automatical",
        value: "Automatical",
      },
      {
        title: "Quartz",
        value: "Quartz",
      },
    ],
  },
  shoes_brand: {
    label: "Brand",
    children: [
      {
        title: "Adidas",
        value: "Adidas",
      },
      {
        title: "Nike",
        value: "Nike",
      },
      {
        title: "Puma",
        value: "Puma",
      },
      {
        title: "Converse",
        value: "Converse",
      },
      {
        title: "Under Armour",
        value: "Under Armour",
      },
      {
        title: "Skechers",
        value: "Skechers",
      },
      {
        title: "Anbessa Chama",
        value: "Anbessa Chama",
      },
      {
        title: "Peacock Chama",
        value: "Peacock Chama",
      },
      {
        title: "Kangaroo Chama",
        value: "Kangaroo Chama",
      },
      {
        title: "Tikur Abay Chama",
        value: "Tikur Abay Chama",
      },
      {
        title: "Tana Chama",
        value: "Tana Chama",
      },
      {
        title: "Fila",
        value: "Fila",
      },
      {
        title: "Jordan",
        value: "Jordan",
      },
      {
        title: "Testoni",
        value: "Testoni",
      },
      {
        title: "Rebook",
        value: "Rebook",
      },
      {
        title: "Johnson and Murphy",
        value: "Johnson and Murphy",
      },
      {
        title: "Timberland",
        value: "Timberland",
      },
      {
        title: "Clarks",
        value: "Clarks",
      },
      {
        title: "Vans",
        value: "Vans",
      },
      {
        title: "Crocs",
        value: "Crocs",
      },
      {
        title: "Calvin Klein",
        value: "Calvin Klein",
      },
      {
        title: "By Hudson",
        value: "By Hudson",
      },
      {
        title: "TOMMY HILFIGER",
        value: "TOMMY HILFIGER",
      },
      {
        title: "Columbia",
        value: "Columbia",
      },
      {
        title: "New Balance",
        value: "New Balance",
      },
      {
        title: "Bogs",
        value: "Bogs",
      },
      {
        title: "kENNETH COLE",
        value: "kENNETH COLE",
      },
      {
        title: "Burberry",
        value: "Burberry",
      },
      {
        title: "Lacoste",
        value: "Lacoste",
      },
      {
        title: "CAT",
        value: "CAT",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  fashion_color: {
    label: "Fashion Color",
    children: [
      {
        title: "Blue",
        value: "Blue",
      },
      {
        title: "Black",
        value: "Black",
      },
      {
        title: "Brown",
        value: "Brown",
      },
      {
        title: "Gray",
        value: "Gray",
      },
      {
        title: "Purple",
        value: "Purple",
      },
      {
        title: "Red",
        value: "Red",
      },
      {
        title: "White",
        value: "White",
      },
      {
        title: "Pink",
        value: "Pink",
      },
      {
        title: "Orange",
        value: "Orange",
      },
      {
        title: "Rose",
        value: "Rose",
      },
      {
        title: "Purple",
        value: "Purple",
      },
      {
        title: "Blueblack",
        value: "Blueblack",
      },
      {
        title: "Silver",
        value: "Silver",
      },
      {
        title: "Yellow",
        value: "Yellow",
      },
      {
        title: "Violet",
        value: "Violet",
      },
      {
        title: "Ash",
        value: "Ash",
      },
      {
        title: "Azure",
        value: "Azure",
      },
      {
        title: "Baby Blue",
        value: "Baby Blue",
      },
      {
        title: "Beige",
        value: "Beige",
      },
      {
        title: "Bone",
        value: "Bone",
      },
      {
        title: "Bronze",
        value: "Bronze",
      },
      {
        title: "Burgundy",
        value: "Burgundy",
      },
      {
        title: "Carrot",
        value: "Carrot",
      },
      {
        title: "Cherry",
        value: "Cherry",
      },
      {
        title: "Chestnut",
        value: "Chestnut",
      },
      {
        title: "Cloud",
        value: "Cloud",
      },
      {
        title: "Coffee",
        value: "Coffee",
      },
      {
        title: "Copper",
        value: "Copper",
      },
      {
        title: "Coral",
        value: "Coral",
      },
      {
        title: "Crimson",
        value: "Crimson",
      },
      {
        title: "Dove",
        value: "Dove",
      },
      {
        title: "Ecru",
        value: "Ecru",
      },
      {
        title: "Emerald",
        value: "Emerald",
      },
      {
        title: "Fog",
        value: "Fog",
      },
      {
        title: "Fuchsia",
        value: "Fuchsia",
      },
      {
        title: "Gold",
        value: "Gold",
      },
      {
        title: "Graphite",
        value: "Graphite",
      },
      {
        title: "Green",
        value: "Green",
      },
      {
        title: "Honey",
        value: "Honey",
      },
      {
        title: "Hot Pink",
        value: "Hot Pink",
      },
      {
        title: "Iron",
        value: "Iron",
      },
      {
        title: "Ivory",
        value: "Ivory",
      },
      {
        title: "Khaki",
        value: "Khaki",
      },
      {
        title: "Lavender",
        value: "Lavender",
      },
      {
        title: "Lemon",
        value: "Lemon",
      },
      {
        title: "Light Blue",
        value: "Light Blue",
      },
      {
        title: "Light Coral",
        value: "Light Coral",
      },
      {
        title: "Light Green",
        value: "Light Green",
      },
      {
        title: "Lilac",
        value: "Lilac",
      },
      {
        title: "Lime",
        value: "Lime",
      },
      {
        title: "Magenta",
        value: "Magenta",
      },
      {
        title: "Mauve",
        value: "Mauve",
      },
      {
        title: "Merigold",
        value: "Merigold",
      },
      {
        title: "Mint",
        value: "Mint",
      },
      {
        title: "Multicolor",
        value: "Multicolor",
      },
      {
        title: "Mustard",
        value: "Mustard",
      },
      {
        title: "Navy Blue",
        value: "Navy Blue",
      },
      {
        title: "Ochre",
        value: "Ochre",
      },
      {
        title: "Orange-Red",
        value: "Orange-Red",
      },
      {
        title: "Orchid",
        value: "Orchid",
      },
      {
        title: "Pearl",
        value: "Pearl",
      },
      {
        title: "Pistachio",
        value: "Pistachio",
      },
      {
        title: "Plum",
        value: "Plum",
      },
      {
        title: "Porcelain",
        value: "Porcelain",
      },
      {
        title: "Rose Gold",
        value: "Rose Gold",
      },
      {
        title: "Ruby",
        value: "Ruby",
      },
      {
        title: "Sage",
        value: "Sage",
      },
      {
        title: "Salmon",
        value: "Salmon",
      },
      {
        title: "Seafoam",
        value: "Seafoam",
      },
      {
        title: "Tan",
        value: "Tan",
      },
      {
        title: "Taupe",
        value: "Taupe",
      },
      {
        title: "Turquoise",
        value: "Turquoise",
      },
      {
        title: "Wine",
        value: "Wine",
      },
      {
        title: "Other",
        value: "Other",
      },
    ],
  },
  clothe_size: {
    label: "Clothe Size",
    children: [
      {
        title: "3XS",
        value: "3XS",
      },
      {
        title: "XXS",
        value: "XXS",
      },
      {
        title: "XS",
        value: "XS",
      },
      {
        title: "S",
        value: "S",
      },
      {
        title: "M",
        value: "M",
      },
      {
        title: "L",
        value: "L",
      },
      {
        title: "XL",
        value: "XL",
      },
      {
        title: "XXL",
        value: "XXL",
      },
      {
        title: "3XL",
        value: "3XL",
      },
      {
        title: "4XL",
        value: "4XL",
      },
      {
        title: "5XL",
        value: "5XL",
      },
      {
        title: "6XL",
        value: "6XL",
      },
      {
        title: "7XL",
        value: "7XL",
      },
      {
        title: "Free Size",
        value: "Free Size",
      },
    ],
  },
  shoes_size: {
    label: "Shoes Size",
    children: [
      {
        title: "35",
        value: "35",
      },
      {
        title: "36",
        value: "36",
      },
      {
        title: "37",
        value: "37",
      },
      {
        title: "38",
        value: "38",
      },
      {
        title: "39",
        value: "39",
      },
      {
        title: "40",
        value: "40",
      },
      {
        title: "41",
        value: "41",
      },
      {
        title: "42",
        value: "42",
      },
      {
        title: "43",
        value: "43",
      },
      {
        title: "44",
        value: "44",
      },
      {
        title: "45",
        value: "45",
      },
      {
        title: "46",
        value: "46",
      },
      {
        title: "47",
        value: "47",
      },
      {
        title: "Free Size",
        value: "Free Size",
      },
    ],
  },
  exchange_available: {
    label: "Is Exchange",
    children: [
      {
        title: "true",
        value: "true",
      },
      {
        title: "false",
        value: "false",
      },
    ],
  },
};
export default options;

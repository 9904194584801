import AdCardForJobs from "../Feedback/AdCardForJobs";
import AdCard from "../Feedback/AdCardMid";
import AdCardForProperty from "../Feedback/AdCardMidProp";
const LongCardConfig = {
  "car-for-sell": AdCard,
  "heavy-vehicles-for-sell": AdCard,
  "motorcycle-for-sell": AdCard,
  "3-wheeler-motors-for-sell": AdCard,
  "car-for-rent": AdCard,
  "heavy-vehicles-for-rent": AdCard,
  "wedding-car-for-rent": AdCard,
  "tourist-car-for-rent": AdCard,
  "apartment-for-sell": AdCardForProperty,
  "commercial-for-sell": AdCardForProperty,
  "commercial-building-for-sell": AdCardForProperty,
  "real-estate-for-sell": AdCardForProperty,
  "condominium-for-sell": AdCardForProperty,
  "villa-for-sell": AdCardForProperty,
  "apartment-for-rent": AdCardForProperty,
  "commercial-for-rent": AdCardForProperty,
  "commercial-building-for-rent": AdCardForProperty,
  "flatmates-room-for-rent": AdCardForProperty,
  "condominium-for-rent": AdCardForProperty,
  "service-room-for-rent": AdCardForProperty,
  "villa-for-rent": AdCardForProperty,
  electronics: AdCard,
  television: AdCard,
  refrigerator: AdCard,
  "washing-machine": AdCard,
  "mobile-phone": AdCard,
  tablet: AdCard,
  computer: AdCard,
  fashion: AdCard,
  bags: AdCard,
  clothing: AdCard,
  jewelry: AdCard,
  shoes: AdCard,
  watches: AdCard,
  classifieds: AdCard,
  job: AdCardForJobs,
  employee: AdCardForJobs,
  services: AdCardForJobs,
};
export default LongCardConfig;

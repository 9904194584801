import { Grid, Popper, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import InputBase from "@mui/material/InputBase";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
const CustomPopper = function (props) {
  return <Popper {...props} placement="bottom" />;
};
export default function CustomAutoComplete({
  text,
  onTextChange,
  onSearch,
  isLoading,
  options,
  placeholder,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  return (
    <Autocomplete
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.defaultMuiPrevented = true;
          onSearch(event);
        }
      }}
      fullWidth
      className={classes.root}
      open={open}
      ListboxProps={{
        style: { maxHeight: "18rem" },
        position: "bottom-end",
      }}
      freeSolo
      onChange={onSearch}
      onInputChange={onTextChange}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      inputValue={text}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => t(option.value) ?? t(option)}
      options={
        options === undefined || options.results === undefined
          ? []
          : options.results
      }
      searchkey={options === undefined ? "" : options.key}
      loading={isLoading}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest } = params;
        return (
          <InputBase
            placeholder={t(placeholder)}
            {...params.InputProps}
            {...rest}
            inputComponent="input"
            endAdornment={
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </React.Fragment>
            }
          />
        );
      }}
      PopperComponent={CustomPopper}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid container alignItems="center">
            <Grid item xs>
              {option.flag ? (
                <Fragment>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    display="inline"
                  >
                    {options.query}
                  </Typography>{" "}
                  {t("in")}{" "}
                  <span
                    style={{
                      fontWeight: 1 ? 700 : 400,
                    }}
                  >
                    {t(option.category)}
                  </span>
                </Fragment>
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  display="inline"
                >
                  {option.value}
                </Typography>
              )}
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
}

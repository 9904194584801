import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import ListItemMore from "./ListItemMore";
function Content({
  notification,
  onMarkAsRead,
  onRemoveNotification,
  showDesc,
}) {
  const getUrl = (notif) => {
    let url = "#";
    if (notif.target && notif.target.target_type === "Product")
      url = `/detail/${notif.target.target_object_id}`;
    if (notif.target && notif.target.target_type === "User")
      url = `/profile/setting`;
    if (notif.target && notif.target.target_type === "Payment")
      url = `/profile/points`;
    if (notif.target && notif.target.target_type === "Subscriptions")
      url = `/profile/pricing`;
    if (notif.target && notif.target.target_type === "Recommendation")
      url = `/profile/pricing`;
    if (notif.target && notif.target.target_type === "Feedback")
      url = `/profile/feedback`;
    if (notif.target && notif.target.target_type === "Announcment")
      url = `/announcments`;
    return url;
  };
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        p: 0,
        cursor: "pointer",
        "&:hover": {
          "& .notification-item": {
            color: "primary.main",
          },
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          textDecoration: "none",
          color: "inherit",
          pointersEvent: "auto",
          zIndex: 1,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%", zIndex: 1 }}
        >
          <Box
            component={RouterLink}
            to={notification.link ? notification.link : getUrl(notification)}
            onClick={(event) => {
              onMarkAsRead(notification.notification_id, true);
            }}
            sx={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {t(notification.verb)}
              <Typography
                component="span"
                variant="subtitle2"
                color="text.primary"
              >
                {" "}
                {notification.target && t(notification.target.target_name)}
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              pointersEvent: "auto",
              zIndex: 1000,
              ml: 1,
            }}
          >
            <ListItemMore
              id={notification.notification_id}
              onMarkAsRead={onMarkAsRead}
              onRemoveNotification={onRemoveNotification}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          {showDesc && (
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                fontSize: "12px",
              }}
            >
              {t(notification.description)}
            </Typography>
          )}
        </Stack>
        <Typography
          fontSize="12px"
          component="span"
          color="textSecondary"
          sx={{
            color: "text.disabled",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "end",
          }}
        >
          {notification &&
            formatDistanceToNow(new Date(String(notification.timestamp)))}
        </Typography>
      </Box>
    </Box>
  );
}
NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};
export default function NotificationItem({
  notification,
  isLoading,
  onMarkAsRead,
  onRemoveNotification,
  showDesc,
}) {
  function getLevelColor(not) {
    if (not === "success") return "success.main";
    else if (not === "info") return "transparent";
    else if (not === "warning") return "warning.main";
    else if (not === "error") return "error.main";
  }
  return (
    <ListItem
      sx={{
        py: 1.5,
        px: 1.5,
        mb: "5px",
        ...(notification &&
          notification.unread && {
            bgcolor: "action.selected",
            borderLeft: "4px solid",
            borderColor: getLevelColor(notification && notification.level),
          }),
      }}
      disablePadding
    >
      <ListItemAvatar>
        {isLoading ? (
          <Skeleton variant="circular">
            <Avatar sx={{ width: 55, height: 55 }} />
          </Skeleton>
        ) : (
          <Avatar
            src={notification.actor.actor_image}
            alt={notification.actor.actor_name}
            sx={{
              bgcolor: "background.neutral",
              width: 55,
              height: 55,
            }}
          />
        )}
      </ListItemAvatar>
      {isLoading ? (
        <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>
          <br />
          <Skeleton width="80%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      ) : (
        <ListItemText
          sx={{ pr: 0 }}
          primary={
            <Content
              onMarkAsRead={onMarkAsRead}
              onRemoveNotification={onRemoveNotification}
              notification={notification}
              showDesc={showDesc}
            />
          }
        />
      )}
    </ListItem>
  );
}

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LongCardConfig from "../products1/LongCardConfig";
import AdCard from "./AdCardMid";
import NoResultFound from "./NoResultFound";
export default function ProductList({
  data,
  isLoading,
  hasNextPage,
  fetchNextPage,
  isSuccess,
}) {
  const [productList, setProductList] = useState([]);
  const getProductCard = (category) => {
    return LongCardConfig[category];
  };
  useEffect(() => {
    if (data) {
      const temp = [];
      data.pages.forEach((element) => {
        element.results.forEach((item) => {
          temp.push(item);
        });
      });
      setProductList(temp);
    }
  }, [data]);
  return (
    <>
      {!isLoading && productList.length === 0 && <NoResultFound />}
      <InfiniteScroll
        dataLength={productList.length}
        pageStart={0}
        next={() => fetchNextPage()}
        hasMore={hasNextPage}
        loader={
          <div className="loader" key={0}>
            {[1, 2, 3, 4].map(() => (
              <AdCard isLoading={true} />
            ))}
          </div>
        }
      >
        {data &&
          productList.map((product, index) => (
            <Box key={"ad-s-" + index}>
              <Box
                component={getProductCard(product.filter_category)}
                data={product}
                filter_category={product.filter_category}
              />
            </Box>
          ))}
      </InfiniteScroll>
      {isLoading
        ? [1, 2, 3].map(() => <AdCard isLoading={isLoading} data={{}} />)
        : null}
    </>
  );
}

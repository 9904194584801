import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withLoading from "../../components/Feedback/withLoading";
import withMutate from "../../components/Feedback/withMutate";
import PaymentMethod from "../../components/Payment/PaymentMethod";
import ProductReview from "../../components/Payment/ProductReview";
import { useProductPrice } from "../../hooks";
import { useGetProductReview } from "../../hooks/Payment";
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    minHeight: "calc(100vh - 160px)",
    padding: theme.spacing(2),
    background: "#fafafa",
    background: theme.palette.background.paper,
    background: "transparent",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
}));
const CustomPaymentMethod = withMutate(withLoading(PaymentMethod));
const CustomProductReview = withLoading(ProductReview);
function SwitchComponent({ product }) {
  const [Loading, setLoading] = useState(false);
  const [review, setReview] = useState(true);
  const [body, setBody] = useState();
  const { data, isLoading } = useProductPrice(product.category.id);
  return (
    <>
      {review ? (
        <CustomProductReview
          price={data}
          product={product}
          isLoading={isLoading}
          setReview={setReview}
          setBody={setBody}
        />
      ) : (
        <CustomPaymentMethod
          body={body}
          product={product}
          isLoading={false}
          setReview={setReview}
          setLoading={setLoading}
          posting={Loading}
          postIsError={false}
          postIsSuccess={false}
          postSuccessMsg="Success!"
          postErrorMsg={"Please correct the errors below."}
        />
      )}
    </>
  );
}
const CustomSwitchComponent = withLoading(SwitchComponent);
function Payment() {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetProductReview(id);
  useEffect(() => {
    if (data && !data.allow_payment) navigate(-1);
  }, [data]);
  return (
    <CustomSwitchComponent
      product={data}
      isLoading={isLoading}
      isError={isError}
    />
  );
}
export default Payment;

import { Icon } from "@iconify/react";
import { Box, Button, SvgIcon, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as errorIcon } from "../config/vectors/error.svg";
export default function ErrorFallbackPage({ error, resetErrorBoundary }) {
  const queryClient = useQueryClient();
  const handleClick = () => {
    window.location.reload(false);
  };
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: "90vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SvgIcon
          sx={{
            fontSize: "98px",
            height: "65px",
            color: "primary.main",
            mb: 1,
          }}
          component={errorIcon}
          inheritViewBox
        />
        <Typography sx={{ mb: 1 }} variant="h2" color="primary">
          {t("Ooops!")}
        </Typography>
        <Typography className="number" sx={{ color: "grey.200", mb: 1 }}>
          {t("Something went wrong")}:
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "text.secondary",
          }}
        >
          <Button
            component={RouterLink}
            to="/"
            startIcon={<Icon icon="bx:home" />}
            lineHeight="15px"
            sx={{ color: "text.secondary" }}
          >
            {t("Return to Home")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

import { useQuery, useInfiniteQuery } from "react-query";
import { getLatestProduct, getLatestProductInfinite } from "../apis";

export const useLatestProduct = (payload) => {
  return useQuery(
    ["latest_product", payload],
    () => getLatestProduct(payload),
    {}
  );
};
export const useLatestProductInfinite = () => {
  return useInfiniteQuery(
    ["getLatestProductInfinte"],
    getLatestProductInfinite,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next === null ? undefined : lastPage.next;
      },
    }
  );
};

import ArrowRight from "@iconify/icons-eva/arrow-ios-forward-outline";
import { Icon } from "@iconify/react";
import { Avatar, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../../apis/config";
import { RootContext } from "../../../pages/ProductAd/AdForm";
import { SearchTextField } from "../../TextField/TextField";
function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}
function SelectCategory({ update, categories, other, ...props }) {
  const { setCategories, setOther, setAddress } = props;
  const { values, setErrors, setValues } = useContext(RootContext);
  const [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    setCategory(categories.child);
  }, [categories.child]);
  const handleClick = async (parent) => {
    window.scrollTo({ top: 0, behavior: "auto" });
    let newValue = {};
    if (search === "") {
      categories.path.push(parent.name);
      if (parent.is_model && other.table !== parent.slug) {
        if (values.company) newValue.company = values.company;
        newValue.phone_number = values.phone_number;
        newValue.is_negotiable = values.is_negotiable;
        other.table = parent.slug;
        setAddress((prev) => ({ ...prev, zone: [], location: [] }));
        setErrors({});
      }
      if (parent.children) {
        setCategories((prev) => ({ ...prev, child: parent.children }));
      }
    } else {
      const { data } = await api.get(
        `/api/category/ancestor/list/${parent.slug}`
      );
      let tempCategory = ["...."];
      let child = null;
      data.forEach((item) => {
        if (item.is_model && other.table !== parent.slug) {
          if (values.company) newValue.company = values.company;
          newValue.phone_number = values.phone_number;
          newValue.is_negotiable = values.is_negotiable;
          other.table = item.slug;
          setAddress((prev) => ({ ...prev, zone: [], location: [] }));
          setErrors({});
        }
        if (item.children) child = item.children;
        tempCategory.push(item.name);
      });
      if (parent.children) categories.child = parent.children;
      else categories.child = child;
      categories.path = tempCategory;
      setCategories(categories);
    }
    if (!parent.children) {
      if (Object.keys(newValue).length > 0)
        setValues({ ...newValue, category: parent.id });
      else setValues((prev) => ({ ...prev, category: parent.id }));
      setOther((prev) => ({ ...prev, page: 2, slug: parent.slug }));
    } else if (Object.keys(newValue).length > 0) setValues({ ...newValue });
    setSearch("");
  };
  const filter = (value, data) => {
    return data.filter((cat) =>
      cat.name.toUpperCase().includes(value.toUpperCase())
    );
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSearch = () => {
    if (search.length < 3) return;
    if (search != "") {
      let stack = [...categories.child];
      let tempCategory = filter(search, categories.child);
      while (stack.length > 0) {
        let top = stack.pop();
        if (top.children) {
          tempCategory = tempCategory.concat(filter(search, top.children));
          stack = stack.concat(top.children);
        }
      }
      setCategory(tempCategory);
    } else setCategory(categories.child);
  };
  useEffect(() => {
    if (search === "") setCategory(categories.child);
  }, [search]);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };
  const { t } = useTranslation();
  return (
    <>
      {!update && (
        <Box
          sx={{
            mb: 1,
            mt: 1.5,
            px: 1.5,
            pr: 0,
            width: "100%",
            display: "flex",
          }}
        >
          <SearchTextField
            autoFocus
            fullWidth
            placeholder={t("Find category...")}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton
            color="secondary"
            aria-label="add an alarm"
            sx={{ mx: 1 }}
            onClick={handleSearch}
          >
            <Icon icon="fa:search" />
          </IconButton>
        </Box>
      )}
      <List>
        {category &&
          category.map((item) => (
            <ListItem
              disablePadding
              disableGutters
              key={item.id}
              sx={{
                mb: 1.5,
                px: 0,
                "&.MuiListItem-root": {
                  fontSize: "20px",
                  paddingLeft: 0,
                },
              }}
            >
              <ListItemButton
                sx={{
                  height: "60px",
                  pl: "12px",
                  pr: "10px",
                }}
                onClick={() => handleClick(item)}
              >
                <ListItemIcon>
                  <Avatar
                    sx={{
                      height: "55px",
                      width: "55px",
                      bgcolor: item.icon.includes("image/icon/")
                        ? ""
                        : stringToColor(item.name),
                      img: {
                        objectFit: "contain",
                      },
                    }}
                    alt={item.slug}
                    src={item.icon ? item.icon : "/not-found"}
                    variant="rounded"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t(item.name)}
                  sx={{
                    "&.MuiListItemText": {
                      fontSize: "10px",
                    },
                  }}
                />
                <ListItemIcon sx={{ mr: 0 }}>
                  <Box component={Icon} icon={ArrowRight} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </>
  );
}
export default memo(SelectCategory);

import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import Landscape from "../../../../config/images/lanscape_right_and_wrong.png";
import Portrait from "../../../../config/images/portrait_right_and_wrong.png";
export default function TipOnScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const { t } = useTranslation();
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen()} sx={{ mb: 1 }}>
        {t("Photo shooting tips")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ m: 0 }}
      >
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography my={1.5} textAlign="center" lineHeight="22px">
              {t("While taking pictures make sure that")}:
            </Typography>
            <Typography variant="body2" textAlign="justify" my={2}>
              * {t("The object is in the middle of the screen.")} <br />*{" "}
              {t("There is no other objects near your target object.")}
              <br />*
              <Trans i18nKey="image.helperText3">
                You choose <b>Landscape</b> mode for wider objects and &nbsp;
                <b>Portrait</b> mode for longer objects.
              </Trans>
            </Typography>
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <img src={Landscape} width="100%" height="100%" />
              <img src={Portrait} width="100%" height="100%" />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../utils/validation";
import Alert from "../Alert/Alert";
import { CustomizedButton } from "../Button/Button";
import { message } from "./Constants";
import { send_otp, verify_otp } from "./OTP";
import { checkEmpty } from "./ValidateField";
const VerifyCode = ({ otp, resendMutate, verifyMutate, ...props }) => {
  const { values, setPage, setValues, setLoading } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notify, setNotify] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const handleBlure = (e) => {
    if (e.target.value === "")
      setErrors((prev) => ({ ...prev, [e.target.name]: message.VALUE_ERROR }));
  };
  const handleChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setValues((prev) => ({ ...prev, [e.target.name]: value }));
      setErrors((prev) => ({ ...prev, [e.target.name]: null }));
    }
  };
  const checkVerification = () => {
    if (window.verify) {
      window.verify = false;
      setPage(false);
    } else setLoading(false);
    if (window.cannotverify) {
      window.cannotverify = false;
      navigate("/signin");
    }
    setErrors((prev) => ({
      ...prev,
      success: window.otp_success,
      detail: window.otp_detail,
    }));
    if (window.otp_success || window.otp_detail) setNotify(true);
  };
  const resendActivation = async () => {
    setValues((prev) => ({ ...prev, key: null }));
    setErrors((prev) => ({ ...prev, key: null }));
    setLoading(true);
    if (otp.phone_number) {
      await send_otp(otp.phone_number);
      checkVerification();
    } else if (otp.email) resendMutate.mutate({ email: otp.email });
    setLoading(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = checkEmpty(e);
    setErrors({ ...error, detail: null, success: null });
    setSubmitted(true);
  };
  useEffect(async () => {
    if (submitted && isEmpty(errors)) {
      if (otp.email) verifyMutate.mutate(values);
      else if (otp.phone_number) {
        if (values.key.length < 6)
          setErrors((prev) => ({ ...prev, key: message.KEY_LENGTH_ERROR }));
        else {
          setLoading(true);
          await verify_otp(values.key);
          checkVerification();
          setLoading(false);
        }
      }
    }
    setSubmitted(false);
  }, [submitted]);
  useEffect(() => {
    if (verifyMutate.isSuccess) {
      verifyMutate.reset();
      setPage(false);
    }
    if (verifyMutate.isError) {
      setErrors(verifyMutate.error);
      if (verifyMutate.error.detail) setNotify(true);
      resendMutate.reset();
    }
    if (resendMutate.error) {
      setErrors(resendMutate.error);
      if (resendMutate.error.detail) setNotify(true);
      verifyMutate.reset();
    }
  }, [verifyMutate.isError, verifyMutate.isSuccess, resendMutate.isError]);
  useEffect(() => {
    if (otp.phone_number) checkVerification();
  }, []);
  return (
    <Box
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "480px",
        textAlign: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <div id="recaptcha-container"></div>
        <Box
          sx={{
            margin: "0px 0px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography
            sx={{
              lineHeight: "1.5",
              lineHeigh: 0,
              fontWeight: 400,
              fontSize: "2.2rem",
            }}
          >
            {otp.phone_number ?? otp.email}
          </Typography>
          <Box
            sx={{
              display: "flex",
              p: 0.5,
              ml: 1.5,
              color: "secondary.light",
              cursor: "pointer",
              "&:hover": { color: "secondary.main" },
            }}
            onClick={() => navigate("/password/reset")}
          >
            <EditOutlinedIcon />
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{ fontWeight: 400, color: "rgb(99, 115, 129)" }}
        >
          {t("We have sent password reset code to your")}&nbsp;
          {otp.phone_number
            ? t("phone number.")
            : otp.email && t("email address.")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            name="key"
            autoComplete="off"
            label={t("Password reset code")}
            variant="outlined"
            placeholder={t("password reset code")}
            value={values.key ? values.key : ""}
            inputProps={{ maxLength: 6 }}
            onChange={handleChange}
            onBlur={handleBlure}
            error={Boolean(errors.key)}
            helperText={t(errors.key)}
            sx={{ mt: 4, mb: 4 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit" color="secondary">
                    <ArrowForwardSharpIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CustomizedButton type="submit" color="secondary" variant="contained">
            {t("Verify")}
          </CustomizedButton>
        </form>
        <Typography
          variant="body2"
          sx={{ justifyContent: "center", textAlign: "center" }}
        >
          {t("Didn't recieve code?")}&nbsp;
          <Link
            underline="none"
            variant="subtitle2"
            sx={{ color: "secondary.main", cursor: "pointer" }}
            onClick={resendActivation}
          >
            {t("Resend code")}
          </Link>
        </Typography>
      </Box>
      {errors.detail && (
        <Alert
          open={notify}
          setOpen={() => setNotify(!notify)}
          message={errors.detail}
          severity="error"
        />
      )}
      {errors.success && (
        <Alert
          open={notify}
          setOpen={() => setNotify(!notify)}
          message={errors.success}
          severity="success"
        />
      )}
    </Box>
  );
};
export default VerifyCode;

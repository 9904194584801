import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import {
  Box,
  Button,
  Chip,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../apis/config";
import Alert from "../Alert/Alert";
const common = {
  mb: 1,
  pl: 0.5,
  width: "80%",
  border: "solid 1px #000000",
  display: "flex",
  justifyContent: "flex-start",
  "&:hover": { bgcolor: "rgb(0, 255, 0, 0.48)" },
};
const selected = { ...common, bgcolor: "rgb(0, 255, 0, 0.48)" };
const unselecte = { ...common, bgcolor: "rev_grey.200" };
const MARKETING_RESPONSE = [
  "Facebook",
  "Telegram",
  "Other Social Medias",
  "TV Commercials",
  "Radio Commercials",
  "Flyers and Brochures",
  "Billboards and Banners",
  "Newspapers and Magazines",
  "Sales Person",
  "Other",
];
function MarketingInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ user: id });
  const [notify, setNotify] = useState(false);
  const handleClick = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleChange = (e) => {
    handleClick(e.target.name, e.target.value.toUpperCase());
    setErrors((prev) => ({ ...prev, [e.target.name]: null }));
  };
  const handleSubmit = async () => {
    if (!values.marketing_response) navigate("/signin");
    else {
      if (
        values.marketing_response === "Sales Person" &&
        (!values.sales || values.sales.length !== 7)
      )
        setErrors((prev) => ({
          ...prev,
          sales: "Ensure sales id has 7 characters.",
        }));
      else {
        let data = { ...values };
        if (values.marketing_response !== "Sales Person") data.sales = null;
        await api
          .post(`/api/auth/marketing-info/`, data)
          .then(() => navigate("/signin"))
          .catch((err) => {
            setErrors(err.response.data);
            if (err.response && !err.response.data.sales) {
              setValues((prev) => ({ ...prev, marketing_response: null }));
              setNotify(true);
            }
          });
      }
    }
  };
  return (
    <Container component="main" maxWidth="xs" sx={{ display: "flex", flex: 1 }}>
      <Box
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          border: "dashed 1px #000000",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 4,
        }}
      >
        <Typography
          sx={{
            margin: "0px 0px 16px",
            fontWeight: 600,
            fontSize: "1.2rem",
            color: "rgb(99, 115, 129)",
            textAlign: "center",
          }}
          variant="body2"
        >
          {t("How did you hear about us?")}
        </Typography>
        {MARKETING_RESPONSE.map((value) => (
          <Chip
            label={t(value)}
            onClick={() => handleClick("marketing_response", value)}
            sx={values.marketing_response === value ? selected : unselecte}
            icon={
              values.marketing_response === value ? (
                <CheckCircleOutlineOutlinedIcon />
              ) : (
                <CircleOutlinedIcon />
              )
            }
          />
        ))}
        {values.marketing_response === "Sales Person" && (
          <TextField
            size="small"
            name="sales"
            label={t("Sales Id")}
            variant="outlined"
            sx={{ width: "80%" }}
            onChange={handleChange}
            value={values.sales ? values.sales : ""}
            error={Boolean(errors.sales)}
            helperText={t(errors.sales)}
            inputProps={{ maxLength: 8 }}
          />
        )}
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          sx={{ width: "80%", borderRadius: "25px", mt: 3 }}
        >
          {values.marketing_response ? t("Submit") : t("Skip")}
        </Button>
        <Alert
          open={notify}
          setOpen={() => setNotify(!notify)}
          message="You already submitted."
          severity="error"
        />
      </Box>
    </Container>
  );
}
export default MarketingInfo;

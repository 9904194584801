import { useQuery } from "react-query";
import { PromotedProduct } from "../apis";

export const usePromotedProduct = (payload, page) => {
  return useQuery(
    ["Promoted product", payload, page],
    () => PromotedProduct(payload, page),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
};

import ClearIcon from "@mui/icons-material/Clear";
import RestoreIcon from "@mui/icons-material/Restore";
import { IconButton, List, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles(() => ({
  cover: {
    bottom: "0px",
    padding: "2px",
    width: "100%",
    left: "0px",
    position: "absolute",
    color: "rgb(255, 255, 255)",
    backgroundColor: "#FB8500",
    textAlign: "center",
  },
  delete: {
    bottom: "0px",
    padding: "2px",
    width: "100%",
    left: "0px",
    position: "absolute",
    color: "rgb(255, 255, 255)",
    backgroundColor: "#ff6347",
    textAlign: "center",
  },
  remove: {
    fontSize: "1.125rem",
    top: "6px",
    padding: "2px",
    right: "6px",
    position: "absolute",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, 0.72)",

    "&:hover": {
      backgroundColor: "rgba(22, 28, 36, 0.48)",
    },
  },
  listImage: {
    justifyContent: "flex-start",
    alignItems: "center",
    textDecoration: "none",
    boxSizing: "border-box",
    textAlign: "left",
    padding: "0px",
    margin: "4px",
    width: "100px",
    height: "75px",
    borderRadius: "10px",
    overflow: "hidden",
    flexWrap: "wrap",
    display: "flex !important",
    border: "1px solid rgba(145, 158, 171, 0.24)",
  },
  error: {
    color: "#FF4842",
    lineHeight: "15px",
    fontSize: "0.75rem",
    fontFamily: "Public Sans,sans-serif",
    fontWeight: "400",
    margin: "2px 14px",
  },
}));

const ChangeCoverImage = ({
  photos,
  coverId,
  deletedId,
  setCoverId,
  setDeletedId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setCoverId(photos[0].id);
  }, [photos]);

  function selectCover(id) {
    if (deletedId.includes(id)) {
      setDeletedId(deletedId.filter((_id) => _id !== id));
    } else setCoverId(id);
  }

  function removeImage(id) {
    setDeletedId((prev) => [...prev, id]);
  }
  return (
    <List
      sx={{
        listStyle: "none",
        margin: "10px 0px",
        padding: "0px",
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {photos.map((file, index) => (
        <ListItem key={index} disableGutters className={classes.listImage}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "inline",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              component={LazyLoadImage}
              src={file.image}
              onClick={() => selectCover(file.id, "cover")}
              effect="blur"
              wrapperClassName="wrapper"
              alt="preview"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
          {file.id === coverId && (
            <Typography variant="caption" className={classes.cover}>
              {t("Cover image")}
            </Typography>
          )}

          {deletedId.includes(file.id) && (
            <Typography variant="caption" className={classes.delete}>
              {t("Deleted image")}
            </Typography>
          )}

          {file.id !== coverId && (
            <IconButton
              onClick={() =>
                deletedId.includes(file.id)
                  ? selectCover(file.id)
                  : removeImage(file.id)
              }
              className={classes.remove}
            >
              {deletedId.includes(file.id) ? (
                <RestoreIcon fontSize="small" />
              ) : (
                <ClearIcon fontSize="small" />
              )}
            </IconButton>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default ChangeCoverImage;
